///////////////////////////////////////////
//////////////////////////////////////////
///   MISSING INFO CONTROLLER   //
////////////////////////////////////////
///////////////////////////////////////
(function(){

	'use strict';

	angular
	.module('app.missingInfo')
	.controller('MissingInfo', MissingInfo);

	/* @ngInject */
	function MissingInfo(
		$scope,
		$rootScope,
		$location,
		$route,
		$idle,
		$routeParams,
		$q,
		$log,
		$sce,
		$cookieStore,
		$translate,
		modalService,
		authentication,
		applicationService,
		stateRestrictFactory,
		SharedDataSvc,
		agreementFactory,
		getStoreInfoFactory,
		getTrainingLibraryFactory,
		getInitialDashboard
		){

		/*jshint validthis: true */
		var MissingInfo = this;

		/* Object declarations goes below */
		$scope.customerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {
				bestCallTime:{
					Morning:'',
					Afternoon:'',
					Evening:''
				}
			},
			phone2: {
				
			},
			incomeSources: {
				sourcePhone:{
					number:""
				},
				sourceName: "",
				income:"",
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				nextPayDate:{
					month:'',
					day:''
				}//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			},
			personalReferences: []
		};
		// CUSTOMER INFORMATION
		$scope.coCustomerInfo = {
			address: {},
			driversLicense: {
				id: '',
				issuer: '',
				idType: '1'
			},
			phone1: {},
			phone2: {},
			incomeSources: {
				nextPayDate: {},
				sourcePhone:{}//VAN-2401-PR stores to use SLI- AWP re-engagement
			},
			personalReferences: [],
			dob: {}
		};

		$scope.coCustomerPrivate = {
			dob: {},
			ssn: {
				ssnType: 'SSN'
			},
			verifyssn: {
				ssnType: 'SSN'
			}
		};

		$scope.custAdditionalInfo = {
			previousAddress : {},
			moveInDate : {},
			auto: {},
			rent: {},
			own: {},
			leaseEndDate: {},
			ownRentInfo: {},
			noCocustomer: false
		}

		$scope.custAdditionalInfo.auto.noCar = false;
		$scope.customerPrivate = {
			dob: {},
			ssn: {
				ssnType : 'SSN',
				ssnTypeDisable:true
			}
		};
		$scope.ref1 = {};
		$scope.ref2 = {};
		$scope.ref3 = {};
		$scope.ref4 = {};
		$scope.leaseYears = [];
		$scope.moveInYears = [];
			
		/* Customer zip and store radius properties */
		$scope.isZipInStoreRadius = isZipInStoreRadius;
		$scope.resetZipData = resetZipData;
		$scope.zipValidation = zipValidation;
		$scope.zipSubmitFalse = zipSubmitFalse;
		$scope.customerInRadius = true;
		$scope.isZipValid = true;
		$scope.allowTextVerificationFlag = false;
		$scope.storeInfo;
		$scope.storeLatLng;
		$scope.customerDistance;
		$scope.storeRadius;
		$scope.restrictions;
		$scope.stateRestrictions;
		$scope.engagementDetails;
		$scope.ssnNumber;
		$scope.isSpanishStore;
		
		/* Methods goes below */
		$scope.validateDOB = validateDOB;
		$scope.checkState = checkState;
		$scope.navigateTo = navigateTo;
		//$scope.saveDraft = saveDraft;
		$scope.saveAndProcess = saveAndProcess;
		$scope.cancelCustomerEntry = cancelCustomerEntry;
		$scope.validateReferenceName = validateReferenceName;
		$scope.validateReferencePhone = validateReferencePhone;
		$scope.validateSecondaryPhone = validateSecondaryPhone;
		$scope.clearSSN = clearSSN;
		//$scope.handleCustomerOutOfRadius = handleCustomerOutOfRadius;
		//PR flow new additions
		$scope.setPreviousAdd = setPreviousAdd;
		$scope.showPreviousAdd = false;
		$scope.updateAutoFields = updateAutoFields;
		
		/* Object initializations goes below*/
		$scope.incomeEnums = [
			{
				type: 'EMPLOYED',
				label: {
	                en : en.application_select_empOpt1,
	                es : es.application_select_empOpt1
	            }
			},
			{
				type: 'SELFEMPLOYED',
				label: {
	                en : en.application_select_empOpt2,
	                es : es.application_select_empOpt2
	            }
			},
			{
				type: 'SOCIAL',
				label: {
	                en : en.application_select_empOpt3,
	                es : es.application_select_empOpt3
	            }
			},
			{
				type: 'RETIRED',
				label: {
	                en : en.application_select_empOpt4,
	                es : es.application_select_empOpt4
	            }
			},
			{
				type: 'PENSION',
				label: {
	                en : en.application_select_empOpt5,
	                es : es.application_select_empOpt5
	            }
			},
			{
				type: 'DISABILITY',
				label: {
	                en : en.application_select_empOpt6,
	                es : es.application_select_empOpt6
	            }
			},
		];

		$scope.payFrequencyEnums = [
			{
				type: 'MONTHLY',
				label: {
					en : en.application_select_payFreq1,
					es : es.application_select_payFreq1
				}
			},
			{
				type: 'TWICE-MONTHLY',
				label: {
					en : en.application_select_payFreq2,
					es : es.application_select_payFreq2
				}
			},
			{
				type: 'BI-WEEKLY',
				label: {
					en : en.application_select_payFreq3,
					es : es.application_select_payFreq3
				}
			},
			{
				type: 'WEEKLY',
				label: {
					en : en.application_select_payFreq4,
					es : es.application_select_payFreq4
				}
			}
		];
		
		$scope.disablingFlag ={
			firstName:true,
			lastName:true,
			middleName:true,
			address:{
				addrLine1:true,
				addrLine2:true,
				city:true,
				state:true,
				zipCode:true,
				isRenewingLease:true,
				landlordName:true,
				landLordNumber:true,
				custPaymentAmount:true,
				leaseTypeRent:true,
				apmtComplex:true,
				leaseEndDate:true
			},
			ssn:{
				ssn1:true,
				ssn2:true,
				ssn3:true
			},
			driversLicense:{
				id:true,
				issuer:true,
				idType: true
			},
			dob:{
				day:true,
				month:true,
				year:true
			},
			phone1:{
				number:true,
				phoneType:true,
				bestCallTime:{
					morning:true,
					afternoon:true,
					evening:true
				}
			},
			phone2:{
				number:true,
				phoneType:true	
			},
			email:true,
			emailVerify:true,//Story 30445 -AWP, Kiosk: Add email verification edit box
			incomeSources:{
				sourcePhone:{
					number:false
				},
				sourceName: false,
				income:false,
				monthlyIncome:false,
				employerAddress:false,
				deptTitle:false,
				supervisorName:false,
				workHours:false,
				bestPayDate:false

			},
			ref1:{
				firstName:true,
				address : true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref2:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref3:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			ref4:{
				firstName:true,
				address:true,
				lastName:true,
				number:true,
				numberType: true,
				personalReferenceType:true
			},
			additionalInfo: {
				avgUtilities: false
			},
			moveInDate: true,
			previousAddress : {
				address: false,
				landlordName: false,
				landlordNumber: false,
			},
			homeType: true,
			rentOwn: {
				landlordName: true,
				landlordNumber: true,
			},
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			coCustomerInfo: {
				firstName:true,
				lastName:true,
				middleName:true,
				relationshipType:true,
				ssn:{
					ssn1:false,
					ssn2:false,
					ssn3:false
				},
				phone1:{
					number:true,
					phoneType:true,
					bestCallTime:{
						morning:true,
						afternoon:true,
						evening:true
					}
				},
				dob:{
					day:true,
					month:true,
					year:true
				},
				incomeSources:{
					sourcePhone:{
						number:false
					},
					sourceName: false,
					income:false,
					monthlyIncome:false,
					employerAddress:false,
					deptTitle:false,
					supervisorName:false,
					workHours:false,
					bestPayDate:false,
					payFrequency:false,
					takeHomePay:false
				},
				auto:{
					makeAndModel:false,
					tag:false,
					color:false,
					amount:false
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
		};
		
		// set default income source
		//$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
		
		// BOOLS goes below
		$scope.customerExistsInHT = false; // true
		$scope.ssnSubmitted = false;
		$rootScope.hideMenuButtons = false;
		$scope.formSubmitted = false;
		$scope.draftSaved = false;
		$scope.amountBelowOrderTotal = false;
		$scope.secondaryNoTypeRequired = false;
		
		// VALIDATION REGEXES
		$scope.onlyNumbers = /^\d+$/;
		$scope.ssnValidation1 = /^(?!666|000|9\d{2})\d{3}$/;
		$scope.ssnValidation2 = /^(?!00)\d{2}$/;
		$scope.ssnValidation3 = /^(?!0{4})\d{4}$/;
		$scope.ccssnValidation1 = /^(?!666|000|9\d{2})\d{3}|\*\*\*$/;
		$scope.ccssnValidation2 = /^(?!00)\d{2}|\*\*$/;
		$scope.itinValidation1 = /(9\d{2})$/;
		$scope.itinValidation2 = /(d{2})$/;
		$scope.itinValidation3 = /(d{4})$/;
		$scope.phoneValidation = /^\(\d{3}\)[-.\s]?\d{3}[-.]?\d{4}$/;
		//$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[BbOoXx])[-a-zA-Z\d .,\/]*$/;
		$scope.POBoxValidation = /^(?!.*[Pp]\.? ?[Oo]\.? ?[Bb]\.? ?[Oo]\.? ?[Xx]\.?)[-a-zA-Z\d\\\/ .,#\/]*$/;//VAN-913 Added to allow escape sequence character
		$scope.nameValidation = /^[a-zA-Z\s\-\']+$/; // Added for name validation 2542 & 2543. It will allows only letters
		$scope.lastNameValidation = /^\d*[a-zA-Z0-9\d\s\-\']*$/; // Added for name validation 2542 & 2543. It will allow letters, dashes, and apostrophes.

		$scope.incomeValidation = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
		$scope.routingValidation = /^([0][1-9]|[1][0-2]|[2][1-9]|[3][0-2]|[6][1-9]|[7][0-2])([0-9]{7})$/;
		$scope.refType  =  ["PARENT","SBLING","GRANDP","COUSIN","ANTUNC","NIENEP","FRIEND","COWORK","OTHER"];   //AC23127 code changes 
		//Story 30445 -AWP, Kiosk: Add email verification edit box - start
		$scope.emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		$scope.salaryValidation = /[1-9]\d*/; // AC13186 code changes 
		$scope.compareEmailVerify = compareEmailVerify;
		$scope.verifyEmail = true;
		$scope.validEmailPattern = false;
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end
		
		
		
		// DATE OF BIRTH INFO
		$scope.dobvalid = {
			day: moment().date(),
			month: moment().month() + 1,
			year: moment().year() - 18
		};
		$scope.birthYears = [];
		
		// Start VAN - 10736 Added for scroll up
		$scope.scrollToTop = function(toHere) {
			$('html, body').animate({
				scrollTop: $(toHere).position().top
			}, 'fast');
		};
		// End

		$scope.updateCoIncome = updateCoIncome;//VAN-2401-PR stores to use SLI- AWP re-engagement 
		$scope.updateCocustomerIncome = updateCocustomerIncome;//VAN-2401-PR stores to use SLI- AWP re-engagement
		
		// PUBLIC FUNCTIONS
		init();
		function init()
		{
			addYears();
			$scope.isSpanishStore = $cookieStore.get('isSpanishStore');
			if ($routeParams.data) {
				var decoded, personData;
				decoded = decodeURIComponent($routeParams.data);
				decoded = window.atob(decoded);
				decoded = JSON.parse(decoded);
				//retrieveApplication($scope.personData);

				applicationService.getApplication(decoded).then(function(successData){
					console.log("[Missing Info find customer success]", successData);
					successData = SharedDataSvc.convertZerosToBlank(successData);
					var zipCode = successData.address[0].zipCode?successData.address[0].zipCode.substring(0,5):"";//Defect 26474 code changes
					$rootScope.findData = successData;//VAN-2401-PR stores to use SLI- AWP re-engagement
					if(!$scope.isSpanishStore)
						zipValidation(zipCode);//AC23079 code change
					fillDataInForm(successData);
				}, function(failData){
					console.log("[Missing Info find customer fail]", failData);
				});
				
			}
			
			getStoreInfoFactory.getData()
				.then(function(response) {
				  $scope.storeInfo = response;
				 // $rootScope.invoiceMethod = response.invoiceMethod;
				  $scope.storeLatLng = { 'lat': $scope.storeInfo.latitude, 'lng': $scope.storeInfo.longitude };
				  $scope.storeRadius = response.allowedRentalRadiusMiles;
				  $scope.allowTextVerificationFlag = response.allowTextVerification;
				  $scope.isReferenceRequired = response.isReferenceRequired;

				  $scope.itinAllowed = response.itinAllowed;
				  stateRestrictFactory.getStateRestrictions()
					  .then(function(response) {
						$scope.stateRestrictions = response.stateRestrictions[0];
					});

				  stateRestrictFactory.getAllStoreRestrictions($scope.storeInfo.state)
					  .then(function(response) {
						$scope.restrictions = response;
					});
			});
			
			//fillExistingFields();
			

		}


		function updateAutoFields(){
			if($scope.custAdditionalInfo.auto.noCar == true){
				$scope.custAdditionalInfo.auto.make = "";
				$scope.custAdditionalInfo.auto.model = "";
				$scope.custAdditionalInfo.auto.tag = "";
				$scope.custAdditionalInfo.auto.color = "";
				$scope.custAdditionalInfo.auto.amount = "";
			}else{//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if($rootScope.findData.additionalInfo != null || $rootScope.findData.additionalInfo != undefined){
					if($rootScope.findData.additionalInfo.autoMakeAndModel != ''){
						var autoModel = $rootScope.findData.additionalInfo.autoMakeAndModel.split('-');
						$scope.custAdditionalInfo.auto.make = autoModel[0];
						$scope.custAdditionalInfo.auto.model = autoModel[1];
					}
					$scope.custAdditionalInfo.auto.make = $scope.custAdditionalInfo.auto.make;
					$scope.custAdditionalInfo.auto.model = $scope.custAdditionalInfo.auto.model;
					$scope.custAdditionalInfo.auto.tag = $rootScope.findData.additionalInfo.tag;
					$scope.custAdditionalInfo.auto.color = $rootScope.findData.additionalInfo.color;
					$scope.custAdditionalInfo.auto.amount = $rootScope.findData.additionalInfo.carPaymentAmount;
				}
			}//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
		}

		function fillDataInForm(successData){
			
			// Test code successData.driversLicense.birthDate = "";
			// Test code successData.personalReferences[0].firstName = "";
			
			
			
			$scope.customerInfo.firstName = successData.firstName;
			if(SharedDataSvc.isBlankOrNull(successData.firstName)){
				$scope.disablingFlag.firstName = false;
			}else{
				$scope.disablingFlag.firstName = true;
			}
			
			$scope.customerInfo.lastName =  successData.lastName;
			if(SharedDataSvc.isBlankOrNull(successData.lastName)){
				$scope.disablingFlag.lastName = false;
			}else{
				$scope.disablingFlag.lastName = true;
			}
			
			$scope.customerInfo.middleName = successData.middleInitial;
			if(SharedDataSvc.isBlankOrNull(successData.middleInitial)){
				$scope.disablingFlag.middleName = false;  /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.middleName = true;
			}
			
			$scope.customerInfo.address.addrLine1 = successData.address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].addrLine1)){
				$scope.disablingFlag.address.addrLine1 = false;
			}else{
				$scope.disablingFlag.address.addrLine1 = true;
			}
			
			$scope.customerInfo.address.addrLine2 = successData.address[0].addrLine2;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].addrLine2)){
				$scope.disablingFlag.address.addrLine2 = false;  /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.address.addrLine2 = true;
			}
			
			$scope.customerInfo.address.city = successData.address[0].city;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].city)){
				$scope.disablingFlag.address.city = false;
			}else{
				$scope.disablingFlag.address.city = true;
			}
			
			$scope.customerInfo.address.state = successData.address[0].state;
			if(SharedDataSvc.isBlankOrNull(successData.address[0].state)){
				$scope.disablingFlag.address.state = false;
			}else{
				$scope.disablingFlag.address.state = true;
			}
			
			$scope.customerInfo.address.zipCode = successData.address[0].zipCode?successData.address[0].zipCode.substring(0,5):"";//Defect 26474 code change
			if(SharedDataSvc.isBlankOrNull(successData.address[0].zipCode)){
				$scope.disablingFlag.address.zipCode = false;
			}else{
				$scope.disablingFlag.address.zipCode = true;
			}

				
			if(SharedDataSvc.isBlankOrNull(successData.address[0].moveInDate)){
				$scope.disablingFlag.moveInDate = false;
			}else{
				$scope.disablingFlag.moveInDate = true;
				$scope.custAdditionalInfo.moveInDate.month = successData.address[0].moveInDate.substr(4, 2);
				$scope.custAdditionalInfo.moveInDate.year = successData.address[0].moveInDate.substr(0, 4);
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].isRenewingLease)){
				$scope.disablingFlag.address.isRenewingLease = false;
			}else{
				$scope.disablingFlag.address.isRenewingLease = true;
				$scope.customerInfo.address.homeType = successData.address[0].isRenewingLease;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].landLordName)){
				$scope.disablingFlag.address.landlordName = false;
			}else{
				$scope.disablingFlag.address.landlordName = true;
				$scope.custAdditionalInfo.ownRentInfo.mortgageCompany = successData.address[0].landLordName;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].landLordNumber)){
				$scope.disablingFlag.address.landLordNumber = false;
			}else{
				$scope.disablingFlag.address.landLordNumber = true;
				$scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNum = successData.address[0].landLordNumber;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if(successData.additionalInfo != null){
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.averageMonthlyUtilities)){
					//$scope.disablingFlag.address.avgUtilities = false;
					$scope.disablingFlag.additionalInfo.avgUtilities = false;
				}else{
					//$scope.disablingFlag.address.avgUtilities = true;
					$scope.disablingFlag.additionalInfo.avgUtilities = true;
					$scope.custAdditionalInfo.ownRentInfo.avgUtilities = successData.additionalInfo.averageMonthlyUtilities;
				}
			}else{
				$scope.disablingFlag.additionalInfo.avgUtilities = false;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
		
			//Commented as part of VAN-2401-PR stores to use SLI- AWP re-engagement
			/*if(SharedDataSvc.isBlankOrNull(successData.address[0].custPayment)){
				$scope.disablingFlag.address.custPaymentAmount = false;
			}else{
				$scope.disablingFlag.address.custPaymentAmount = true;
				$scope.custAdditionalInfo.ownRentInfo.custPaymentAmount = successData.address[0].custPayment;
			}*/

			if(SharedDataSvc.isBlankOrNull(successData.address[0].leaseType)){
				$scope.disablingFlag.address.leaseTypeRent = false;
			}else{
				$scope.disablingFlag.address.leaseTypeRent = true;
				$scope.custAdditionalInfo.rent.leaseTypeRent = successData.address[0].leaseType;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].apartmentComplex)){
				$scope.disablingFlag.address.apmtComplex = false;
			}else{
				$scope.disablingFlag.address.apmtComplex = true;
				$scope.custAdditionalInfo.rent.apmtComplex = successData.address[0].apartmentComplex;
			}

			if(SharedDataSvc.isBlankOrNull(successData.address[0].leaseEndDate)){
				$scope.disablingFlag.address.leaseEndDate = false;
			}else{
				$scope.disablingFlag.address.leaseEndDate = true;
				$scope.custAdditionalInfo.leaseEndDate.month = successData.address[0].leaseEndDate.substr(4, 2);
				$scope.custAdditionalInfo.leaseEndDate.year = successData.address[0].leaseEndDate.substr(0, 4);
			}



			
			if(successData.additionalInfo){
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.averageMonthlyUtilities)){
					$scope.disablingFlag.additionalInfo.avgUtilities = false;
				}else{
					$scope.disablingFlag.additionalInfo.avgUtilities = true;
					$scope.disablingFlag.additionalInfo.avgUtilities = successData.additionalInfo.averageMonthlyUtilities;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].addrLine1)){
					$scope.disablingFlag.previousAddress.address = false;
				}else{
					$scope.disablingFlag.previousAddress.address = true;
					$scope.custAdditionalInfo.previousAddress.addrLine1 = successData.additionalInfo.previousAddress[0].addrLine1;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].landLordName)){
					$scope.disablingFlag.previousAddress.landlordName = false;
				}else{
					$scope.disablingFlag.previousAddress.landlordName = true;
					$scope.custAdditionalInfo.previousAddress.mortgageName = successData.additionalInfo.previousAddress[0].landLordName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.previousAddress[0].landLordNumber)){
					$scope.disablingFlag.previousAddress.landlordNumber = false;
				}else{
					$scope.disablingFlag.previousAddress.landlordNumber = true;
					$scope.custAdditionalInfo.previousAddress.mortgagePhone = successData.additionalInfo.previousAddress[0].landLordNumber;
				}

				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.customerPaymentAmount)){
					$scope.disablingFlag.address.custPaymentAmount = false;
				}else{
					$scope.disablingFlag.address.custPaymentAmount = true;
					$scope.custAdditionalInfo.ownRentInfo.custPaymentAmount = successData.additionalInfo.customerPaymentAmount;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceAddress.addrLine1)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.employerAddress = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.employerAddress = true;
					$scope.coCustomerInfo.incomeSources.employerAddress = successData.additionalInfo.coCustomerIncomeSource[0].sourceAddress.addrLine1;
				}

				$scope.coCustomerInfo.incomeSources.deptTitle =  successData.additionalInfo.coCustomerIncomeSource[0].department !=null ? successData.additionalInfo.coCustomerIncomeSource[0].department : successData.additionalInfo.coCustomerIncomeSource[0].sourceDepartment;
				if(SharedDataSvc.isBlankOrNull($scope.coCustomerInfo.incomeSources.deptTitle)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.deptTitle = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.deptTitle = true;
					$scope.coCustomerInfo.incomeSources.deptTitle = successData.additionalInfo.coCustomerIncomeSource[0].department != null?successData.additionalInfo.coCustomerIncomeSource[0].department :successData.additionalInfo.coCustomerIncomeSource[0].sourceDepartment ;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].supervisorName)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.supervisorName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.supervisorName = true;
					$scope.coCustomerInfo.incomeSources.supervisorName = successData.additionalInfo.coCustomerIncomeSource[0].supervisorName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].workHours)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.workHours = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.workHours = true;
					$scope.coCustomerInfo.incomeSources.workHours = successData.additionalInfo.coCustomerIncomeSource[0].workHours;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].bestPayDate)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.bestPayDate = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.bestPayDate = true;
					$scope.coCustomerInfo.incomeSources.bestPayDate = successData.additionalInfo.coCustomerIncomeSource[0].bestPayDate;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].paydayFrequency)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.payFrequency = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.payFrequency = true;
					$scope.coCustomerInfo.incomeSources.payFrequency = successData.additionalInfo.coCustomerIncomeSource[0].paydayFrequency;
				}

				$scope.coCustomerInfo.incomeSources.income = successData.additionalInfo.coCustomerIncomeSource[0].incomeType;
				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].incomeType)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.income = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.income = true;
				}

				if(successData.additionalInfo.coCustomerIncomeSource[0].sourceName != '0'){
					$scope.coCustomerInfo.incomeSources.sourceName = successData.additionalInfo.coCustomerIncomeSource[0].sourceName;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceName)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = false;
				} else if(successData.incomeSources[0].sourceName == "0"){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = false;
				} else {
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceName = true;
				}

				if(successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone !== null && successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone !== '0000000'){
					$scope.coCustomerInfo.incomeSources.sourcePhone.number = successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone.areaCode + successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone.number;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourcePhone)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourcePhone.number = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourcePhone.number = true;
				}

				var coCustStartDateSelection;
				var coCustMonthThresholds = ['11', '23', '48', '60'];
				var coCustStartDate = moment(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate, 'YYYYMMDD');
				var coCustMonthsSinceStart = moment().diff(coCustStartDate, 'months');
				for (var i = 0; i < coCustMonthThresholds.length; i++) {
					if(coCustMonthsSinceStart <= coCustMonthThresholds[i] && coCustMonthsSinceStart ) {
						coCustStartDateSelection = coCustMonthThresholds[i];
						break;
					}
				}
				if (coCustMonthsSinceStart >= coCustMonthThresholds[coCustMonthThresholds.length-1]) {
					coCustStartDateSelection = coCustMonthThresholds[coCustMonthThresholds.length-1];
				}
				if(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate == ""){
					$scope.coCustomerInfo.incomeSources.sourceStartDate = successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate;
				} else {
					$scope.coCustomerInfo.incomeSources.sourceStartDate = coCustStartDateSelection;
				}

				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].sourceStartDate)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceStartDate = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.sourceStartDate = true;
				}

				$scope.coCustomerInfo.incomeSources.takeHomePay = successData.additionalInfo.coCustomerIncomeSource[0].takeHome != null?successData.additionalInfo.coCustomerIncomeSource[0].takeHome : successData.additionalInfo.coCustomerIncomeSource[0].monthlyIncome;
				console.log('$scope.coCustomerInfo.incomeSources.monthlyIncome ::: ' + $scope.coCustomerInfo.incomeSources.takeHomePay);
				if(SharedDataSvc.isBlankOrNull($scope.coCustomerInfo.incomeSources.takeHomePay)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.takeHomePay = false; 
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.takeHomePay = true;
				}


				if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.coCustomerIncomeSource[0].nextPayday)){
					$scope.disablingFlag.coCustomerInfo.incomeSources.nextPayday = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.incomeSources.nextPayday = true;
					$scope.coCustomerInfo.incomeSources.nextPayDate.month = successData.additionalInfo.coCustomerIncomeSource[0].nextPayday.substr(4, 2);
					$scope.coCustomerInfo.incomeSources.nextPayDate.day = successData.additionalInfo.coCustomerIncomeSource[0].nextPayday.substr(6, 2);
				}


				if(successData.additionalInfo.isCarDetailsProvided){
					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.autoMakeAndModel)){
						$scope.disablingFlag.coCustomerInfo.auto.makeAndModel = false;
					}else{
						var model = successData.additionalInfo.autoMakeAndModel.split('-');
						$scope.custAdditionalInfo.auto.make = model[0];
						$scope.custAdditionalInfo.auto.model = model[1];
						$scope.disablingFlag.coCustomerInfo.auto.makeAndModel = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.tag)){
						$scope.disablingFlag.coCustomerInfo.auto.tag = false;
					}else{
						$scope.custAdditionalInfo.auto.tag = successData.additionalInfo.tag;
						$scope.disablingFlag.coCustomerInfo.auto.tag = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.color)){
						$scope.disablingFlag.coCustomerInfo.auto.color = false;
					}else{
						$scope.custAdditionalInfo.auto.color = successData.additionalInfo.color;
						$scope.disablingFlag.coCustomerInfo.auto.color = true;
					}

					if(SharedDataSvc.isBlankOrNull(successData.additionalInfo.carPaymentAmount)){
						$scope.disablingFlag.coCustomerInfo.auto.carPaymentAmount = false;
					}else{
						$scope.custAdditionalInfo.auto.amount = successData.additionalInfo.carPaymentAmount;
						$scope.disablingFlag.coCustomerInfo.auto.carPaymentAmount = true;
					}
				}

			}else{
				$scope.disablingFlag.address.custPaymentAmount = false;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceAddress.addrLine1)){
				$scope.disablingFlag.incomeSources.employerAddress = false;
			}else{
				$scope.disablingFlag.incomeSources.employerAddress = true;
				$scope.customerInfo.incomeSources.employerAddress = successData.incomeSources[0].sourceAddress.addrLine1;
			}

			$scope.customerInfo.incomeSources.deptTitle =  successData.incomeSources[0].department !=null ? successData.incomeSources[0].department : successData.incomeSources[0].sourceDepartment;//VAN-2401-PR stores to use SLI- AWP re-engagement
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].department)){
				$scope.disablingFlag.incomeSources.deptTitle = false;
			}else{
				$scope.disablingFlag.incomeSources.deptTitle = true;
				$scope.customerInfo.incomeSources.deptTitle = successData.incomeSources[0].department;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].supervisorName)){
				$scope.disablingFlag.incomeSources.supervisorName = false;
			}else{
				$scope.disablingFlag.incomeSources.supervisorName = true;
				$scope.customerInfo.incomeSources.supervisorName = successData.incomeSources[0].supervisorName;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].workHours)){
				$scope.disablingFlag.incomeSources.workHours = false;
			}else{
				$scope.disablingFlag.incomeSources.workHours = true;
				$scope.customerInfo.incomeSources.workHours = successData.incomeSources[0].workHours;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].bestPayDate)){
				$scope.disablingFlag.incomeSources.bestPayDate = false;
			}else{
				$scope.disablingFlag.incomeSources.bestPayDate = true;
				$scope.customerInfo.incomeSources.bestPayDate = successData.incomeSources[0].bestPayDate;
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].nextPayday)){
				$scope.disablingFlag.incomeSources.nextPayday = false;
			}else{
				$scope.disablingFlag.incomeSources.nextPayday = true;
				$scope.customerInfo.incomeSources.nextPayDate.month = successData.incomeSources[0].nextPayday.substr(4, 2);
				$scope.customerInfo.incomeSources.nextPayDate.day = successData.incomeSources[0].nextPayday.substr(6, 2);
			}

			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].paydayFrequency)){
				$scope.disablingFlag.incomeSources.payFrequency = false;
			}else{
				$scope.disablingFlag.incomeSources.payFrequency = true;
				$scope.customerInfo.incomeSources.payFrequency = successData.incomeSources[0].paydayFrequency;//VAN-2401-PR stores to use SLI- AWP re-engagement
				
			}

			
			
					
			if(successData.itinFlag) {
				$scope.customerPrivate.ssn.ssnType = 'ITIN';
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn1 = false;
			}else{
				$scope.customerPrivate.ssn.ssn1 = successData.social.substring(0,3);
				$scope.disablingFlag.ssn.ssn1 = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn2 = false;
			}else{
				$scope.customerPrivate.ssn.ssn2 = successData.social.substring(3,5);
				$scope.disablingFlag.ssn.ssn2 = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.social)){
				$scope.disablingFlag.ssn.ssn3 = false;
			}else{
				$scope.customerPrivate.ssn.ssn3 = successData.social.substring(5,9);
				$scope.disablingFlag.ssn.ssn3 = true;
			}
			
			$scope.customerInfo.driversLicense.id = successData.driversLicense.id;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.id)){
				$scope.disablingFlag.driversLicense.id = false;
			}else{
				$scope.disablingFlag.driversLicense.id = true;
			}
			
			$scope.customerInfo.driversLicense.issuer = successData.driversLicense.issuer;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.issuer)){
				$scope.disablingFlag.driversLicense.issuer = false;
			}else{
				$scope.disablingFlag.driversLicense.issuer = true;
			}
			
			$scope.customerInfo.driversLicense.idType = successData.driversLicense.idType;
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.idType)){
				$scope.disablingFlag.driversLicense.idType = false;
			}else{
				$scope.disablingFlag.driversLicense.idType = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.day = false;
			}else{
				$scope.customerPrivate.dob.day = successData.driversLicense.birthDate.substring(6,8);
				$scope.disablingFlag.dob.day = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.month = false;
			}else{
				$scope.customerPrivate.dob.month = successData.driversLicense.birthDate.substring(4,6);
				$scope.disablingFlag.dob.month = true;
			}
			
			
			if(SharedDataSvc.isBlankOrNull(successData.driversLicense.birthDate)){
				$scope.disablingFlag.dob.year = false;
			}else{
				$scope.customerPrivate.dob.year = successData.driversLicense.birthDate.substring(0,4);
				$scope.disablingFlag.dob.year = true;
			}
			
			$scope.customerInfo.phone1.number = successData.phones[0].areaCode+successData.phones[0].number;
			if(SharedDataSvc.isBlankOrNull(successData.phones[0].areaCode+successData.phones[0].number)){
				$scope.disablingFlag.phone1.number = false;
			}else{
				$scope.disablingFlag.phone1.number = true;
			}
			
			/* We need to send phoneType as null always. Whether it is
			 *  case of blank or null otherwise service will break.
			 *  */
			if(SharedDataSvc.isBlankOrNull(successData.phones[0].phoneType)){
				$scope.disablingFlag.phone1.phoneType = false;
				$scope.customerInfo.phone1.phoneType = null;
			}else{
				$scope.disablingFlag.phone1.phoneType = true;
				$scope.customerInfo.phone1.phoneType = successData.phones[0].phoneType;
			}
			
			/*
			if( successData.phones[0].bestCallTime == "M" ){  
							$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "No";
							$scope.customerInfo.phone1.bestCallTime.Evening = "No";
						}else if( successData.phones[0].bestCallTime == "A" ){
							$scope.customerInfo.phone1.bestCallTime.Morning = "No";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
							$scope.customerInfo.phone1.bestCallTime.Evening = "No";
						}else{
							$scope.customerInfo.phone1.bestCallTime.Morning = "No";
							$scope.customerInfo.phone1.bestCallTime.Afternoon = "No";
							$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
						}*/
			
			if( successData.phones[0].bestCallTime){ 
				var times = successData.phones[0].bestCallTime.split(",");
				console.log("[Best call Times]", times);
				for(var x=0; x< times.length; x++){
					times[x] = times[x].trim();
					if(times[x] == "M"){
						$scope.customerInfo.phone1.bestCallTime.Morning = "Yes";
					}else if(times[x] == "A"){
						$scope.customerInfo.phone1.bestCallTime.Afternoon = "Yes";
					}else if(times[x] == "E"){
						$scope.customerInfo.phone1.bestCallTime.Evening = "Yes";
					}else{
						$scope.disablingFlag.phone1.bestCallTime.morning = false;
						$scope.disablingFlag.phone1.bestCallTime.afternoon = false;
						$scope.disablingFlag.phone1.bestCallTime.evening = false;
					}
				}
			} else if(successData.phones[0].bestCallTime == ""){
				$scope.disablingFlag.phone1.bestCallTime.morning = false;
				$scope.disablingFlag.phone1.bestCallTime.afternoon = false;
				$scope.disablingFlag.phone1.bestCallTime.evening = false;
			}
			
			
			$scope.customerInfo.phone2.number = successData.phones[1].areaCode+successData.phones[1].number;
			if(SharedDataSvc.isBlankOrNull(successData.phones[1].areaCode+successData.phones[1].number)){
				$scope.disablingFlag.phone2.number = false; /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.phone2.number = true;
			}
			
			
			/* We need to send phoneType as null always. Whether it is
			 *  case of blank or null otherwise service will break.
			 *  */
			if(SharedDataSvc.isBlankOrNull(successData.phones[1].phoneType)){
				if(successData.phones[0].phoneType === "HOME" && $scope.allowTextVerificationFlag) {
					$scope.customerInfo.phone2.phoneType = "CELL";
					$scope.disablingFlag.phone2.phoneType = false;
				}else {
					$scope.disablingFlag.phone2.phoneType = false; /* Keeping this field always disabled as it is optional. */
					$scope.customerInfo.phone2.phoneType = null;	
				}				
			}else{
				$scope.disablingFlag.phone2.phoneType = true;
				$scope.customerInfo.phone2.phoneType = successData.phones[1].phoneType;
			}
			
			$scope.customerInfo.email = successData.email;
			//Story 30445 -AWP, Kiosk: Add email verification edit box - start
			if($scope.emailValidation.test(successData.email)) {
				$scope.validEmailPattern = true;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.email) || (!$scope.validEmailPattern)){
			//Story 30445 -AWP, Kiosk: Add email verification edit box - end
				$scope.disablingFlag.email = false;
			}else{
				$scope.disablingFlag.email = true;
			}
			
			//Story 30445 -AWP, Kiosk: Add email verification edit box - start
			$scope.customerInfo.emailVerify = successData.email;
			if(SharedDataSvc.isBlankOrNull(successData.email) || (!$scope.validEmailPattern)){
				$scope.disablingFlag.emailVerify = false;
			}else{
				$scope.disablingFlag.emailVerify = true;
			}
			//Story 30445 -AWP, Kiosk: Add email verification edit box - end
			$scope.customerInfo.incomeSources.income = successData.incomeSources[0].incomeType;
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].incomeType)){
				// set default income source if null
				$scope.customerInfo.incomeSources.income = $scope.incomeEnums[0].type;
				//$scope.disablingFlag.incomeSources.income = false;
			}else{
				//$scope.disablingFlag.incomeSources.income = true;
			}
			
			if(successData.incomeSources[0].sourceName != '0'){
				$scope.customerInfo.incomeSources.sourceName = successData.incomeSources[0].sourceName;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceName)){
				$scope.disablingFlag.incomeSources.sourceName = false;
			} else if(successData.incomeSources[0].sourceName == "0"){
				//$scope.disablingFlag.incomeSources.sourceName = false;
			} else {
				//$scope.disablingFlag.incomeSources.sourceName = true;
			}

			//$scope.customerInfo.incomeSources.monthlyIncome = successData.incomeSources[0].monthlyIncome;
			
			if(successData.incomeSources[0].sourcePhone.number !== '0000000'){
				$scope.customerInfo.incomeSources.sourcePhone.number = successData.incomeSources[0].sourcePhone.areaCode + successData.incomeSources[0].sourcePhone.number;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourcePhone.areaCode+successData.incomeSources[0].sourcePhone.number)){
				
				//$scope.disablingFlag.incomeSources.sourcePhone.number = false;
			}else{
				//$scope.disablingFlag.incomeSources.sourcePhone.number = true;
			}
			
			
			
			var startDateSelection;
			var monthThresholds = ['11', '23', '48', '60'];
			var startDate = moment(successData.incomeSources[0].sourceStartDate, 'YYYYMMDD');
			var monthsSinceStart = moment().diff(startDate, 'months');
			for (var i = 0; i < monthThresholds.length; i++) {
				if(monthsSinceStart <= monthThresholds[i] && monthsSinceStart ) {
					startDateSelection = monthThresholds[i];
					break;
				}
			}
			if (monthsSinceStart >= monthThresholds[monthThresholds.length-1]) {
				startDateSelection = monthThresholds[monthThresholds.length-1];
			}
			if(successData.incomeSources[0].sourceStartDate == ""){
				$scope.customerInfo.incomeSources.sourceStartDate = successData.incomeSources[0].sourceStartDate;
			} else {
				$scope.customerInfo.incomeSources.sourceStartDate = startDateSelection;
			}
			
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].sourceStartDate)){
				//$scope.disablingFlag.incomeSources.sourceStartDate = false;
			}else{
				//$scope.disablingFlag.incomeSources.sourceStartDate = true;
			}
			
			
			
			$scope.customerInfo.incomeSources.monthlyIncome = successData.incomeSources[0].monthlyIncome;
			console.log('$scope.customerInfo.incomeSources.monthlyIncome ::: ' + $scope.customerInfo.incomeSources.monthlyIncome);
			if(SharedDataSvc.isBlankOrNull(successData.incomeSources[0].monthlyIncome)){
				$scope.disablingFlag.incomeSources.monthlyIncome = true; /* Keeping this field always disabled as it is optional. */
			}else{
				$scope.disablingFlag.incomeSources.monthlyIncome = true;
			}
			
			//AC23127  code changes starts
			if (successData.personalReferences && successData.personalReferences.length > 0) {
				for (var i = 0; i < successData.personalReferences.length; i++) {										
					if ($scope.refType.indexOf(successData.personalReferences[i].personalReferenceType) < 0) {
						successData.personalReferences[i].personalReferenceType = "";
					}					
			}
			}
			//AC23127 code changes ends	
			
			
			/* Reference 1  */
			$scope.ref1.firstName = successData.personalReferences[0].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].firstName)){
				$scope.disablingFlag.ref1.firstName = false;
			}else{
				$scope.disablingFlag.ref1.firstName = true;
			}
			
			$scope.ref1.lastName = successData.personalReferences[0].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].lastName)){
				$scope.disablingFlag.ref1.lastName = false;
			}else{
				$scope.disablingFlag.ref1.lastName = true;
			}
			
			$scope.ref1.number = successData.personalReferences[0].phone.areaCode+successData.personalReferences[0].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].phone.areaCode+successData.personalReferences[0].phone.number)){
				$scope.disablingFlag.ref1.number = false;
			}else{
				$scope.disablingFlag.ref1.number = true;
			}
			
			$scope.ref1.personalReferenceType = successData.personalReferences[0].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].personalReferenceType)){
				$scope.disablingFlag.ref1.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref1.personalReferenceType = true;
			}

			$scope.ref1.address = successData.personalReferences[0].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].address[0].addrLine1)){
				$scope.disablingFlag.ref1.address = false;
			}else{
				$scope.disablingFlag.ref1.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref1.phoneType = successData.personalReferences[0].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[0].phone.phoneType)){
				$scope.disablingFlag.ref1.numberType = false;
			}else{
				$scope.disablingFlag.ref1.numberType = true;
			}

			/* Reference 2 */
			$scope.ref2.firstName = successData.personalReferences[1].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].firstName)){
				$scope.disablingFlag.ref2.firstName = false;
			}else{
				$scope.disablingFlag.ref2.firstName = true;
			}
			
			$scope.ref2.lastName = successData.personalReferences[1].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].lastName)){
				$scope.disablingFlag.ref2.lastName = false;
			}else{
				$scope.disablingFlag.ref2.lastName = true;
			}
			
			$scope.ref2.number = successData.personalReferences[1].phone.areaCode+successData.personalReferences[1].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].phone.areaCode+successData.personalReferences[1].phone.number)){
				$scope.disablingFlag.ref2.number = false;
			}else{
				$scope.disablingFlag.ref2.number = true;
			}
			
			$scope.ref2.personalReferenceType = successData.personalReferences[1].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].personalReferenceType)){
				$scope.disablingFlag.ref2.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref2.personalReferenceType = true;
			}

			$scope.ref2.address = successData.personalReferences[1].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].address[0].addrLine1)){
				$scope.disablingFlag.ref2.address = false;
			}else{
				$scope.disablingFlag.ref2.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref2.phoneType = successData.personalReferences[1].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[1].numberType)){
				$scope.disablingFlag.ref2.numberType = false;
			}else{
				$scope.disablingFlag.ref2.numberType = true;
			}
			

			/*  Reference 3  */
			
			$scope.ref3.firstName = successData.personalReferences[2].firstName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].firstName)){
				$scope.disablingFlag.ref3.firstName = false;
			}else{
				$scope.disablingFlag.ref3.firstName = true;
			}
			
			$scope.ref3.lastName = successData.personalReferences[2].lastName;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].lastName)){
				$scope.disablingFlag.ref3.lastName = false;
			}else{
				$scope.disablingFlag.ref3.lastName = true;
			}
			
			$scope.ref3.number = successData.personalReferences[2].phone.areaCode+successData.personalReferences[2].phone.number;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].phone.areaCode+successData.personalReferences[2].phone.number)){
				$scope.disablingFlag.ref3.number = false;
			}else{
				$scope.disablingFlag.ref3.number = true;
			}
			
			$scope.ref3.personalReferenceType = successData.personalReferences[2].personalReferenceType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].personalReferenceType)){
				$scope.disablingFlag.ref3.personalReferenceType = false;
			}else{
				$scope.disablingFlag.ref3.personalReferenceType = true;
			}

			$scope.ref3.address = successData.personalReferences[2].address[0].addrLine1;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].address[0].addrLine1)){
				$scope.disablingFlag.ref3.address = false;
			}else{
				$scope.disablingFlag.ref3.address = true;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement
			$scope.ref3.phoneType = successData.personalReferences[2].phone.phoneType;
			if(SharedDataSvc.isBlankOrNull(successData.personalReferences[2].phone.phoneType)){
				$scope.disablingFlag.ref3.numberType = false;
			}else{
				$scope.disablingFlag.ref3.numberType = true;
			}


			/*  Reference 4  */
			if($scope.isSpanishStore) {
				$scope.ref4.firstName = successData.personalReferences[3].firstName;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].firstName)){
					$scope.disablingFlag.ref4.firstName = false;
				}else{
					$scope.disablingFlag.ref4.firstName = true;
				}
				
				$scope.ref4.lastName = successData.personalReferences[3].lastName;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].lastName)){
					$scope.disablingFlag.ref4.lastName = false;
				}else{
					$scope.disablingFlag.ref4.lastName = true;
				}
				
				$scope.ref4.number = successData.personalReferences[3].phone.areaCode+successData.personalReferences[3].phone.number;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].phone.areaCode+successData.personalReferences[3].phone.number)){
					$scope.disablingFlag.ref4.number = false;
				}else{
					$scope.disablingFlag.ref4.number = true;
				}
				
				$scope.ref4.personalReferenceType = successData.personalReferences[3].personalReferenceType;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].personalReferenceType)){
					$scope.disablingFlag.ref4.personalReferenceType = false;
				}else{
					$scope.disablingFlag.ref4.personalReferenceType = true;
				}

				$scope.ref4.address = successData.personalReferences[3].address[0].addrLine1;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].address[0].addrLine1)){
					$scope.disablingFlag.ref4.address = false;
				}else{
					$scope.disablingFlag.ref4.address = true;
				}
				
				//VAN-2401-PR stores to use SLI- AWP re-engagement
				$scope.ref4.phoneType = successData.personalReferences[3].phone.phoneType;
				if(SharedDataSvc.isBlankOrNull(successData.personalReferences[3].phone.phoneType)){
					$scope.disablingFlag.ref4.numberType = false;
				}else{
					$scope.disablingFlag.ref4.numberType = true;
				}	
			}

			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			//CoCustomer Info validation
			if($scope.isSpanishStore) {
				$scope.coCustomerInfo.firstName = successData.coCustomerInfo.firstName;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.firstName)){
					$scope.disablingFlag.coCustomerInfo.firstName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.firstName = true;
				}

				$scope.coCustomerInfo.lastName =  successData.coCustomerInfo.lastName;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.lastName)){
					$scope.disablingFlag.coCustomerInfo.lastName = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.lastName = true;
				}

				$scope.coCustomerInfo.middleName = successData.coCustomerInfo.middleInitial;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.middleInitial)){
					$scope.disablingFlag.coCustomerInfo.middleName = false;  
				}else{
					$scope.disablingFlag.coCustomerInfo.middleName = true;
				}

				$scope.coCustomerInfo.relationshipType = successData.coCustomerInfo.relationshipType;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.relationshipType)){
					$scope.disablingFlag.coCustomerInfo.relationshipType = false;  
				}else{
					$scope.disablingFlag.coCustomerInfo.relationshipType = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn1 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn1 = successData.coCustomerInfo.social.substring(0,3);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn1 = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn2 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn2 = successData.coCustomerInfo.social.substring(3,5);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn2 = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.social)){
					$scope.disablingFlag.coCustomerInfo.ssn.ssn3 = false;
				}else{
					$scope.coCustomerPrivate.ssn.ssn3 = successData.coCustomerInfo.social.substring(5,9);
//					$scope.disablingFlag.coCustomerInfo.ssn.ssn3 = true;
				}

				$scope.coCustomerInfo.phone1.number = successData.coCustomerInfo.phoneNumber;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.phoneNumber)){
					$scope.disablingFlag.coCustomerInfo.phone1.number = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.phone1.number = true;
				}


				$scope.coCustomerInfo.phone1.phoneType = successData.coCustomerInfo.phoneType;
				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.phoneType)){
					$scope.disablingFlag.coCustomerInfo.phone1.phoneType  = false;
				}else{
					$scope.disablingFlag.coCustomerInfo.phone1.phoneType  = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.day = false;
				}else{
					$scope.coCustomerPrivate.dob.day = successData.coCustomerInfo.birthDate.substring(6,8);
					$scope.disablingFlag.coCustomerInfo.dob.day = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.month = false;
				}else{
					$scope.coCustomerPrivate.dob.month = successData.coCustomerInfo.birthDate.substring(4,6);
					$scope.disablingFlag.coCustomerInfo.dob.month = true;
				}

				if(SharedDataSvc.isBlankOrNull(successData.coCustomerInfo.birthDate)){
					$scope.disablingFlag.coCustomerInfo.dob.year = false;
				}else{
					$scope.coCustomerPrivate.dob.year = successData.coCustomerInfo.birthDate.substring(0,4);
					$scope.disablingFlag.coCustomerInfo.dob.year = true;
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			//Defect 28266 - VAN: References getting wiped off in HT - Starts
			console.log("If customer originator is SLI enable email,employee info, refernece details");
			if(successData.VanEngagement && successData.VanEngagement.customer.customerOriginator && 
					(successData.VanEngagement.customer.customerOriginator == "SLI" || successData.VanEngagement.customer.customerOriginator == "HighTouch") //VAN-813 New originator for HighTouch for HT originated approvals 
					&&  $scope.isReferenceRequired)
						{
					$scope.disablingFlag.email = false;
					$scope.disablingFlag.emailVerify = false;
					$scope.disablingFlag.incomeSources.income = false;
					$scope.disablingFlag.incomeSources.sourceName = false;
					$scope.disablingFlag.incomeSources.sourcePhone.number = false;
					$scope.disablingFlag.incomeSources.sourceStartDate = false;
					$scope.disablingFlag.incomeSources.monthlyIncome = false;
					$scope.disablingFlag.ref1.firstName = false;
					$scope.disablingFlag.ref1.lastName = false;
					$scope.disablingFlag.ref1.number = false;
					$scope.disablingFlag.ref1.personalReferenceType = false;
					$scope.disablingFlag.ref2.firstName = false;
					$scope.disablingFlag.ref2.lastName = false;
					$scope.disablingFlag.ref2.number = false;
					$scope.disablingFlag.ref2.personalReferenceType = false;
					$scope.disablingFlag.ref3.firstName = false;
					$scope.disablingFlag.ref3.lastName = false;
					$scope.disablingFlag.ref3.number = false;
					$scope.disablingFlag.ref3.personalReferenceType = false;
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
					$scope.disablingFlag.ref4.firstName = false;
					$scope.disablingFlag.ref4.lastName = false;
					$scope.disablingFlag.ref4.number = false;
					$scope.disablingFlag.ref4.personalReferenceType = false;
					$scope.disablingFlag.ref4.address = false;
					$scope.disablingFlag.ref4.numberType = false;
					$scope.disablingFlag.ref3.numberType = false;
					$scope.disablingFlag.ref2.numberType = false;
					$scope.disablingFlag.ref1.numberType = false;
					$scope.disablingFlag.ref3.address = false;
					$scope.disablingFlag.ref2.address = false;
					$scope.disablingFlag.ref1.address = false;
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
							}
			//Defect 28266 - VAN: References getting wiped off in HT - Ends
	
			$scope.$watch('customerPrivate.dob.month', generateDays);
			//$scope.$watch('customerPrivate.dob.year', addYears);
		}
		
		
		function generateDays(newVal, oldVal) {
			// April, June, September, November
			var daysThirty = ['04', '06', '09', '11'];
			// February
			var daysTwentyNine = ['02'];
			var dayNum = 0;
			$scope.days = [];
			if (newVal == undefined) {
				return;
			} else if (daysThirty.indexOf(newVal) > -1) {
				dayNum = 30;
			} else if (daysTwentyNine.indexOf(newVal) > -1) {
				dayNum = 29;
			} else {
				dayNum = 31;
			}
			var day = 1;
			// Add days to array, with 0 based numbers before 10
			while (day < dayNum + 1) {
				if (day < 10) {
					day = '0' + day;
				} else {
					day += '';
				}
				$scope.days.push(day);
				day++;
			}
		}

		function setPreviousAdd(){
			if($scope.custAdditionalInfo.moveInDate.month && $scope.custAdditionalInfo.moveInDate.year){
				var currentTime = new Date();
	        	var enteredDay = currentTime.getDate();
	        	var enteredMonth= $scope.custAdditionalInfo.moveInDate.month;
	        	var enteredYear = $scope.custAdditionalInfo.moveInDate.year;

				var enteredDateFormat = enteredYear+'/'+enteredMonth+'/'+enteredDay;
				var enDate = new Date(enteredDateFormat);


				var months;
	    		months = (currentTime.getFullYear() - enDate.getFullYear()) * 12;
	    		months -= enDate.getMonth() + 1;
	    		months += currentTime.getMonth();
	    		if(months > 12 ){
	    			$scope.showPreviousAdd = false;
	    		}else{
	    			$scope.showPreviousAdd = true;
	    		}
			}
		}
		
		function addYears() {
			for (var i = 18; i < 100; i++) {
				$scope.birthYears.push(moment().year() - i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.leaseYears.push(moment().year() + i);
			};

			for (var i = 0; i < 100; i++) {
				$scope.moveInYears.push(moment().year() - i);
			};
		}

		function getFullName(first, last) {
			if(first && last) {
				return first.toLowerCase() + ' ' + last.toLowerCase();
			}
			return undefined;
		}

		function getReferenceErrors(index) {
			return ['ref' + index + 'FirstName', 'ref' + index + 'LastName'];
		}

		function referenceNameSameAsCustomer(refName) {
			var custName = $scope.customerInfo.firstName + ' ' + $scope.customerInfo.lastName;
			if(refName && custName) {
				return refName.toLowerCase() === custName.toLowerCase() ? true : false;
			}
		}

		function referencePhoneSameAsCustomer(refPhone) {
			var custPhone = $scope.customerInfo.phone1.number;
			if(refPhone) {
				return refPhone === custPhone ? true : false;
			}
		}

		function clearSSN(){
			$scope.customerPrivate.ssn.ssn1 = '';
			$scope.customerPrivate.ssn.ssn2 = '';
			$scope.customerPrivate.ssn.ssn3 = '';
		}

		function validateReferenceName(index) {
			$scope.ref = [{
				first: $scope.customerInfoForm.newRefs.ref1FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref1LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref1FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref1LastName.$modelValue)
			}, 
			{
				first: $scope.customerInfoForm.newRefs.ref2FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref2LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref2FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref2LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref3FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref3LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref3FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			},
			{
				first: $scope.customerInfoForm.newRefs.ref4FirstName.$modelValue,
				last: $scope.customerInfoForm.newRefs.ref4LastName.$modelValue,
				full: getFullName($scope.customerInfoForm.newRefs.ref4FirstName.$modelValue, $scope.customerInfoForm.newRefs.ref3LastName.$modelValue)
			}];

			var currentRef = _.at($scope.ref, index);
			var fullName = getFullName($scope.customerInfoForm.newRefs['ref' + index + 'FirstName'].$modelValue, $scope.customerInfoForm.newRefs['ref' + index + 'LastName'].$modelValue);
			var matches = _.pluck(_.where($scope.ref, {'full': fullName}), 'full');
			var errorProp = 'ref'+index+'NameSubmitted';
			var propNames = getReferenceErrors(index);
			var first = propNames[0];
			var last = propNames[1];
			
			matches = _.filter(matches, function(item){
				if(!_.isUndefined(item)) return item;
			});

			var propFirstName = 'ref' + index + 'FirstName';
			var propLastName = 'ref' + index + 'LastName';

			if(matches.length > 1 || referenceNameSameAsCustomer(fullName)) {
				$scope.newRefs[first].$setValidity(first, false);
				$scope.newRefs[last].$setValidity(last, false);
				$scope[errorProp] = true;
			} else {
				$scope.newRefs[first].$setValidity(first, true);
				$scope.newRefs[last].$setValidity(last, true);
				$scope[errorProp] = false;
			}
		}

		// secondary phone number duplicate check
		function validateSecondaryPhone(index) {
			$scope.phoneNumbers = [
													{ 'number' : $scope.customerInfoForm.phoneSecondary.$modelValue},
													{ 'number' : $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref3Number.$modelValue }
													
												 ];		


			var currentNum = $scope.phoneNumbers[index-1].number;
			var matches = _.pluck(_.where($scope.phoneNumbers, { 'number' : currentNum }), 'number');
			var errorProp = 'phone' + index + 'NumberSubmitted';

			if(currentNum !== undefined) {
				if(currentNum.length === 10){
					$scope.secondaryNoTypeRequired = true;
				} else {
					$scope.secondaryNoTypeRequired = false;
				}			
			} 

			matches = _.filter(matches, function(item) {
				if(!_.isUndefined(item)) return item;
			});

			console.log('PROP SUB', errorProp);

			if(matches.length > 1 || referencePhoneSameAsCustomer(currentNum)) {
				$scope[errorProp] = true;
			} else {
				$scope[errorProp] = false;
			}
		}

		function validateReferencePhone(index) {
			$scope.refPhones = [
													{ 'number' : $scope.customerInfoForm.newRefs.ref1Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref2Number.$modelValue },
													{ 'number' : $scope.customerInfoForm.newRefs.ref3Number.$modelValue },
													{ 'number' : ($scope.customerInfoForm.newRefs.ref4Number ? $scope.customerInfoForm.newRefs.ref4Number.$modelValue : '')}
												 ];

			var currentRef = $scope.refPhones[index-1].number;
			var propName = 'ref' + index + 'Number';
			var matches = _.pluck(_.where($scope.refPhones, { 'number' : currentRef }), 'number');
			var errorProp = 'ref' + index + 'NumberSubmitted';

			matches = _.filter(matches, function(item) {
				if(!_.isUndefined(item)) return item;
			});

			console.log('PROP NAME', propName);
			console.log('PROP SUB', errorProp);
			if(matches.length > 1 || referencePhoneSameAsCustomer(currentRef)) {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, false);
				$scope[errorProp] = true;
			} else {
				$scope.customerInfoForm.newRefs[propName].$setValidity(propName, true);
				$scope[errorProp] = false;
			}
		}

		function validateDOB(dateOfBirth) {
			console.log('[Validating Bday]');
			if (dateOfBirth.year && dateOfBirth.month && dateOfBirth.day) {
				var isValid;
				var isDateValidOnLeapYear; // VAN-2842 Changes
				if ((dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month > $scope.dobvalid.month) || (dateOfBirth.year >= $scope.dobvalid.year && dateOfBirth.month == $scope.dobvalid.month && dateOfBirth.day > $scope.dobvalid.day)) {
					isValid = false;
				} else {
					isValid = true;
				}
				// VAN-2842 Changes Starts
				if (moment(dateOfBirth.month+"/"+dateOfBirth.day+"/"+dateOfBirth.year+"",'MM/DD/YYYY', true).isValid()){
					isDateValidOnLeapYear = true;
				} else {
					isDateValidOnLeapYear = false;
				}
				$scope.customerInfoForm.$setValidity('isDateValidOnLeapYear',isDateValidOnLeapYear );
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('isDateValidOnLeapYear', isDateValidOnLeapYear);
				// VAN-2842 Changes Ends
				$scope.customerInfoForm.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.year.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.month.$setValidity('validAge', isValid);
				$scope.customerInfoForm.dateOfBirth.day.$setValidity('validAge', isValid);
			}
		}

		function zipValidation(zip) {
			var pattern = /^([0-9]{5})$/;//Defect 26474 code change
			if(pattern.test(zip)) {
				isZipInStoreRadius(zip);
			} else {
				console.log('not valid zip - validate');
				$scope.isZipValid = false;
				console.log('FORM', $scope.customerInfoForm);
				$scope.customerInfoForm.zip.$setValidity('zip', false);
			}
		}

		function resetZipData() {
			$scope.customerInRadius = true;
			$scope.isZipValid = true;
			$scope.customerInfoForm.zip.$setValidity('zip', true);
		}

		function zipSubmitFalse(event) {
			if(event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		}

		function isZipInStoreRadius(zip, getFn) {
			getFn = getFn || function(){};
			getStoreInfoFactory.getLatLngFromZip(zip)
					.then(function(results) {
						console.log('ZIP TO LAT LNG', results);
			// Google API Implementation - Starts
			if (results.status == "OK") 
			{	
				var loc = results[0].geometry.location,
					customer = { 'lat': loc.lat(), 'lng': loc.lng() },
					// Google API Implementation - Ends
									distance = getStoreInfoFactory.getCustomerDistance($scope.storeLatLng, customer),
									isInAllowedRadius = getStoreInfoFactory.isCustomerInRadius($scope.storeInfo.allowedRentalRadiusMiles, distance),
									isCustomerInUS = getStoreInfoFactory.isCustomerInUS(results[0].address_components);

							console.log('Is in radius', isInAllowedRadius);
							console.log('Is in US', isCustomerInUS);
							console.log('distance', distance);
							$scope.customerDistance = Math.round(distance);

							if(isCustomerInUS && isInAllowedRadius) {
								$scope.customerInRadius = true;
								$scope.customerInfoForm.zip.$setValidity('zip', true);
								getFn("true");
							} else {
								$scope.customerInRadius = false;
								$scope.customerInfoForm.zip.$setValidity('zip', false);
								//AC23079 code change starts
								$scope.disablingFlag.address.addrLine1 = false;
								$scope.disablingFlag.address.addrLine2 = false;
								$scope.disablingFlag.address.city = false;
								$scope.disablingFlag.address.state = false;
								$scope.disablingFlag.address.zipCode = false;
								//AC23079 code change ends
								getFn("false");
							}

						} else {
							console.log('not valid zip');
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
							//Defect 26474 code changes starts
							$scope.disablingFlag.address.addrLine1 = false;
							$scope.disablingFlag.address.addrLine2 = false;
							$scope.disablingFlag.address.city = false;
							$scope.disablingFlag.address.state = false;
							$scope.disablingFlag.address.zipCode = false;
							//Defect 26474 code changes ends
							getFn("invalidZip");
						}
					}).
			catch(function(response){
				// sending error to Raygun
				SharedDataSvc.reportRaygun(response);
			});
		}

		function checkState(state) {
			// console.log('test', state);
			var unsupportedStates = $scope.stateRestrictions.unsupportedStates.split(",") || '';
			if (unsupportedStates.indexOf(state) > -1) {
				$scope.invalidState = modalService.open({
					title: 'State Not Supported',
					templateUrl: 'partials/alert_stateUnsupported.tpl.html',
					backdrop: 'static',
					size: 'sm',
					scope: $scope
				});
			}
		}
		
		//Story 30445 -AWP, Kiosk: Add email verification edit box - start		
		function compareEmailVerify(event,newvalue) {
			if($scope.customerInfoForm.emailAddressVerify.$invalid) {
				$scope.verifyEmail = true;
				$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
			}
			if($scope.customerInfoForm.emailAddress.$valid && $scope.customerInfoForm.emailAddressVerify.$valid) {
				if($scope.customerInfo.email) {
					if(newvalue.toUpperCase() === $scope.customerInfo.email.toUpperCase()){
						$scope.verifyEmail = true;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', true);
					} else {
						$scope.verifyEmail = false;
						$scope.customerInfoForm.emailAddressVerify.$setValidity('emailAddressVerify', false);
					}
				}
			} 			
		}
		//Story 30445 -AWP, Kiosk: Add email verification edit box - end

		function navigateTo(target) {
			modalService.dismiss();
			$location.path(target);
		}
		
		function cancelCustomerEntry() {
			var cancelCustSSNModal;
			// open modal only once
			cancelCustSSNModal = modalService.open({
				title: 'Existing Customer Verification',
				templateUrl: 'partials/confirm_discardAppChanges.tpl.html',
				backdrop: 'static',
				scope: $scope
			});
		}
		
		function saveAndProcess() {
			$scope.formSubmitted = true;
			$scope.draftSaved = false;
			
			$scope.customerInfoForm.firstName.$pristine = false;
			$scope.customerInfoForm.lastName.$pristine = false;
			
			$scope.customerInfoForm.newRefs.ref1FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref1LastName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref1address.$pristine = false;//VAN-2401-PR stores to use SLI- AWP re-engagement
			
			$scope.customerInfoForm.newRefs.ref2FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref2LastName.$pristine = false;
			
			$scope.customerInfoForm.newRefs.ref3FirstName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref3LastName.$pristine = false;
			$scope.customerInfoForm.newRefs.ref3address.$pristine = false;//VAN-2401-PR stores to use SLI- AWP re-engagement
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if($scope.isSpanishStore){
				$scope.customerInfoForm.newRefs.ref4FirstName.$pristine = false;
				$scope.customerInfoForm.newRefs.ref4LastName.$pristine = false;
				$scope.customerInfoForm.newRefs.ref4address.$pristine = false;}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			compareEmailVerify(event,$scope.customerInfo.emailVerify);//Story 30445 -AWP, Kiosk: Add email verification edit box
			
			
			var zipCode = $scope.customerInfo.address.zipCode?$scope.customerInfo.address.zipCode.substring(0,5):"";//Defect 26474 code changes
			if(!$scope.isSpanishStore) {
				isZipInStoreRadius(zipCode, function(status) {
					console.log(status);
					if(status === "true") {
						$scope.customerInRadius = true;
						$scope.isZipValid = true;
						saveAndProcessAppData();
					} else{
						if(status === "false"){
							$scope.customerInRadius = false;
							$scope.isZipValid = true;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}else {
							$scope.customerInRadius = true;
							$scope.isZipValid = false;
							$scope.customerInfoForm.zip.$setValidity('zip', false);
						}
					}
				});
			} else {
				saveAndProcessAppData();
			}
			
		}
		
		function saveAndProcessAppData() {
			if ($scope.customerInfoForm.$valid) {
				saveCustomerInfo().then( function(success){
					//Story 33452 changes starts
					if($rootScope.isSLICustomer){
						navigateTo('ordersummary/' + SharedDataSvc.globalVars.currentSLICustEngId);
					} 
					//Defect 28266 - VAN: References getting wiped off in HT - Starts
					else if($rootScope.createOrderFlow && $rootScope.VANEngagementId!=undefined){
						navigateTo('ordersummary/' + $rootScope.VANEngagementId);
						
					} 
					//Defect 28266 - VAN: References getting wiped off in HT - Ends
					else {
						$location.path('dashboard');
					}
					//Story 33452 changes ends
				}, function(error){
					modalService.dismiss();
					console.log("[Error in saving data]", error);
				});
			} else {
				console.log('Application Invalid, Check It');
				var error = $scope.customerInfoForm.$error;
				var invalidFields = [];
				angular.forEach(error.required, function(field) {
					if (field.$invalid) {
						var fieldName = field.$name;
						invalidFields.push(fieldName);
					}
				});
				$scope.scrollToTop("body"); // Added for scroll up VAN-10736
				console.log($scope.customerInfoForm);
				console.log("Invalid Fields: ", invalidFields);

			}
		}
		
		function saveCustomerInfo() {
			var deferred = $q.defer();

			modalService.processing('Saving Application');

			var employerAreaCode = '000',
				employerPhone = '0000000',
				monthlyIncome = '',
				incomeSourceName = '',
				bestCallTime = '',
				customerId = SharedDataSvc.globalVars.order.currentEngagement.VANCustomerID || SharedDataSvc.globalVars.order.currentEngagement.customerId,
				engagementId = SharedDataSvc.globalVars.order.currentEngagement.VANEngagementId || $routeParams.engagementId;
				var currYear = (new Date()).getFullYear();

			var mortgageName,mortgagePhone,avgUtilities,leaseEnd, leaseTypeRent,apartmentComplex,custPayment,homeType;

			SharedDataSvc.customerInfo = $scope.customerInfo;
			SharedDataSvc.customerPrivate = $scope.customerPrivate;

			if (typeof $scope.customerInfo.incomeSources.sourcePhone !== "undefined") {
				employerAreaCode = $scope.customerInfo.incomeSources.sourcePhone.number.substr(0, 3);
				employerPhone = $scope.customerInfo.incomeSources.sourcePhone.number.substr(3, 10);
			}
			if ($scope.customerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.customerInfo.incomeSources.nextPayDate))) {
				var nextPayDate = currYear +  $scope.customerInfo.incomeSources.nextPayDate.month + $scope.customerInfo.incomeSources.nextPayDate.day;
			}
			
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
			if ($scope.coCustomerInfo.incomeSources.nextPayDate && _.every(['month', 'day'], _.partial(_.has, $scope.coCustomerInfo.incomeSources.nextPayDate))) {
				var coCustNextPayDate = currYear +  $scope.coCustomerInfo.incomeSources.nextPayDate.month + $scope.coCustomerInfo.incomeSources.nextPayDate.day;
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
			
			if ($scope.customerInfo.incomeSources.income) {
				switch ($scope.customerInfo.incomeSources.income) {
					case 'EMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					case 'SELFEMPLOYED':
						incomeSourceName = $scope.customerInfo.incomeSources.sourceName || '';
						break;
					default:
						incomeSourceName = $scope.customerInfo.incomeSources.income;
				}
			}

			if ($scope.customerInfo.incomeSources.monthlyIncome) {
				monthlyIncome = $scope.customerInfo.incomeSources.monthlyIncome.replace('$', '');
			} 

			if (_($scope.customerInfo.phone1).has('bestCallTime')) {
				if ($scope.customerInfo.phone1.bestCallTime.Morning === 'Yes') {
					bestCallTime += 'M';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Afternoon === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'A';
				}
				if ($scope.customerInfo.phone1.bestCallTime.Evening === 'Yes') {
					if (bestCallTime.length > 0) bestCallTime += ', ';
					bestCallTime += 'E';
				}
			}

			if($scope.customerInfo.address){
				if($scope.customerInfo.address.homeType === 'RENT'){
					leaseTypeRent = $scope.custAdditionalInfo.rent.leaseTypeRent;
					apartmentComplex = $scope.custAdditionalInfo.rent.apmtComplex;
				}

				if($scope.customerInfo.address.homeType === 'RENT' || $scope.customerInfo.address.homeType === 'OWN'){
					mortgageName = $scope.custAdditionalInfo.ownRentInfo.mortgageCompany;
					mortgagePhone = $scope.custAdditionalInfo.ownRentInfo.mortgageCompanyNum;
					avgUtilities = $scope.custAdditionalInfo.ownRentInfo.avgUtilities;
					custPayment = $scope.custAdditionalInfo.ownRentInfo.custPaymentAmount;
				}
			}

				var ccMoveInDate =  $scope.custAdditionalInfo.moveInDate.year + $scope.custAdditionalInfo.moveInDate.month + "01" ;

					if($scope.custAdditionalInfo.leaseEndDate.month && $scope.custAdditionalInfo.leaseEndDate.year){
						leaseEnd =  $scope.custAdditionalInfo.leaseEndDate.year + $scope.custAdditionalInfo.leaseEndDate.month + "01";
					}

			var incType = $scope.customerInfo.incomeSources.income;

			var mData = {
				lastName: $scope.customerInfo.lastName,
				firstName: $scope.customerInfo.firstName,
				middleInitial: $scope.customerInfo.middleName,
				customerId: customerId,
				engagementId: engagementId,
				email: $scope.customerInfo.email,
				orderOriginator: "AWP",
				referer: "",
				primaryPhoneNumber: $scope.customerInfo.phone1.number,
				phones: [{
					areaCode: $scope.customerInfo.phone1.number.substr(0, 3),
					number: $scope.customerInfo.phone1.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone1.phoneType,
					bestCallTime: bestCallTime,
					contactName: '',
					phoneSet: "CUSTPHN1"
				}],
				languagePreference: "ENGLISH",
				address: [{
					addrLine1: $scope.customerInfo.address.addrLine1,
					//Changes added for AC13229
					addrLine2: "",
					unit: $scope.customerInfo.address.addrLine2,
					city: $scope.customerInfo.address.city,
					state: $scope.customerInfo.address.state,
					zipCode: $scope.customerInfo.address.zipCode
				}],
				driversLicense: {
					id: $scope.customerInfo.driversLicense.id,
					issuer: $scope.customerInfo.driversLicense !== undefined && ($scope.customerInfo.driversLicense.idType == 1 || $scope.customerInfo.driversLicense.idType == 2) ? $scope.customerInfo.driversLicense.issuer : "",
					idType: $scope.customerInfo.driversLicense.idType
				},
				incomeSources: [{
					incomeSourceSet: "INCSRC1",
					sourcePhone: {
						phoneType: 'HOME',
						areaCode: employerAreaCode,
						number: employerPhone,
						extension: '',
						bestCallTime: 'xxx'
					},
					sourceStartDate: '',
					incomeType : incType,
					sourceName: incomeSourceName,
					monthlyIncome: monthlyIncome,
					paydayFrequency : $scope.customerInfo.incomeSources.payFrequency,
					department : $scope.customerInfo.incomeSources.deptTitle !==undefined ? $scope.customerInfo.incomeSources.deptTitle : "",
					workHours : $scope.customerInfo.incomeSources.workHours !== undefined ? $scope.customerInfo.incomeSources.workHours: "",
					supervisorName :$scope.customerInfo.incomeSources.supervisorName !== undefined ? $scope.customerInfo.incomeSources.supervisorName: "",
					bestPayDate : $scope.customerInfo.incomeSources.bestPayDate,
					nextPayDate : nextPayDate !== undefined ? nextPayDate : "",
					sourceDepartment : $scope.customerInfo.incomeSources.deptTitle !==undefined ? $scope.customerInfo.incomeSources.deptTitle : ""//VAN-2401-PR stores to use SLI- AWP re-engagement 
				}]
			};

			if($scope.isSpanishStore){
				mData.address[0].leaseType =  leaseTypeRent !== undefined ? leaseTypeRent : "" ;
				mData.address[0].landLordName =  mortgageName !== undefined ? mortgageName : "";
				mData.address[0].landLordNumber =  mortgagePhone !== undefined ? mortgagePhone : "";
				mData.address[0].apartmentComplex =  apartmentComplex !== undefined ? apartmentComplex :"";
				mData.address[0].moveInDate =  ccMoveInDate;
				mData.address[0].leaseEndDate =  leaseEnd !== undefined ? leaseEnd : "";
				mData.address[0].isRenewingLease =  $scope.customerInfo.address.homeType !== undefined ? $scope.customerInfo.address.homeType: "";

				
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
				if ($scope.coCustomerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.coCustomerPrivate.ssn))) {
					var SSN = $scope.coCustomerPrivate.ssn;
					if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
						$scope.coCustSocial = $scope.coCustomerPrivate.ssn.ssn1 + $scope.coCustomerPrivate.ssn.ssn2 + $scope.coCustomerPrivate.ssn.ssn3;
					}
				}
				$scope.makeAndModel = $scope.custAdditionalInfo.auto.make + "-" + $scope.custAdditionalInfo.auto.model;
				//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends
				mData.additionalInfo = {
					previousAddress : [{
						addrLine1 : $scope.custAdditionalInfo.previousAddress.addrLine1 !== undefined ? $scope.custAdditionalInfo.previousAddress.addrLine1 : "",
						landLordName : $scope.custAdditionalInfo.previousAddress.mortgageName !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgageName : "",
						landLordNumber : $scope.custAdditionalInfo.previousAddress.mortgagePhone !== undefined ? $scope.custAdditionalInfo.previousAddress.mortgagePhone : ""
					}],
					//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
					autoMakeAndModel :  $scope.makeAndModel !== undefined ? $scope.makeAndModel :"",
					tag : $scope.custAdditionalInfo.auto.tag !== undefined ? $scope.custAdditionalInfo.auto.tag : "",
					color : $scope.custAdditionalInfo.auto.color !== undefined ? $scope.custAdditionalInfo.auto.color : "",
					carPaymentAmount : $scope.custAdditionalInfo.auto.amount !== undefined ? $scope.custAdditionalInfo.auto.amount : "",
					averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :"",
					isCarDetailsProvided: !$scope.custAdditionalInfo.auto.noCar,
					customerPaymentAmount : custPayment !== undefined ? custPayment : "",
									
					coCustomerIncomeSource : [{
						sourceName : $scope.coCustomerInfo.incomeSources.sourceName  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceName  : "",
						department : $scope.coCustomerInfo.incomeSources.deptTitle  !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle  : "",
						supervisorName : $scope.coCustomerInfo.incomeSources.supervisorName !== undefined ? $scope.coCustomerInfo.incomeSources.supervisorName  : "",
						workHours : $scope.coCustomerInfo.incomeSources.workHours  !== undefined ? $scope.coCustomerInfo.incomeSources.workHours : "",
						bestPayDate : $scope.coCustomerInfo.incomeSources.bestPayDate  !== undefined ? $scope.coCustomerInfo.incomeSources.bestPayDate  : "",
						paydayFrequency : $scope.coCustomerInfo.incomeSources.payFrequency  !== undefined ? $scope.coCustomerInfo.incomeSources.payFrequency  : "",	
						sourceStartDate : $scope.coCustomerInfo.incomeSources.sourceStartDate  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceStartDate  : "",
						sourcePhone : {
							areACode: $scope.coCustomerInfo.incomeSources.sourcePhone.number !== undefined ? $scope.coCustomerInfo.incomeSources.sourcePhone.number.substring(0,2) : "",
							number: $scope.coCustomerInfo.incomeSources.sourcePhone.number !== undefined ? $scope.coCustomerInfo.incomeSources.sourcePhone.number.substring(3,9)  : "",
							phoneType: $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType : "HOME"
						},
						sourceDepartment : $scope.coCustomerInfo.incomeSources.deptTitle  !== undefined ? $scope.coCustomerInfo.incomeSources.deptTitle  : "",
						nextPayday : coCustNextPayDate  !== undefined ? coCustNextPayDate : "",
						sourceAddress : {
							addrLine1: $scope.coCustomerInfo.incomeSources.employerAddress  !== undefined ? $scope.coCustomerInfo.incomeSources.employerAddress  : ""
						},
						monthlyIncome : $scope.coCustomerInfo.incomeSources.takeHomePay !== undefined ? $scope.coCustomerInfo.incomeSources.takeHomePay  : "",
						incomeType : $scope.coCustomerInfo.incomeSources.income  !== undefined ? $scope.coCustomerInfo.incomeSources.income  : "",
						incomeSourceSet : $scope.coCustomerInfo.incomeSources.incomeSourceSet  !== undefined ? $scope.coCustomerInfo.incomeSources.incomeSourceSet  : "",
						takeHome : $scope.coCustomerInfo.incomeSources.takeHomePay  !== undefined ? $scope.coCustomerInfo.incomeSources.takeHomePay  : "",
						lengthOfEmployment : $scope.coCustomerInfo.incomeSources.sourceStartDate  !== undefined ? $scope.coCustomerInfo.incomeSources.sourceStartDate  : "",
						coCustomerMonIncome : $scope.coCustomerInfo.incomeSources.coCustomerMonIncome  !== undefined ? $scope.coCustomerInfo.incomeSources.coCustomerMonIncome  : ""
					}]
				//	}],
				//	averageMonthlyUtilities : avgUtilities !== undefined ? avgUtilities :""
				},
				mData.coCustomerInfo= {
					lastName : $scope.coCustomerInfo.lastName !== undefined ? $scope.coCustomerInfo.lastName  : "",
					firstName : $scope.coCustomerInfo.firstName !== undefined ? $scope.coCustomerInfo.firstName  : "",
					middleInitial : $scope.coCustomerInfo.middleInitial !== undefined ? $scope.coCustomerInfo.middleInitial  : "",
					birthDate : $scope.coCustomerInfo.dob !== undefined ? $scope.coCustomerPrivate.dob.year +$scope.coCustomerPrivate.dob.month +$scope.coCustomerPrivate.dob.day  : "",
					social : $scope.coCustSocial !== undefined ? $scope.coCustSocial  : "",
//					custInfoType : $scope.coCustomerInfo.custInfoType !== undefined ? $scope.coCustomerInfo.custInfoType  : "",
					relationshipType : $scope.coCustomerInfo.relationshipType !== undefined ? $scope.coCustomerInfo.relationshipType  : "",
					phoneNumber : $scope.coCustomerInfo.phone1.number !== undefined ? $scope.coCustomerInfo.phone1.number  : "",
					phoneType : $scope.coCustomerInfo.phone1.phoneType !== undefined ? $scope.coCustomerInfo.phone1.phoneType  : ""
				
				}
			}
			//VAN-2401-PR stores to use SLI- AWP re-engagement - Ends

			// Check if listed keys exist in form; for saving form properly
			if ($scope.customerPrivate.ssn && _.every(['ssn1', 'ssn2', 'ssn3'], _.partial(_.has, $scope.customerPrivate.ssn))) {
				var SSN = $scope.customerPrivate.ssn;
				if (SSN.ssn1.length === 3 && SSN.ssn2.length === 2 && SSN.ssn3.length === 4) {
					mData.social = $scope.customerPrivate.ssn.ssn1 + $scope.customerPrivate.ssn.ssn2 + $scope.customerPrivate.ssn.ssn3;
				}
			}

			if($scope.customerPrivate.ssn.ssnType == 'ITIN'){
				mData.itinFlag = true;
			}else{
				mData.itinFlag = false;
			}

			if ($scope.customerPrivate.dob && _.every(['month', 'day', 'year'], _.partial(_.has, $scope.customerPrivate.dob))) {
				mData.driversLicense.birthDate = $scope.customerPrivate.dob.year + $scope.customerPrivate.dob.month + $scope.customerPrivate.dob.day;
			}

			if($scope.isSpanishStore){
					var referenceCount = 4;
				}else{
					var referenceCount = 3;
			}

			var referencesArray = [];
			if($scope.storeInfo.isReferenceRequired !== false) {
				for (var i = 0; i < referenceCount; i++) {
					var formReference = $scope['ref' + (i + 1)],reference,set;
					set = "PERREF" + (i + 1);
					if(_(formReference).has('personalReferenceType') && formReference.personalReferenceType !== '') {
						reference = {
							lastName: formReference.lastName,
							firstName: formReference.firstName,
							personalReferenceType: formReference.personalReferenceType,
							address: [{
								addrLine1: formReference.address
							}],
							personalReferenceSet : set,
							phone: {
								areaCode: formReference.number.substr(0, 3),
								number: formReference.number.substr(3, 10),
								phoneType: formReference.phoneType,
								bestCallTime: ''
							}
						};
						referencesArray.push(reference);
					}
				}	
			}
			mData.personalReferences = referencesArray;

			if (_($scope.customerInfo).has('phone2')
			&& _($scope.customerInfo.phone2).has('number')
			&& $scope.customerInfo.phone2.number.length > 9) {
				var phone2 = {
					areaCode: $scope.customerInfo.phone2.number.substr(0, 3),
					number: $scope.customerInfo.phone2.number.substr(3, 10),
					extension: '',
					phoneType: $scope.customerInfo.phone2.phoneType,
					contactName: '',
					phoneSet: "CUSTPHN2"
				};
				mData.phones.push(phone2);
			}

			if ($scope.customerInfo.incomeSources.sourceStartDate !== '') {
				mData.incomeSources[0].sourceStartDate = moment().subtract('months', $scope.customerInfo.incomeSources.sourceStartDate).format("YYYYMMDD");
			}
			mData.isMissingInfo = true;//Story 30647 code change
			// save to /customer
			applicationService.saveApplication(mData)
				.then(
					function(response) {
						deferred.resolve('success');
					},
					function(error) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(error);
						if(error.data.errorData.status_message === 'SSN invalid'){
							modalService.error(error.data.errorData.status_message);
						}
						deferred.reject(error);
					}
				);

			return deferred.promise;
		}
		
		//VAN-2401-PR stores to use SLI- AWP re-engagement - Starts
		function updateCoIncome(){
			if($scope.custAdditionalInfo.noCocustomer){
				$scope.custAdditionalInfo.noCocustomerIncome = true;
				$scope.coCustomerInfo.firstName = "";
				$scope.coCustomerInfo.lastName = "";
				$scope.coCustomerInfo.middleName =  "";
				$scope.coCustomerInfo.relation = "";
				$scope.coCustomerInfo.phone1.number = ""; 
				$scope.coCustomerInfo.phone1.phoneType = null;	
				$scope.coCustomerInfo.driversLicense.id = "";
				$scope.coCustomerInfo.driversLicense.issuer = "";
				$scope.coCustomerInfo.driversLicense.idType = "";
				$scope.coCustomerInfo.incomeSources.income = null;
			}else{
				$scope.custAdditionalInfo.noCocustomerIncome = false;
				$scope.coCustomerInfo.firstName = $rootScope.findData.coCustomerInfo.firstName;
				$scope.coCustomerInfo.lastName =  $rootScope.findData.coCustomerInfo.lastName;
				$scope.coCustomerInfo.middleName =  $rootScope.findData.coCustomerInfo.middleInitial;
				$scope.coCustomerInfo.relation = $rootScope.findData.coCustomerInfo.relationshipType;
				$scope.coCustomerInfo.phone1.number = $rootScope.findData.coCustomerInfo.phoneNumber; 
				$scope.coCustomerInfo.phone1.phoneType = $rootScope.findData.coCustomerInfo.phoneType != '' || $rootScope.findData.coCustomerInfo.phoneType != null?$rootScope.findData.coCustomerInfo.phoneType:null;	
				$scope.coCustomerInfo.driversLicense.id = $rootScope.findData.coCustomerInfo.driversLicense.id;
				$scope.coCustomerInfo.driversLicense.issuer = $rootScope.findData.coCustomerInfo.driversLicense.issuer;
				$scope.coCustomerInfo.driversLicense.idType = $rootScope.findData.coCustomerInfo.driversLicense.idType;
				$scope.coCustomerInfo.incomeSources.income = $rootScope.findData.additionalInfo != undefined || $rootScope.findData.additionalInfo != null?$rootScope.findData.additionalInfo.coCustomerIncomeSource[0].incomeType :null;
			}	
		}

		function updateCocustomerIncome() {
			if($scope.custAdditionalInfo.noCocustomerIncome){
				$scope.coCustomerInfo.incomeSources.income = null;
			}else{
				$scope.coCustomerInfo.incomeSources.income = $rootScope.findData.additionalInfo != undefined || $rootScope.findData.additionalInfo != null?$rootScope.findData.additionalInfo.coCustomerIncomeSource[0].incomeType :null;
			}
		}
		//VAN-2401-PR stores to use SLI- AWP re-engagement  - Ends
		
		
	}
	
})();