angular
    .module('myApp')
    .directive('datepickerpopup', datepickerpopup);

function datepickerpopup() {
    var directive = {
        link: link,
        template: '<p class="input-group"><input type="text" class="form-control" datepicker-popup="{{datepickerpopup.format}}" show-button-bar="false" ng-model="datepickerpopup.dt" is-open="datepickerpopup.opened" min-date="datepickerpopup.ipMinDate" max-date="datepickerpopup.ipMaxDate" datepicker-options="datepickerpopup.dateOptions" date-disabled="disabled(date, mode)" ng-required="true" close-text="Close" ng-change="datepickerpopup.getSelectedDate(datepickerpopup.dt)" /><span class="input-group-btn"><button type="button" class="btn btn-default" ng-click="datepickerpopup.dateChange($event)"><i class="glyphicon glyphicon-calendar"></i></button></span></p>',
        restrict: 'EA',
        controller: DatePickerPopupController,
        controllerAs: 'datepickerpopup'
    };

    return directive;

    function link(scope, element, attrs) {

    }
}

DatePickerPopupController.$inject = ['$scope', 'SharedDataSvc','datesFactory', '$cookieStore'];

function DatePickerPopupController($scope, SharedDataSvc, datesFactory, $cookieStore) {
    var vm = this;
    $scope.isSpanishStore = $cookieStore.get('isSpanishStore');
    vm.isDateSelected = SharedDataSvc.quoteCalculator.selectedDate;
    vm.isAdjustablePayment = false;
    vm.arrAdjustablePayment = SharedDataSvc.globalVars.storeSetup.adjustableInitPayment.split(",");
    vm.isAdjustablePayment = parseInt(vm.arrAdjustablePayment[0]);

    if (vm.isAdjustablePayment) {
        var start = parseInt(vm.arrAdjustablePayment[1]);
        var end = parseInt(vm.arrAdjustablePayment[2]);
        console.log(vm.arrAdjustablePayment);
        
        datesFactory.getDateByDaysFromNow(start).then(function(response) {
            vm.ipMinDate = response;
            //vm.dt = vm.ipMinDate._d;
            if(vm.isDateSelected !== null) {
                vm.dt = SharedDataSvc.quoteCalculator.selectedDate;
            } 
            datesFactory.getDateByDaysFromNow(end).then(function(responseData) {
                vm.ipMaxDate = responseData;
            });
        });
    }

    vm.formats = ['dd-MMMM-yyyy', 'MMMM-dd-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[1];
    if($scope.isSpanishStore)
        vm.format = vm.formats[0];
    vm.dateOptions = {
        formatYear: 'yy',
        startingDay: 0,
        showWeeks: false
    };

    vm.dateChange = dateChange;
    vm.getSelectedDate = getSelectedDate;

    function dateChange($event) {
        $event.preventDefault();
        $event.stopPropagation();
        vm.opened = true;
    }

    function getSelectedDate(selectedDate) {
        SharedDataSvc.quoteCalculator.selectedDate = selectedDate;
        SharedDataSvc.quoteCalculator.date = moment(selectedDate).format('YYYY-MM-DD HH:MM:SS');
        console.log(vm.dt);
    }
}