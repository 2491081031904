///////////////////////////////////////////
//////////////////////////////////////////
///   ORDER SUMMARY CONTROLLER   ////////
////////////////////////////////////////
///////////////////////////////////////
(function() {

	'use strict';

	angular
		.module('app.order')
		.controller('OrderSummary', OrderSummary);

	function OrderSummary(
		$scope,
		$rootScope,
		$http,
		$parse,
		$timeout,
		$compile,
		$routeParams,
		$location,
		$cookieStore,
		modalService,
		filterFilter,
		orderService,
		SharedDataSvc,
		authentication,
		updateEngagementItemFactory,
		orderEstimatePutFactory,
		datesFactory,
		stateRestrictFactory,
		getStoreInfoFactory,
		customerDataFactory,
		
		//Feature 9500 - VAN - Business Demo Changes - Starts
		productCategoriesFactory,
		//Feature 9500 - VAN - Business Demo Changes - Ends
		agreementFactory,
		productFactory
	) {
		/*jshint validthis: true */
		var orderSummary = this;

		orderSummary.editOrderMode = false;
		orderSummary.globalVars = {};
		orderSummary.globalVars.order = {
			currentEngagement: {},
			currentEngagementOrder: {}
		};
		$rootScope.hideMenuButtons = false;
		orderSummary.routeId = $routeParams.engagementId;
		orderSummary.editItemForm = {};
		orderSummary.addItemForm = [];
		orderSummary.SharedDataSvc = SharedDataSvc;
		orderSummary.globalmessage = SharedDataSvc.globalmessage;
		orderSummary.globalmessageShow = SharedDataSvc.globalmessageShow;
		orderSummary.invoiceReductionPayment = SharedDataSvc.invoiceReductionPayment; 
		orderSummary.invoiceAmountCalculate;
		orderSummary.hasItems = null;
		orderSummary.checkingOrder = true;
		orderSummary.allowExtendedWarranty = false;
		orderSummary.restrictions;
		orderSummary.processingFee;
		orderSummary.storeInfo;
		orderSummary.modal;
		orderSummary.associatePassFail = false;
		orderSummary.noEmpPass = false;
		orderSummary.approvalAmount;
		orderSummary.hasApproval = false;
		orderSummary.disableOrderButtons = false;
		orderSummary.disableOrderButtonsOnSetAbsent = false;
		orderSummary.disableReviewEstimate = false;
		orderSummary.submitInvoiceAndSalesDetails = submitInvoiceAndSalesDetails;
		orderSummary.invoiceNumber = '';
		orderSummary.associate = '';
		orderSummary.errors = {
			invoiceNumber: false,
			associate: false
		};
		orderSummary.prodCat;
		orderSummary.isProdCatValLoaded = false;
		// PUBLIC METHODS
		orderSummary.calcTotals = calcTotals;
		orderSummary.navigateTo = navigateTo;
		orderSummary.openOrderBuilder = openOrderBuilder;
		orderSummary.openSetMode = openSetMode;
		orderSummary.removeItemConfirm = removeItemConfirm;
		orderSummary.removeItemConfirmConfirm = removeItemConfirmConfirm;
		orderSummary.confirmOrder = confirmOrder;
		orderSummary.editModalRun = editModalRun;
		orderSummary.editOrderItem = editOrderItem;
		orderSummary.getOrderEstimate = getOrderEstimate;
		orderSummary.proceedOrderSave = proceedOrderSave;
		orderSummary.done = done;
		orderSummary.saveOrder = saveOrder;
		orderSummary.confirmId = confirmId;
		orderSummary.showModal = showModal;
		orderSummary.showDependencyNote = showDependencyNote;
		orderSummary.feeCheck = feeCheck;//AC 25341 code change
		$scope.submitEmployeePass = submitEmployeePass;
		$scope.submitEmployeePassWithOutSaveOrder = submitEmployeePassWithOutSaveOrder;
		$scope.closeSetItem = closeSetItem; //AC 26202 changes
		$scope.name;
		$scope.empPass;

		var existingData = false;

		//save Data before navigating to AP dashboard or training library
		$scope.$on("saveAppData",function(event, path) {
			console.log("Broadcast Event: saveAppData");
			//check if secondary number is filled then secondary phone type is valid

			if (!checkMinMax()) return;
			modalService.processing('Saving Order');
			if(path.indexOf("training") > -1) {
				orderService.save(SharedDataSvc.globalVars.order, 'InProgress');
				$rootScope.loginTrainingLibrary();
			}else {
				orderService.save(SharedDataSvc.globalVars.order, 'InProgress', '/dashboardAgreement');
			}



		/*	saveCustomerInfo().then(function() {
				if(path.indexOf("training") > -1) {
					$rootScope.loginTrainingLibrary();
				}else {
					$location.path("/dashboardAgreement");
				}
				return true;
			}); */
		});

		function init() {
			$rootScope.hideDashboardButton = true;
			$rootScope.hideDashboardAgreementButton = false;
			$rootScope.hideTrainingLibButton = false;
			SharedDataSvc.globalVars.order.currentOrderSubTotal = 0.00;	//Story 28508 Changes
			// Check if an engagement is loaded in from the dashboard to shared data service
			if (SharedDataSvc.globalVars.order.currentEngagement.engagementId !== null
			&& SharedDataSvc.globalVars.order.currentEngagement.engagementId === Number(orderSummary.routeId)) {
				var sharedDataEngagement = SharedDataSvc.globalVars.order.currentEngagement;
				orderSummary.globalVars.order.currentEngagement = sharedDataEngagement;
				console.log(sharedDataEngagement);
				orderSummary.checkingOrder = false;
				orderSummary.showName = true;
				existingData = true;

				if (sharedDataEngagement.orderContent) {
					processOrderContent(sharedDataEngagement.orderContent);
				} else {
					orderSummary.hasItems = false;
					startNewOrder(sharedDataEngagement);
				}
				if (sharedDataEngagement.approvalAmount && sharedDataEngagement.applicationStatus !== "InProgress") {
					orderSummary.approvalAmount = parseFloat(sharedDataEngagement.approvalAmount);
				}
			}

			// Get state/store restrictions
			getStoreInfoFactory.getData()
				.then(function(storeInfo) {
					
					//Feature 9500 - VAN - Business Demo Changes - Starts
					productCategoriesFactory.getData().then(function(response) {
						console.log('[OrderBuilder.init1] response: ' + response);
						var orderAWPitemgroupmessage;
						for(var i=0;i<response.itemGroupVendorSet.length;i++){
							if(response.itemGroupVendorSet[i].vendor.indexOf("|" + storeInfo.storeCode +"|")>=0){
								orderAWPitemgroupmessage = response.itemGroupVendorSet[i].awpItemGroupMessage;
							}
						}
			//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes-Start

					/*	if(orderAWPitemgroupmessage!=undefined){
							var openAlert = modalService.open({
								template: orderAWPitemgroupmessage,
								backdrop: 'static'
							});
						}*/
			//VAN-2607-Remove restriction on combining items during order creation for vendors like Ashley and Lowes-Ends
					});
					//Feature 9500 - VAN - Business Demo Changes - Ends
					orderSummary.storeInfo = storeInfo;
				//VAN-2808-AWP-Displaying error messages if we give ESV and DF values between 41 to 99 in both Manned and Unmanned stores-starts	
					orderSummary.storeInfo.maximumDeliveryfee = parseFloat(orderSummary.storeInfo.maximumDeliveryfee + '.00');//VAN-1393- $400 Max ESV and Delivery fee
					orderSummary.storeInfo.maximumEsvfee = parseFloat(orderSummary.storeInfo.maximumEsvfee + '.00');//VAN-1393- $400 Max ESV and Delivery fee
					//VAN-2808-AWP-Displaying error messages if we give ESV and DF values between 41 to 99 in both Manned and Unmanned stores-Ends
					$rootScope.invoiceMethod = storeInfo.invoiceMethod;
					$scope.agreementAmount = storeInfo.minAgreementAmount;
					stateRestrictFactory.getAllStoreRestrictions(storeInfo.state)
					.then(function(response) {
						orderSummary.restrictions = response;
						console.log('[OrderSummary.init] store restrictions', orderSummary.restrictions);
					});
			});

			// check routeParam for incoming order number
			if (typeof orderSummary.routeId !== 'undefined') {
				SharedDataSvc.globalVars.order.currentEngagementId = orderSummary.routeId;

				// load EXISTING record from routeParam
				customerDataFactory.findByEngagementId(orderSummary.routeId, !existingData)
				.then(
					handleEngagementData,
					function (response) {
						if (response.status === 303) {
							handleEngagementData(response.data.errorData);
						}
					}
				);
			} else {
				startNewOrder();
			}
			getStoreInfoFactory.getData()
				.then(
					// Success
					function(response) {
						if (response.allowExtendedWarranty === false) {
							orderSummary.allowExtendedWarranty = false;
						} else {
							orderSummary.allowExtendedWarranty = true;
						}
							// also check against state restrictions
					},
					// Error
					function(error) {

					}
				);

			orderEstimatePutFactory.getProcessingFee()
				.then(function(processingFee) {
					orderSummary.processingFee = processingFee;
				});

			//get product category list
			productFactory.getProductCategories().then(function(response) {
				orderSummary.prodCat = response.productCategories;
				orderSummary.isProdCatValLoaded = true;
			});
		}
		init();

		$scope.$on('orderUpdated', function() {
			orderSummary.hasItems = (SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0) ? true : false;
			if (angular.isDefined(orderSummary.globalVars.order.currentEngagementOrder.orderContent)) {
				orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems;
				orderSummary.globalVars.order.currentEngagement = SharedDataSvc.globalVars.order.currentEngagement;
			} else {
				orderSummary.globalVars.order.currentEngagement = SharedDataSvc.globalVars.order.currentEngagement;
				orderSummary.globalVars.order.currentEngagementOrder = SharedDataSvc.globalVars.order.currentEngagementOrder;
			}
			orderSummary.calcTotals();
		});

		$rootScope.$on('ABC', function(){
			checkMinMax();
		});

		function showDependencyNote(index){
			console.log("ThisSet", orderSummary.dependentItem);
			if(index == orderSummary.dependentItem[0]){
				return true;
			}else{
				return false;
			}
		}

		function handleEngagementData(data) {
			console.log('[OrderSummary.init] customerDataFactory.findByEngagementId data:', data);
			orderSummary.checkingOrder = false;

			if (typeof data !== "object") {
				data = JSON.parse(data);
			}

			orderSummary.approvalAmount = data.approvalAmount || orderSummary.approvalAmount;
			SharedDataSvc.globalVars.order.approvalAmount = orderSummary.approvalAmount; //Story 28508 Changes
			// switch to VanEngagement for further data if it exists
			if (data.VanEngagement) {
				data = data.VanEngagement;
			}
			if (orderSummary.approvalAmount > 0 && data.applicationStatus === 'Accepted') {
				orderSummary.hasApproval = true;
			}
			if (existingData) {
				data = _.merge(SharedDataSvc.globalVars.order.currentEngagement, data);
				console.log(data);
				updateEngagementData();
			}

			orderSummary.globalVars.order.currentEngagement = data;
			SharedDataSvc.globalVars.order.currentEngagement = data;

			if (!existingData) {
				orderSummary.globalVars.order.currentEngagement.customerFirstName = data.customer.fName;
				orderSummary.globalVars.order.currentEngagement.customerLastName = data.customer.lName;
				orderSummary.globalVars.order.currentEngagement.customerPhoneNum = data.customer.phNum;
				orderSummary.globalVars.order.currentEngagement.approvalAmount = SharedDataSvc.manualOverrideData.approvalAmount;//data.approvalAmount;
				orderSummary.showName = true;
				$scope.name = data.customer.fName + ' ' + data.customer.lName;
				updateEngagementData();
			}
			if (!data.orderContent) {
				orderSummary.hasItems = false;
				startNewOrder(data);
			} else {
				processOrderContent(data.orderContent);
			}
		}

		function processOrderContent(orderContent) {
			// parse the order-items into decodable content
			var decodedOrderString;
			try {
				decodedOrderString = JSON.parse(window.atob(orderContent));
			} catch (e) {
				decodedOrderString = JSON.parse(orderContent);
			}
			console.log(decodedOrderString);
			// re-write order-items portion with decoded var
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent = decodedOrderString;
			orderSummary.globalVars.order.currentEngagementOrder.orderContent = decodedOrderString;
			if (decodedOrderString.orderItems.length === 0) {
				orderSummary.hasItems = false;
			} else {
				orderSummary.hasItems = true;
			}

			// calc totals
			orderSummary.calcTotals();
			if ($routeParams.confirm) {
				// confirmOrder();
			}
		}

		function openOrderBuilder() {
			var addItemModal = modalService.open(builderModal());
			addItemModal.result.then(function(result) {
				console.log("Getting from addItemModal", result);
				var index = orderService.addToOrder(result.item);
				orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];
				orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];
				//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Starts
				if ($rootScope.setModeForGroup == false)
					{
					result.isSetMode = false;
					}
				//Feature 9500 - VAN - Lowes Large Appliances and 'other items' on different agreements - Ends
				if (result.isSetMode) {
					openSetMode(index);
				}
				//SharedDataSvc.editOrder = true;
			});
		}

		// Start order builder in set mode
		function openSetMode(itemIndex) {
			// retrieve builder modal with set mode on
			var addSetItemModal = modalService.open(builderModal({isSetMode:true, itemIndex: itemIndex}));
			orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			addSetItemModal.result.then(function(result){
				orderService.addToSet(result.item, result.itemIndex);

				if (result.isSetMode) {
					openSetMode(result.itemIndex);
				}
				orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];
				orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			});
		}

		function showModal(name, windowClass, title) {
		  windowClass = windowClass || '';
		  $rootScope.modalTitle = title || '';
		  orderSummary.modal = modalService.open({
			templateUrl: '../../../partials/modal_' + name + '.html',
			backdrop: 'static',
			windowClass: windowClass,
			scope: $scope
		  });
		}

		function confirmId() {
			console.log('confirm ID');
			SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 1;
			if(orderSummary.storeInfo.invoiceAtOrderconfirmation == 1){
				// set ID confirm to 0
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
					backdrop: 'static'
				});
			}
			else {
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
					backdrop: 'static'
				});
			}
		}

		function submitEmployeePass(pass) {

			if(pass) {
				$scope.noEmpPass = false;
				// showModal('Processing', 'processing-modal');
				var auth = authentication.storePass;

				if(auth === pass) {
					console.log('associate password passes');
					saveOrder('Confirmed', '/dashboard');
				} else {
					// showModal('associatePassFail');
					orderSummary.associatePassFail = true;
					showModal('employeePass');
					console.log('associate password failure');
				}
			} else {
				$scope.noEmpPass = true;
				console.log('no associate password', orderSummary.noEmpPass);
			}
		}
//		AC 26202 code changes starts
		function closeSetItem(itemIndex, setMode) {
			console.log("closed from summary");
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0 && SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex].setItems &&//AC 26528 code change 
					SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems[itemIndex].setItems.length > 0 ){
				return;
			} else {
				if(setMode){
					console.log("setMode is true");
					SharedDataSvc.itemDeleteTempIdx = itemIndex;
					orderSummary.removeItemConfirmConfirm();
				}
				
			}
		}
		//		AC 26202 code changes ends

		function submitEmployeePassWithOutSaveOrder(pass) {
			if(pass) {
				$scope.noEmpPass = false;
				// showModal('Processing', 'processing-modal');
				var auth = authentication.storePass;
				if(auth === pass) {
					console.log('associate password passes');
					//modalService.dismiss();
					var modal_invoiceAndSales = modalService.open({
						title: 'Confirm Order',
						content: 'this is the content',
						show: true,
						scope: $scope,
						templateUrl: 'partials/modal_invoiceAndDeliveryDetails.html',
						backdrop: 'static'
					});
				} else {
					// showModal('associatePassFail');
					orderSummary.associatePassFail = true;
					showModal('employeePassWithoutSave');
					console.log('associate password failure');
				}
			} else {
				$scope.noEmpPass = true;
				console.log('no associate password', orderSummary.noEmpPass);
			}
		}

		function submitInvoiceAndSalesDetails(){
			console.log('Final Data stored');
			orderSummary.errors.invoiceNumber = false;
			orderSummary.errors.associate = false;
			if(orderSummary.invoiceNumber == ''){
				orderSummary.errors.invoiceNumber = true;
			}
			if(orderSummary.associate == ''){
				orderSummary.errors.associate = true;
			}
			if(!orderSummary.errors.invoiceNumber && !orderSummary.errors.associate){
				orderSummary.globalVars.order.currentEngagement.invoiceNum = orderSummary.invoiceNumber;
				orderSummary.globalVars.order.currentEngagement.salesAssociateID = orderSummary.associate;
				saveOrder('Confirmed', '/dashboard');
			}
		}

		function editModalRun(input) {
			SharedDataSvc.hasEditItemModalRun = input;
		};

		function editOrderItem(itemIndex, childIndex) {
			childIndex = isNaN(childIndex) ? null : childIndex;
			// set to array of index values if child index is set
			itemIndex = (childIndex !== null) ? [itemIndex, childIndex] : itemIndex;

			var editItemModal = modalService.open(builderModal({
				isEditMode:true,
				itemIndex: itemIndex
			}));
			editItemModal.result
				.then(
					// Success
					function(result) {
						orderService.addEditedItem(result.item, itemIndex);
						calcTotals();
						//AC13472 code changes start
						orderService.setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
						orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];

						orderService.evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
						orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];
						//AC13472 code changes end
					}
				);
		};


		// Set up the modal options for order builder/set mode
		function builderModal(modalData) {
			/*VAN 273,275 - VAN - PR Change Hecho to continue on order screen - Starts*/
			$scope.showDoneToContinue = false;
			if($rootScope.state=="PR"){
				$scope.showDoneToContinue = true;
			}
			/*VAN 273,275 - VAN - PR Change Hecho to continue on order screen - Ends*/
			
			var modalTemplateUrl = 'partials/modal_addOrderItem.html';
			var options = {
				isSetMode: false,
				isEditMode: false,
				itemIndex: 0
			};
			modalData = _.merge(options, modalData);

			if (modalData.isEditMode) modalTemplateUrl = 'partials/modal_editOrderItem.html';

			return {
				title: 'Add Item',
				size: 'sm',
				controller: 'OrderBuilder',
				controllerAs: 'builder',
				templateUrl: modalTemplateUrl,
				backdrop: 'static',
				scope: $scope, //AC 26202 code changes
				resolve: {
					// Data to be sent to the OrderBuilder controller
					modalData: function() {
						return modalData;
					}
				}
			};
		}

		function updateEngagementData() {
			SharedDataSvc.globalVars.order.currentEngagement = orderSummary.globalVars.order.currentEngagement;
		}

		function calcTotals() {
			var eachPrice = [0];
			if (angular.isDefined(orderSummary.globalVars.order.currentEngagementOrder.orderContent)) {
				if (orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems.length > 0) {
					eachPrice = _.pluck(orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems, 'itemPrice');
				}

				var orderSubTotal = 0;
				for (var i = 0, len = eachPrice.length; i < len; i++) {
					if (eachPrice < 0.01) {
						orderSubTotal = 0;
						SharedDataSvc.globalVars.order.currentOrderSubTotal = orderSummary.orderSubtotal;//Story 28508 Changes
						return
					} else {
						orderSubTotal += Number(eachPrice[i]);
					}
				};

				orderSummary.orderSubtotal = parseFloat(orderSubTotal, 2);
			} else {
				orderSummary.orderSubtotal = 0;
			}
			SharedDataSvc.globalVars.order.currentOrderSubTotal = orderSummary.orderSubtotal;//Story 28508 Changes
		};

		function startNewOrder(engagementData) {
			orderSummary.globalVars.order = {
				customerId: '',
				deliveryFee: 0,
				languagePreference: 'ENGLISH',
				calculatedTax:0,
				orderSubtotal:0,
				initialPayment:0,
				monthlyPayment:0,
				deliveryDate: '',
				currentEngagementId: engagementData.engagementId,
				currentEngagement: engagementData,
				currentEngagementOrder: {
					customerId: 0,
					dateStamp: '',
					modifiedDateStamp: '',
					engagementId: null,
					agreementHtId: null,
					vanStoreId: '',
					orderStatus: '',
					deliveryStatus: '',
					paymentStatus: '',
					salesStatus: '',
					agreementStatus: '',
					reconcilationStatus: null,
					orderContent: {
						extendedServiceAmount: 0.00,
						confirmed: 0,
						otherFee: 0,
						installationFee: 0,
						deliveryFee: 0,
						orderIdentifier: '',
						orderItems: []
					},
					applicationStatus: '',
					customerHtID: '', // HT2110000276
					customerHtStoreId: null,
					estimatedDeliveryDate: null,
					orderId: null,
					orderOriginator: 'AWP'
				}
			};
			SharedDataSvc.globalVars.order.currentEngagementOrder = orderSummary.globalVars.order.currentEngagementOrder;
		};

		function navigateTo(target) {
			$location.path(target);
		};

		function removeItemConfirm(index, childIndex) {
			childIndex = isNaN(childIndex) ? null : childIndex;
			var deletionIndexes = [index];
			if (childIndex !== null) {
				deletionIndexes = [index, childIndex];
			}
			SharedDataSvc.itemDeleteTempIdx = deletionIndexes;

			var confirmItemDeleteModal = modalService.open({
				title: 'Confirm Item Delete',
				scope: $scope,
				templateUrl: 'partials/confirm_confirmItemDeleteModal.tpl.html'
			});

			/*confirmItemDeleteModal.result.then(function(){
				SharedDataSvc.editOrder = true;
			});*/
		};

		function removeItemConfirmConfirm() {
			var removalIndex = SharedDataSvc.itemDeleteTempIdx;
			var orderItems = SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems;
			var setItems;

			if (removalIndex.length > 1) {
				setItems = orderItems[removalIndex[0]].setItems;
				setItems = _.without(setItems, setItems[removalIndex[1]]);
//				AC 26202 code changes starts
//				orderItems[removalIndex[0]].setItems = setItems.length > 0 ? setItems : 1;
				if(setItems.length > 0){
					orderItems[removalIndex[0]].setItems = setItems;
				} else {
					orderItems = _.without(orderItems, orderItems[removalIndex[0]]);
				}
				//		AC 26202 code changes ends
			} else {
				orderItems = _.without(orderItems, orderItems[removalIndex]);
			}
			SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems = orderItems;

			if (orderSummary.globalVars.order.currentEngagementOrder.orderContent.orderItems.length < 1) {
				orderSummary.hasItems = false;
				orderSummary.orderSubtotal = 0.00;
			} else {
				orderSummary.calcTotals();
			}
			orderService.setDisplayDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			orderSummary.disableOrderButtons = SharedDataSvc.globalVars.disableOrderButtons[0];

			orderService.evalSetItemDependencyFlag(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.orderItems);
			orderSummary.disableOrderButtonsOnSetAbsent = SharedDataSvc.globalVars.disableOrderButtonsOnSetAbsent[0];

			$scope.$broadcast('orderUpdated');
		};

		function confirmOrder() {
			//if (orderSummary.orderSubtotal > 3000 || orderSummary.orderSubtotal > SharedDataSvc.globalVars.order.currentEngagement.approvalAmount) {
			if (!checkMinMax()) return;

			if (orderSummary.hasItems === false) {
				// alert 'you need to complete order'
				var confirmOrderModal = modalService.open({
					title: 'Confirm Order',
					content: 'this is the content',
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_confirmOrderModal.tpl.html',
					backdrop: 'static'
				});
			} else {
				
				// confirm 'are you sure you want to confirm?'
				if(!orderSummary.storeInfo.isManned || $cookieStore.get("isHybrid")) {
					var confirmOrderModal = modalService.open({
						title: 'Confirm ID',
						content: 'this is the content',
						show: true,
						scope: $scope,
						// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
						templateUrl: 'partials/modal_checkCustomerId.html',
						backdrop: 'static'
					});
				} else {
					if(orderSummary.storeInfo.invoiceAtOrderconfirmation == 1){
						// set ID confirm to 0
						SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/confirm_confirmModalWithOutSaveOrder.tpl.html',
							backdrop: 'static'
						});
					}
					else {
						// set ID confirm to 0
						SharedDataSvc.globalVars.order.currentEngagement.isIdConfirmed = 0;
						var confirmOrderModal = modalService.open({
							title: 'Confirm Order',
							content: 'this is the content',
							show: true,
							scope: $scope,
							// templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							templateUrl: 'partials/confirm_confirmOrderConfirmModal.tpl.html',
							backdrop: 'static'
						});
					}
				}
			}
		};

		function done() {
			//if (!checkMinMax()) return;
			modalService.processing('Saving Order');
			orderService.save(SharedDataSvc.globalVars.order, 'InProgress', '/dashboard');
		}

		function getOrderEstimate() {
			orderSummary.disableReviewEstimate = true;
			console.log('ORDER ESTIMATE');			
			SharedDataSvc.globalVars.estimateFlag = true;//AC21459 code changes

			if(orderSummary.approvalAmount && orderSummary.storeInfo.approvalAmtCheck){
				var orderAmountPayload = angular.copy(orderSummary.globalVars.order.currentEngagementOrder.orderContent);
				orderAmountPayload.customerId = orderSummary.globalVars.order.currentEngagement.customer.customerId;
				orderAmountPayload.inventoryList = [];
				_.forEach(orderAmountPayload.orderItems, function(item, index) {
					var orderItem = {
						"racBrand" : "ACP", // 'ESV' for extended
						"itemModelNumber" : "", // 'ExtSvc' for extended
						"racModelNumber" : productFactory.getRacModelNumber(orderSummary.prodCat, item, $rootScope.selectedLanguage),//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
						"depreciableLifeInMonths" : 24,
						"itemOptions" : productFactory.buildItemOptions(item),
						"itemOriginalCost" : SharedDataSvc.formatItemPrice(item.itemPrice)
					};
					orderAmountPayload.inventoryList.push(orderItem);
				});
				delete orderAmountPayload.orderItems;

				orderEstimatePutFactory.checkApprovalAmt(orderAmountPayload).then(function(result){			
					if(SharedDataSvc.invoiceReductionPayment) {
						if (!checkMinMax()) return;
						orderSummary.proceedOrderSave(result);
						return;
					}
					if(result.responseCode == "AAC001"){
						if($rootScope.selectedLanguage == 'es'){
							$scope.checkApprovalAmtError = 'El monto total del pedido y de los servicios extendidos no puede exceder de $' 
														+ orderSummary.approvalAmount + '.'; //result.message;
						} else {
							$scope.checkApprovalAmtError = 'The total order and extended services amount can not exceed $' 
														+ orderSummary.approvalAmount + '.'; //result.message;
						}						
						var checkApprovalAmtErrorModal = modalService.open({
							scope: $scope,
							templateUrl: 'partials/alert_totalOrderAmountError.html',
							backdrop: 'static'
						});
						checkApprovalAmtErrorModal.result.then(function(){
							orderSummary.disableReviewEstimate = false;
						});
					} else if(result.responseCode == "AAC002"){
						if (!checkMinMax()) return;
						orderSummary.proceedOrderSave(result);
					}
				}, function(error){
					SharedDataSvc.reportRaygun(error);				
					orderSummary.disableReviewEstimate = false;
				});
				return false;
			} else {
				if (!checkMinMax()) return;
				orderSummary.proceedOrderSave(result);
			}			
		}

		function proceedOrderSave(result) {
			/*VAN-4036 -AWP - order screen invoice reduction payment changes 
			Payload is added with the extra value of inv red pay amt*/
			
			if(SharedDataSvc.invoiceReductionPayment) {
				if (result.responseCode == "AAC001") {
					console.log("message call", result.message.split('-')[1]);
					if (result.message.split('-')[1].trim() == "INV,ESV,DEL") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV, ESV") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount)
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV, DEL") {
						if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee)
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
					else if (result.message.split('-')[1].trim() == "INV") {
						if(Number(orderSummary.orderSubtotal) > 1.4*(Number(orderSummary.approvalAmount))){
							var wrongvendor = modalService.open({
								show: true,
								templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
								backdrop: 'static'         
							});
							orderSummary.disableReviewEstimate = false;
							return;
						}
						orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =
							Number(orderSummary.orderSubtotal) +
							- Number(orderSummary.approvalAmount);
						SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
					}
				}
				else if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.approvalAmount)) {
					if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > 1.4*(Number(orderSummary.approvalAmount))){
						var wrongvendor = modalService.open({
							show: true,
							templateUrl: 'partials/alert_orderAmountExceeds.tpl.html',
							backdrop: 'static'         
						});
						orderSummary.disableReviewEstimate = false;
						return;
					}
					orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					console.log("irp",orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount);
					SharedDataSvc.invoiceReductionPaymentAmount = Number(orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount.toFixed(2));
				}
				else {
					orderSummary.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = 0;
				}
			}
			console.log("inv red pay amt",SharedDataSvc.invoiceReductionPayment,SharedDataSvc.invoiceReductionPaymentAmount);
			orderService.save(orderSummary.globalVars.order, 'Pending');
			
			SharedDataSvc.globalVars.orderSummary = orderSummary.globalVars.order;
			var delFeePaymentOptionFlagValue = orderSummary.storeInfo.deliveryFeePaymentOption;
			var esvdelFeePaymentOptionFlagValue = orderSummary.storeInfo.esvTermOption;
			
			if(orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee > 0 && (orderSummary.storeInfo.esvTermOption && orderSummary.storeInfo.deliveryFeePaymentOption)) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/modal_deliveryFeeOptions.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action) {
					showModal('Processing', 'processing-modal', 'Processing');
					SharedDataSvc.globalVars.estimateDeliveryOption = action;

					var orderData = orderEstimatePutFactory.compileData(orderSummary.globalVars.order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, orderSummary.prodCat, action, $rootScope.selectedLanguage);//VAN-737 - PRB0042979 - AWP - Adding RAnges/Electric giving error in AWP on order screen - Added the selectedlanguage
					SharedDataSvc.globalVars.orderEstimatePut = orderData;

					// to calculate quotes on review estimate
					var currentDate = datesFactory.getCurrentDate();
					var quoteDates = datesFactory.setQuoteDates(currentDate);

					orderSummary.orderDataArray = [_.clone(orderData),
						_.clone(orderData),
						_.clone(orderData)];

					_.forEach(quoteDates, function(paymentDate, i) {
						orderSummary.orderDataArray[i].requestDte = currentDate;
						orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
					});

					SharedDataSvc.globalVars.disabledEstimateOrderConfirmButton = orderSummary.checkingOrder ||
							!orderSummary.hasItems || !orderSummary.hasApproval || (orderSummary.disableOrderButtons) ||
							(orderSummary.disableOrderButtonsOnSetAbsent == 'true');

					SharedDataSvc.globalVars.orderSummary = orderSummary;

					SharedDataSvc.globalVars.orderSummary.order = _.clone(SharedDataSvc.globalVars.order);

					SharedDataSvc.globalVars.orderSummary.order.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
					SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

					SharedDataSvc.globalVars.orderSummary.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
					SharedDataSvc.globalVars.orderSummary.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

					_.forEach(quoteDates, function(paymentDate, i) {
						orderSummary.orderDataArray[i].requestDte = currentDate;
						orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
					});

					agreementFactory.setQuotes(orderSummary.orderDataArray)
							.then(function () {
								orderSummary.disableReviewEstimate = false;
								orderSummary.quotes = agreementFactory.quotes;
								_.forEach(quoteDates, function (paymentDate, i) {
									orderSummary.quotes[i].paymentDueDate = moment(paymentDate.date);
								});
								var target = '/estimate/' + $routeParams.engagementId;
								SharedDataSvc.globalVars.estimateReturn = orderSummary.quotes[0];
								SharedDataSvc.globalVars.estimateReturnAll = orderSummary.quotes;

								orderSummary.navigateTo(target);
							}, function (response) {
								// sending error to Raygun
								SharedDataSvc.reportRaygun(response);
								orderSummary.navigateTo('/dashboard');
							});

				});
			}
			else {
				showModal('Processing', 'processing-modal', 'Processing');
				var orderData = orderEstimatePutFactory.compileData(orderSummary.globalVars.order, esvdelFeePaymentOptionFlagValue, delFeePaymentOptionFlagValue, orderSummary.prodCat, undefined, $rootScope.selectedLanguage);//VAN-693 - PRB0042959 - PR stores Spanish order issues - Added the selectedlanguage
				SharedDataSvc.globalVars.orderEstimatePut = orderData;

				// to calculate quotes on review estimate
				var currentDate = datesFactory.getCurrentDate();
				var quoteDates = datesFactory.setQuoteDates(currentDate);

				orderSummary.orderDataArray = [_.clone(orderData),
					_.clone(orderData),
					_.clone(orderData)];

				_.forEach(quoteDates, function(paymentDate, i) {
					orderSummary.orderDataArray[i].requestDte = currentDate;
					orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
				});

				SharedDataSvc.globalVars.disabledEstimateOrderConfirmButton = orderSummary.checkingOrder ||
						!orderSummary.hasItems || !orderSummary.hasApproval || (orderSummary.disableOrderButtons) ||
						(orderSummary.disableOrderButtonsOnSetAbsent == 'true');

				SharedDataSvc.globalVars.orderSummary = orderSummary;

				SharedDataSvc.globalVars.orderSummary.order = _.clone(SharedDataSvc.globalVars.order);

				SharedDataSvc.globalVars.orderSummary.order.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
				SharedDataSvc.globalVars.orderSummary.order.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

				SharedDataSvc.globalVars.orderSummary.currentEngagement = _.clone(SharedDataSvc.globalVars.order.currentEngagement);
				SharedDataSvc.globalVars.orderSummary.currentEngagementOrder = _.clone(SharedDataSvc.globalVars.order.currentEngagementOrder);

				_.forEach(quoteDates, function(paymentDate, i) {
					orderSummary.orderDataArray[i].requestDte = currentDate;
					orderSummary.orderDataArray[i].nextRenewDte = paymentDate.date;
				});
				/*VAN 774 - Biweekly renewal payments Long Term - Starts*/	
				if(!orderSummary.storeInfo.lowInitialPaymentEnabled){
					agreementFactory.setQuotes(orderSummary.orderDataArray)
					.then(function () {
						orderSummary.disableReviewEstimate = false;
						orderSummary.quotes = agreementFactory.quotes;
						_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotes[i].paymentDueDate = moment(paymentDate.date);
						});
						var target = '/estimate/' + $routeParams.engagementId;
						SharedDataSvc.globalVars.estimateReturn = orderSummary.quotes[0];
						SharedDataSvc.globalVars.estimateReturnAll = orderSummary.quotes;

						orderSummary.navigateTo(target);
					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						orderSummary.navigateTo('/dashboard');
					});
				/*VAN-858 - Quad Weekly Payment Frequency - Starts*/	
				}else if(orderSummary.storeInfo.lowInitialPaymentEnabled && orderSummary.storeInfo.paymentModeEnabled!=undefined){
				/*VAN-858 - Quad Weekly Payment Frequency - Ends*/

					/*VAN-4074 - AWP_Include the HT object in payload of Quotev2 */

					if (SharedDataSvc.invoiceReductionPayment && SharedDataSvc.invoiceReductionPaymentAmount > 0) {
						var orderItem = {
							"racBrand": "IVR",
							"itemModelNumber": "",
							"racModelNumber": "HTIVR-DEPAMT",
							"depreciableLifeInMonths": 24,
							"itemOptions": "1",
							"itemOriginalCost": (SharedDataSvc.invoiceReductionPaymentAmount * 100)
						}
						orderItem.itemModelNumber = "";
						orderItem.racBrand = "IVR";
						orderItem.racModelNumber = "HTIVR-DEPAMT"
						orderItem.itemOptions = "1";
						orderItem.itemOriginalCost = (SharedDataSvc.invoiceReductionPaymentAmount * 100);
						orderItem.depreciableLifeInMonths = 24;
						console.log('[OrderSummary] order summary  invoice  ITEM', orderItem);
						orderSummary.orderDataArray[0].inventoryList.push(orderItem);
					}
					orderSummary.orderDataArray[0].paymentMode = 'Monthly';
					orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
					.then(function (monthlyResponse) {
						console.log("Monthly Success");
						orderSummary.disableReviewEstimate = false;
						orderSummary.quotesMonthly = monthlyResponse;
						/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
						/*_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
						});*/
						orderSummary.quotesMonthly.paymentDueDate  = quoteDates[0].date;
						orderSummary.orderDataArray[0].paymentMode = 'Biweekly';
						orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
						.then(function (biweeklyResponse) {
							console.log("Biweekly Success");
							orderSummary.disableReviewEstimate = false;
							orderSummary.quotesBiweekly= biweeklyResponse;
							/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
							/*_.forEach(quoteDates, function (paymentDate, i) {
								orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
							});*/
							orderSummary.quotesBiweekly.paymentDueDate  = quoteDates[0].date;
							/*VAN-858 - Quad Weekly Payment Frequency - Starts*/
							orderSummary.orderDataArray[0].paymentMode = 'FOURWEEK';
							orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
							.then(function (quarterlyResponse) {
								console.log("Quarterly Success");
								orderSummary.disableReviewEstimate = false;
								orderSummary.quotesQuarterly= quarterlyResponse;
								
								orderSummary.quotesQuarterly.paymentDueDate  = quoteDates[0].date;
								var target = '/estimate/' + $routeParams.engagementId;
								SharedDataSvc.globalVars.estimateReturnMonthly = orderSummary.quotesMonthly;
								SharedDataSvc.globalVars.estimateReturnBiweekly = orderSummary.quotesBiweekly;
								SharedDataSvc.globalVars.estimateReturnQuarterly = orderSummary.quotesQuarterly;
								orderSummary.navigateTo(target);
							},function (quarterlyResponse) {
								SharedDataSvc.reportRaygun(quarterlyResponse);
								orderSummary.navigateTo('/dashboard');
							});
							/*VAN-858 - Quad Weekly Payment Frequency - Ends*/
						}, function (biweeklyResponse) {
							SharedDataSvc.reportRaygun(biweeklyResponse);
							orderSummary.navigateTo('/dashboard');
						});
					}, function (monthlyResponse) {
						SharedDataSvc.reportRaygun(monthlyResponse);
						orderSummary.navigateTo('/dashboard');
					});
				}else{
					/*VAN-4074 - AWP_Include the HT object in payload of Quotev2 */

					if (SharedDataSvc.invoiceReductionPayment && SharedDataSvc.invoiceReductionPaymentAmount > 0) {
						var orderItem = {
							"racBrand": "IVR",
							"itemModelNumber": "",
							"racModelNumber": "HTIVR-DEPAMT",
							"depreciableLifeInMonths": 24,
							"itemOptions": "1",
							"itemOriginalCost": (SharedDataSvc.invoiceReductionPaymentAmount * 100)
						}
						orderItem.itemModelNumber = "";
						orderItem.racBrand = "IVR";
						orderItem.racModelNumber = "HTIVR-DEPAMT"
						orderItem.itemOptions = "1";
						orderItem.itemOriginalCost = (SharedDataSvc.invoiceReductionPaymentAmount * 100);
						orderItem.depreciableLifeInMonths = 24;
						console.log('[OrderSummary] order summary  invoice  ITEM', orderItem);
						orderSummary.orderDataArray[0].inventoryList.push(orderItem);
					}
					orderSummary.orderDataArray[0].paymentMode = '';
					orderEstimatePutFactory.putData(orderSummary.orderDataArray[0],false)
					.then(function (response) {
						orderSummary.disableReviewEstimate = false;
						SharedDataSvc.globalVars.estimateReturn = response;
						/*orderSummary.quotesMonthly.paymentDueDate = moment(paymentDate.date);*/
						/*_.forEach(quoteDates, function (paymentDate, i) {
							orderSummary.quotesMonthly[i].paymentDueDate = moment(paymentDate.date);
						});*/
						/*orderSummary.quotesBiweekly.paymentDueDate  = quoteDates[0].date;*/
						var target = '/estimate/' + $routeParams.engagementId;
						orderSummary.navigateTo(target);
					}, function (response) {
						// sending error to Raygun
						SharedDataSvc.reportRaygun(response);
						orderSummary.navigateTo('/dashboard');
					});
				}
				/*VAN 774 -  Biweekly renewal payments Long Term - Ends*/	
			}
		}
		
		function checkMinMax() {
			console.log('[orderSummary.checkMinMax orderSubtotal]', orderSummary.orderSubtotal);
			console.log('[orderSummary.checkMinMax approvalAmount]', orderSummary.approvalAmount);
			
			//AC-21473 changes
			/*if (SharedDataSvc.globalVars.order.currentEngagement.applicationStatus != 'Accepted') {
				$scope.approvalAmount = orderSummary.approvalAmount;
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_manualOrderConfirmError.tpl.html',
					backdrop: 'static'
				});
				confirmOrderModal.result.then(function(action){
					if(action){
						saveOrder('InProgress', '/dashboard');
					}
				});
				return false;
			}*/

			if (!orderSummary.storeInfo.approvalAmtCheck && (orderSummary.orderSubtotal > orderSummary.approvalAmount)) {				
					if(orderSummary.approvalAmount > 0){
						$scope.approvalAmount = orderSummary.approvalAmount;
					}else{
						$scope.approvalAmount = 0;
					}
					/*VAN-4036 -AWP - order screen invoice reduction payment changes 
					Hide the max order alert when the invoice reduction payment flag is on*/
					
					if( !SharedDataSvc.invoiceReductionPayment) {
						var confirmOrderModal = modalService.open({
							show: true,
							scope: $scope,
							controllerAs: 'orderSummary',
							templateUrl: 'partials/alert_maxOrderModal.tpl.html',
							backdrop: 'static'
						});
					}
					orderSummary.disableReviewEstimate = false;
					return false;
			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - Start
//			if (orderSummary.orderSubtotal > 3000) {
//				$scope.approvalAmount = 3000;
//				var confirmOrderModal = modalService.open({
//					show: true,
//					scope: $scope,
//					controllerAs: 'orderSummary',
//					templateUrl: 'partials/alert_maxOrderModal.tpl.html',
//					backdrop: 'static'
//				});
//				orderSummary.disableReviewEstimate = false;
//				return false;
//			}
			//AC-27936 - Commenting the orderLimit check as the maximumPriceLimit for an item can be more than 3000. - End

			if (!orderSummary.orderSubtotal || orderSummary.orderSubtotal < $scope.agreementAmount) {
				var confirmOrderModal = modalService.open({
					show: true,
					scope: $scope,
					controllerAs: 'orderSummary',
					templateUrl: 'partials/alert_minOrderModal.tpl.html',
					backdrop: 'static'
				});
				orderSummary.disableReviewEstimate = false;
				return false;
			}

			return true;
		}

		// TODO - remove this later, use saveOrder in service
		function saveOrder(orderStatus, navigateTo) {
			// check for unneeded param
			$location.search('confirm', null);
			// Start VAN 3066
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee == ''){
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee = 0;
			}
			if(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount == ''){
				SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount = 0;
			}
			/*VAN-4036 -AWP - order screen invoice reduction payment changes 
			passing the amount in request payload to save order service*/			
			if(SharedDataSvc.invoiceReductionPayment) {
				if(Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) > Number(orderSummary.approvalAmount)) {
					orderSummary.invoiceAmountCalculate =  Number(orderSummary.orderSubtotal) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.deliveryFee) + Number(SharedDataSvc.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount) - Number(orderSummary.approvalAmount);
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount = orderSummary.invoiceAmountCalculate.toFixed(2);
				}
				else {
					console.log("less than field");
					SharedDataSvc.globalVars.order.currentEngagement.invoiceReductionPaymentAmount  = 0;
				}
			}
			// End
			orderService.save(SharedDataSvc.globalVars.order, orderStatus, navigateTo);
		};
		
		//AC 25341 Code changes starts		
		function feeCheck(value, name) {
			if (value && String(value).indexOf('.') > -1) {
				var values = String(value).split('.');
				if (values[1].length > 2) { 
					if(name == 'extendedServiceAmount') {
						orderSummary.globalVars.order.currentEngagementOrder.orderContent.extendedServiceAmount = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					} else if (name == 'deliveryFee'){
						orderSummary.globalVars.order.currentEngagementOrder.orderContent.deliveryFee = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));
					}			
				}
			}
		}
		//AC 25341 Code changes ends

		$scope.$on('$locationChangeStart', function() {
				//SharedDataSvc.globalVars.order.currentEngagement = {};
				//SharedDataSvc.globalVars.order.currentEngagementOrder = {};
				console.log(SharedDataSvc.globalVars.order.currentEngagement);
		});
	}

})();