// The EPay controller follows the angular-styleguide
// https://github.com/johnpapa/angularjs-styleguide
(function () {
  'use strict';

  angular
    .module('myApp')
    .controller('EPay', ePay);

  ePay.$inject = ['$scope',
                  '$log',
                  '$rootScope',
                  '$routeParams',
                  '$q',
                  '$translate',
                  '$location',
                  '$cookieStore',
                  'modalService',
                  'getSpecificApplication',
                  'paymentFactory',
                  'SharedDataSvc',
                  'base64',
                  'getStoreInfoFactory',
                  'applicationService',
                  'updateEngagementItemFactory',
                  'customerDataFactory',
                  'agreementFactory',
                  'datesFactory'
                  ];

  function ePay($scope,
                $log,
                $rootScope,
                $routeParams,
                $q,
                $translate,
                $location,
                $cookieStore,
                modalService,
                getSpecificApplication,
                paymentFactory,
                SharedDataSvc,
                base64,
                getStoreInfoFactory,
                applicationService,
                updateEngagementItemFactory,
                customerDataFactory,
                agreementFactory,
                datesFactory
               ) {
    /*jshint validthis: true */
    var vm = this;

    // set scope variable for processing modal
    $scope.viewLoading = false;
    $scope.modalTitle = 'Processing';

    $scope.initialPaymentOCHSubmitted = false;
    // Variables
    vm.benefits;
    vm.account = {
        username:"",
        password:"",
        name: null,
        passwordVerify: "",
        loggedIntoEpay: /*false,*/true,//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		newOrExistingAcct: /*true,*/false,//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        paymentCredit: 'credit',
        /* Commented these changes as part of VAN-670 - Consent Verbiage*/
       /*VAN-616 - VAN - Low Down Initial Payment - Starts
        paymentInitialValSelected: 'AutoPayRequired',
        VAN-616 - VAN - Low Down Initial Payment - Ends*/
        paymentBankAccount: false,
        securityQuestion:[
            {
              name: {
                en : en.epay_label_securityQstnOpt1,
                es : es.epay_label_securityQstnOpt1
              },
              value: en.epay_label_securityQstnOpt1
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt2,
                es : es.epay_label_securityQstnOpt2
              },
              value: en.epay_label_securityQstnOpt2
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt3,
                es : es.epay_label_securityQstnOpt3
              },
              value: en.epay_label_securityQstnOpt3
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt4,
                es : es.epay_label_securityQstnOpt4
              },
              value: en.epay_label_securityQstnOpt4
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt5,
                es : es.epay_label_securityQstnOpt5
              },
              value: en.epay_label_securityQstnOpt5
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt6,
                es : es.epay_label_securityQstnOpt6
              },
              value: en.epay_label_securityQstnOpt6
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt7,
                es : es.epay_label_securityQstnOpt7
              },
              value: en.epay_label_securityQstnOpt7
            },
            {
              name: {
                en : en.epay_label_securityQstnOpt8,
                es : es.epay_label_securityQstnOpt8
              },
              value: en.epay_label_securityQstnOpt8
            }
        ],
        securityAnswer: null,
        initialPayment:{
            routingNumber:"",
            accountNumber:"",
            accountType: undefined,
            creditCard:{
                cc1: null,
                cc2: null,
                cc3: null,
                cc4: null,
                cardType:"",
                nameOnCard: null,
                expDate: "", //'10/2017'
                securityCode: null, // 127
                billingAddress1: '',
                billingAddress2: '',
                billingCity: null,
                billingState: null,
                billingZipCode: null,
                billingEmail: null,
                billingPhone:  null
            }
        },
        autoPayment: {
            useSameAcctAsInitial: false,
            existingBankOrCredit: true,
            routingNumber:"",
            accountNumber:"",
            accountType: undefined,
            existingAcctNumber:"",
            nameOnAccount:"",
            skipAutopay: false,
            creditCard:{
                cc1: "",
                cc2: "",
                cc3: "",
                cc4: "",
                cardType: "",
                nameOnCard:"",
                expDate:"",
                securityCode:"",
                billingAddress1: "",
                billingAddress2: "",
                billingCity: "",
                billingState: "",
                billingZipCode: "",
                billingEmail: "",
                billingPhone: ""
            }
        },
        epayStatus: "In Progress",
        epayStatusId: 1,
        possibleStatuses: [
          {
            id:0,
            title: "Not Started",
            cssClass: "process-standby",
            editable: true
          },
          {
            id:1,
            title: "In Progress",
            cssClass: "process-yellow",
            editable: true
          },
          {
            id:2,
            title: "Completed",
            cssClass: "process-complete",
            editable: false
          }
        ]
    };
    vm.login = {
      customerUsername: null,
      customerPassword: null,
      engagementId: null
    };
    vm.order;
    vm.engagementId = $routeParams.engagementId;
    vm.modal;
    vm.alertClass = '';
    vm.viewLoading = false;
    vm.statusMessage = undefined;
    vm.message = undefined;
    vm.message2 = undefined;
    vm.error = undefined;
    vm.initialPaymentPaid = false;
    vm.customer;
//	  VAN-2372 Initial Payment Breakdown details starts
    vm.diffDays;
    vm.showBreakDown;
    vm.rentalFee;
    vm.liabilityWaiverAmt;
    vm.benefitsPlusAmt;
    vm.totalInitialAmt;
//	  VAN-2372 Initial Payment Breakdown details ends
    vm.engagement;
    vm.storeInfo;
    vm.userNamePattern = /^[a-z0-9]+$/i;
    vm.userPassPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7}$/;
    vm.validUsername = true;
    vm.validPass = true;
    vm.verifyPass = true;
    vm.initialPayment = '19077';
    vm.formattedInitialPayment;
    vm.bankAccountNoLastFourDigit;
    vm.changeDue = 0;
    vm.change;
    vm.cashPaymentToLow = false;
    vm.securityQuestion = $rootScope.selectedLanguage == 'es' ? vm.account.securityQuestion[0].name.es : vm.account.securityQuestion[0].name.en;
    vm.paymentMessageSuccess = $rootScope.selectedLanguage == 'es' ? es.epay_label_success_epayCreate : en.epay_label_success_epayCreate;
    vm.paymentMessageFailure = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_paymentFailed : en.epay_label_error_paymentFailed;
    vm.invalidBankDetailsType = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_invalidBankDtlDesc : en.epay_label_error_invalidBankDtlDesc;
    vm.invalidBankDetail = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_invalidBankDtl : en.epay_label_error_invalidBankDtl;
    vm.promoNotAvailable = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_promo : en.epay_label_error_promo;
    //PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment    
    vm.internalRecordLocked = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_recordLocked : en.epay_label_error_recordLocked;
    vm.noTransactionProcess = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_transaction : en.epay_label_error_transaction;
    vm.transactionDesc1 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc1 : en.epay_label_transactionDesc1;
    vm.transactionDesc2 =  $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc2 : en.epay_label_transactionDesc2;
    vm.transactionDesc3 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc3 : en.epay_label_transactionDesc3;
    vm.transactionDesc4 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc4 : en.epay_label_transactionDesc4;
    vm.transactionDesc5 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc5 : en.epay_label_transactionDesc5;
    vm.transactionDesc6 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc6 : en.epay_label_transactionDesc6;
    vm.transactionDesc7 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc7 : en.epay_label_transactionDesc7;
    vm.transactionDesc8 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc8 : en.epay_label_transactionDesc8;
    vm.transactionDesc9 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc9 : en.epay_label_transactionDesc9;
    vm.transactionDesc10 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc10 : en.epay_label_transactionDesc10;
    vm.transactionDesc11 = $rootScope.selectedLanguage == 'es' ? es.epay_label_transactionDesc11 : en.epay_label_transactionDesc11;
    vm.errorEpayCreate = $rootScope.selectedLanguage == 'es' ? es.epay_label_error_epayCreate : en.epay_label_error_epayCreate;
    vm.paymentDesc1 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc1 : en.epay_label_paymentDesc1;
    vm.paymentDesc8 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc8 : en.epay_label_paymentDesc8;
    vm.paymentDesc9 = $rootScope.selectedLanguage == 'es' ? es.epay_label_paymentDesc9 : en.epay_label_paymentDesc9;
    vm.epaySuccess = $rootScope.selectedLanguage == 'es' ? es.epay_label_epaySuccess : en.epay_label_epaySuccess;
    /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
    vm.valAutoPay = false;
    vm.cashPaymentSuccess = false;
    /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
    
    vm.employeeId;
    vm.epayAccount = {
      customerPassword: vm.account.password,
      customerUsername: vm.account.username,
      customerFirstName: null,
      customerLastName: null,
      customerPassCreatQuestion: vm.account.password,
      customerPassCreatQuestionAnswer: vm.securityQuestion,
      customerEmailAddress: "",
      addToEmailList: "0"
    };
    vm.customerFind;
    vm.invalidEpayCredit = false;
    vm.invalidAutoCredit = false;
    vm.cashPaymentAmount;
    vm.noCashPaymentAmount = false;
    vm.initialPaymentStatus = false;
    vm.initialPaymentMessage;
    vm.paymentErrorCode;
    vm.initialPaymentErrorClass = "";
    vm.autoPaySuccess=false;
    vm.autoPayStatus;
    vm.autoPayMessage;
    vm.autoPayClass;
    vm.copyPaymentInfo;
    vm.pinAttempts = 0;
    vm.employeePinError;
    vm.changePaymentType = false;
    vm.unmannedAutoPayDo = false;
    vm.initialPayWithAutoPay = false;
    vm.autoPayDate = "";
    vm.changeAutoPaymentMode = false;
    vm.invalidBankDetailCount = 0;
    vm.showPromoPayment = false;
    vm.promoPaymentCheckboxValue = false;
    vm.selectedPromoOption = undefined;
    vm.promoOptions = [];
    vm.associatePassFail = false;
    vm.invalidPassPhrase = false;
    vm.invalidPromoPayment = false;
    vm.empPassPhrase;
    vm.errorMessage = '';
    vm.showPromoOptions = false;
    vm.promoSelected;
    vm.promoPaymentOfferSelected = false;
    vm.promoValue = [];
    vm.promoMinValue;
    vm.zeroDollarPromoInitialPayment = false;
    vm.initialPaymentSetUp = false;
    
    //VAN-616 - VAN - Low Down Initial Payment
    vm.promoCode;
	vm.promoDesc;
	vm.promoAmount;
	
    <!-- Start VAN-3023 -->
    vm.resetAccountDetails = resetAccountDetails;
    <!-- Start VAN-3023 -->

      // Controller functions
    vm.showModal = showModal;
    vm.navigateTo = navigateTo;
    vm.validateUsername = validateUsername;
    vm.validatePassword = validatePassword;
    vm.findCardTypeInitialPayment = findCardTypeInitialPayment;
    vm.findCardTypeEpayAutoCredit = findCardTypeEpayAutoCredit;
    vm.verifyPassword = verifyPassword;
    vm.createEpayAccount = createEpayAccount;
    vm.epayLogin = epayLogin;
    vm.calculateChange = calculateChange;
    vm.submitPayment = submitPayment;
    vm.processCashPayment = processCashPayment;
    vm.setEmployeeId = setEmployeeId;
    vm.processCreditPayment = processCreditPayment;
    vm.getCustomerData = getCustomerData;
    vm.addDecimalToInitialPayment = addDecimalToInitialPayment;
    vm.submitAutoPay = submitAutoPay;
    vm.validateEpayForm = validateEpayForm;
    vm.validateEpayCredit = validateEpayCredit;
    vm.validateEpayBankingAccount = validateEpayBankingAccount;
    vm.validateAutoCredit = validateAutoCredit;
    vm.validateAutoPay = validateAutoPay;
    vm.validateBankAccount = validateBankAccount;
    vm.validateAutoCreditDtl = validateAutoCreditDtl;
    vm.validateInitialPayment = validateInitialPayment;
    vm.validateBillingInfo = validateBillingInfo;
    vm.resetAutopayValidation = resetAutopayValidation;
    vm.resetInitialPayValidation = resetInitialPayValidation;
    vm.getCreditCardDate = getCreditCardDate;
    vm.sameCardAsInitial = sameCardAsInitial;
    vm.checkCashPaymentAmount = checkCashPaymentAmount;
    vm.ccDateAddSlash = ccDateAddSlash;
    vm.ccDateKeyDown = ccDateKeyDown;
    vm.validateAssociatePasswordCash = validateAssociatePasswordCash;
    vm.isStoreManned = SharedDataSvc.globalVars.storeSetup.isManned;
    vm.setDirectAutoPayCreditCard = setDirectAutoPayCreditCard;
    vm.getDirectAutoPayCreditCardDate = getDirectAutoPayCreditCardDate;
    vm.submitSkipAutoPay = submitSkipAutoPay;
    vm.initialBankAccount = initialBankAccount;
    vm.promoPaymentSelectOperation = promoPaymentSelectOperation;
    vm.verifyEmployeePassPhrase = verifyEmployeePassPhrase;
    vm.verifyEmployeePassWord = verifyEmployeePassWord;
    vm.updateInitialPayment = updateInitialPayment;
    vm.resetPromoPayment = resetPromoPayment;
    vm.getCardTypeDtl = getCardTypeDtl;
    vm.clearPinData = clearPinData;
    vm.setUpInitialPaymentAmount = setUpInitialPaymentAmount;
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    vm.autopayWithEcomplish = false;
    $scope.newProcess = false;
    vm.processAutopay = processAutopay;
    vm.saveAutopay = saveAutopay;
    vm.autopayForCash = vm.autopayForCash;
    //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    vm.findData = findData;//VAN-877-Submit payment button is not showing on AWP
    /* Commented these changes as part of VAN-670 - Consent Verbiage*/
    /*VAN-616 - VAN - Low Down Initial Payment - Starts
    vm.paymentInitial = paymentInitial;
    VAN-616 - VAN - Low Down Initial Payment - Ends*/
    vm.selectedPromo;//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
    $scope.navigateTo = navigateTo;
    //Commented as part of VAN-3330
    //VAN-1395-eComplish iFrame implementation - Starts
	/*vm.tokenizedPAN = "";
	vm.data = "";
	vm.enableBank = "";*/
	vm.lastFourDigit = "";
	/*vm.tokenGenerated = false;
	vm.eventInitialize = false;
	vm.ecomplishRequest = ecomplishRequest;
	vm.invokeEcomIframe = invokeEcomIframe;
	vm.postToPayPage = postToPayPage;
	vm.resHandler = resHandler;
	vm.payPageCreditCard = "";
	vm.payPageBankAcc = "";*/
	var iFrame = "";
	/*var ecomSrcURL = "";
	vm.htStoreID = "";*/
	vm.copyPayPageInfo = false;
	vm.autopayIframe = false;
	/*vm.resHandlerInitialPay = resHandlerInitialPay;
	vm.resHandlerAutopay = resHandlerAutopay;*/
	vm.autoPayToken = autoPayToken;
	//VAN-1395-eComplish iFrame implementation - Ends
    
    //VAN-2012-VAN-AutoPay Issue - Starts
    vm.processCashAndAutoPay = processCashAndAutoPay;
    vm.processCash = processCash;
    //VAN-2012-VAN-AutoPay Issue - Ends
    
    vm.zeroPromo = false;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days

    //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    vm.configIframe = configIframe;
    vm.invokeVantivIframe = invokeVantivIframe;
    vm.getCardType = getCardType;
    vm.payPageRegID;
    vm.showIframe = true;
    vm.paymentId = "";
    vm.isPayRegIdGenerated = false;
    vm.props = "";
    var payframeClient =  "";
    var iFrame = "";
    var payPageID = "";
    var merchantID = "";
    vm.cardType = "";
    //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
	
    init();

    function init() {
      $rootScope.hideDashboardButton = true;
      $rootScope.hideDashboardAgreementButton = false;
      $rootScope.hideTrainingLibButton = false;
      $rootScope.isHybrid = $cookieStore.get('isHybrid');
      $rootScope.isMannedMode = $cookieStore.get('isMannedMode');
      $scope.isSpanishStore = $cookieStore.get('isSpanishStore');

      $log.log('[EPay.init] Init epay controller');
      $log.log('[EPay.init] initialPaymentPaid', vm.initialPaymentPaid);

      // Remove after auto is fully setup
      // vm.account.loggedIntoEpay = true;
      // vm.initialPaymentPaid = true;

      getCardTypeDtl();

      
      getStoreInfoFactory.getData()
        .then(function(response) {
          $log.log('[EPay.init] STORE INFO', response);
          vm.storeInfo = response;
          $rootScope.invoiceMethod = response.invoiceMethod;
          vm.initialPaymentSetUp = response.lowInitialPaymentEnabled;
        });

      
      updateEngagementItemFactory.getData(vm.engagementId)
        .then(function (response) {
          var order,
              findData = { 'firstName': null, 'lastName': null, 'phone': null };

          vm.engagement = response;

          if(vm.engagement.orderContent) {
            order = getOrder();
          }

          $log.log('[EPay.init] engagement', vm.engagement);
          $log.log('[EPay.init] order', order);
          
          showModal('Processing', 'processing-modal');
          
          customerDataFactory.findByEngagementId(vm.engagementId)
            .then(function(response) {
              $log.log('[EPay.init] customerDataFactory.findByEngagementId response: ', response);
              vm.customer = response.customer;
              vm.diffDays;
              vm.engagementPromo = JSON.parse(response.promoSelectedAtAgreement);//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP 
//        	  VAN-2372 Initial Payment Breakdown details show starts
        	  if(response.payToDate != null) {
    			  var currentdate = moment(new Date());
    			  var nextDuedate = moment(response.payToDate);
    			  vm.diffDays = nextDuedate.diff(currentdate, 'days')+1;
    			  console.log('vm.diffDays', vm.diffDays)
    			  if(vm.diffDays > 15) {
    				  vm.showBreakDown = true;
    			  }else {
    				  vm.showBreakDown = false;
    			  }
    		  }
//        	  VAN-2372 Initial Payment Breakdown details show ends
//    		  VAN-2372 Initial Payment Breakdown details starts
               if(vm.diffDays > 15 || response.totalDueAmount === null || vm.engagementPromo.desc === '') {//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
//         		  VAN-2372 Initial Payment Breakdown details ends
            	  if(response.payToDate != null){
            		  var selectedDate = moment(response.payToDate).format('YYYYMMDD');}
            	  else{
            		  selectedDate = moment(response.nextPaymentDate).format('YYYYMMDD');
            	  }

            	  var calcPaymentData= {
            			  "id": response.customerHTStoreId,
            			  "hoCustId": response.customerHTId,
            			  "paymentToDate": selectedDate,
            			  "clubSwitch": response.clubSelection,
            			  "agreementNumber": response.agreementHTId,
            			  "waiverSwitch": response.waiverSelection,
            			  "customerId": response.customer.customerId ,
            			  "customerEngagementId": response.engagementId
            	  };
            	  paymentFactory.calculatePayment(calcPaymentData)
            	  .then(function(response) {
            		  $log.log('[EPay.init] paymentFactory.calculatePayment response: ', response);
//            		  VAN-2372 Initial Payment Breakdown details starts
            		 if (vm.diffDays > 15 || vm.engagementPromo.desc === '') {//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
            		  vm.rentalFee = addDecimalToInitialPayment(response.raAmtNoLdwInclTax);
            		  vm.liabilityWaiverAmt = addDecimalToInitialPayment(response.ldwAmtInclTax);
            		  vm.benefitsPlusAmt = addDecimalToInitialPayment(response.clubAmtInclTax);
            		  vm.totalInitialAmt = addDecimalToInitialPayment(response.totalPmtAmtInclTax);
            		 }
//            		  VAN-2372 Initial Payment Breakdown details ends
            		  vm.findData();
            	  }).catch(function(response) {
            		  $log.log('[EPay.init] could not get the data from calculatePayment call', response);
            	  });
              	}
              else{
            	  vm.findData();
              }
              //VAN-877-Submit payment button is not showing on AWP - Ends
            }).catch(function(response) {
              $log.log('[EPay.init] could not get customer data', response);
            });
        }).catch(function(response) {
          $log.log('[EPay.init] could not get customer data', response);
        });
   }
    
    //VAN-877-Submit payment button is not showing on AWP - Starts
    function findData(){
    	var findData = { 'firstName': null, 'lastName': null, 'phone': null };
    	// Set customer values
        vm.account.name = vm.customer.fName + ' ' + vm.customer.lName;
        vm.account.initialPayment.creditCard.billingEmail = vm.customer.email;
        vm.account.initialPayment.creditCard.billingPhone = vm.customer.phNum;

        vm.customer.customerFirstName = findData.firstName = vm.customer.fName;
        vm.customer.customerLastName = findData.lastName = vm.customer.lName;
        vm.customer.customerEmailAddress = vm.customer.email;
        findData.phone = vm.customer.phNum;

        applicationService.getApplication(findData)
          .then(function (response) {
            modalService.dismiss();
            console.log('[EPay.init] Find customer success', response);

            if (response.VanEngagement) {
              vm.customerFind = response;
              vm.initialPayment = checkForInitialDecimal(response.VanEngagement.totalDueAmount);
              if(!vm.storeInfo.lowInitialPaymentEnabled){//VAN-670 - Consent Verbiage
              SharedDataSvc.initialPaymentValue = vm.initialPayment;}
              //Start - VAN-616 - Low Down Initial Payment
//              vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
              	/* Commented as part of VAN-670 - Consent Verbiage*/
              	//vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[0].payment);
              	//VAN-672 - Low Down Initial Payment should be displayed on RA - Starts
              	if(vm.customerFind.VanEngagement != undefined && vm.customerFind.VanEngagement.promoSelectedAtAgreement != null)
              		vm.selectedPromo = JSON.parse(vm.customerFind.VanEngagement.promoSelectedAtAgreement);//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
//              	vm.formattedInitialPayment = vm.selectedPromo.payment;//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
              	/*if(vm.formattedInitialPayment == '49'){*/
                //if(vm.selectedPromo.desc.includes('AutoPay Required')){//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	if(vm.selectedPromo.desc.indexOf('AutoPay Required')!=-1){ //VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		vm.account.paymentInitialValSelected ='AutoPayRequired';
              	}//VAN-2841-AWP-Value City $1 Promo
              	//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP
              	//else if(vm.selectedPromo.desc.includes('without AutoPay')  || vm.selectedPromo.code === 'Q' || vm.selectedPromo.desc === ""){//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
              	else if(vm.selectedPromo.desc.indexOf('without AutoPay')!=-1  || vm.selectedPromo.code === 'Q' || vm.selectedPromo.desc === ""){//VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		/*if(vm.formattedInitialPayment == '99'){*/
              			vm.account.paymentInitialValSelected ='WithoutAutoPay';
              	}
              	//VAN-672 - Low Down Initial Payment should be displayed on RA - Ends
              	//else if(vm.selectedPromo.desc.includes('$0 START PIF') || vm.selectedPromo.code === 'D'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
              	else if(vm.selectedPromo.desc.indexOf('$0 START PIF')!=-1 || vm.selectedPromo.code === 'D'){//VAN-3732 : VAN - Unable to login in ANOW application in  IE browser
              		vm.account.paymentInitialValSelected = 'PIFPromo';
              		if(vm.formattedInitialPayment === "0.0" || vm.customerFind.VanEngagement.totalDueAmount === "0"){
              			vm.zeroPromo = true;
              			vm.account.paymentCredit = 'cash';
              			vm.cashPaymentAmount = '0';
              			vm.changeDue = 0;
              			SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
              			vm.zeroDollarPromoInitialPayment = true;   
              		}
              	}//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
              	//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Starts
              	if(vm.selectedPromo.desc === ''){
              		vm.showBreakDown = true;
              	}//VAN-2843-Variable Initial Payment (no free days)- Kiosk and AWP - Ends
              	vm.promoPaymentOfferSelected=true;
              }
              else{
                  vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              }
              //End - VAN-616 - Low Down Initial Payment
              SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
              var orderContent = getOrder();
              //VAN-672 - Low Down Initial Payment should be displayed on RA
              if(SharedDataSvc.initialPaymentAmount != undefined && !vm.storeInfo.lowInitialPaymentEnabled){//VAN-670 - Consent Verbiage
              var initialPaymentAmount = Number(addDecimalToInitialPayment(SharedDataSvc.initialPaymentValue));}
              vm.autoPayDate = response.VanEngagement.nextPaymentDate.substring(6);
              console.log("Auto Pay Date", vm.autoPayDate);
              //Storing eligible promos in sharedData services
              SharedDataSvc.eligiblePromos = JSON.parse(response.VanEngagement.eligiblePromos);
              if(SharedDataSvc.eligiblePromos !== null && SharedDataSvc.eligiblePromos.length > 0 && $rootScope.isMannedMode) {
                vm.showPromoPayment = true;
                _.forEach(SharedDataSvc.eligiblePromos, function(obj, index) {
                  vm.promoOptions[index] = {
                    id: index,
                    options: obj.code + " - " + obj.desc
                  };
                });
                console.log("Promo Payment options", SharedDataSvc.eligiblePromos);
              } else {
                vm.showPromoPayment = false;
              }

              //VAN-1395-eComplish iFrame implementation - Starts
              if(vm.account.paymentCredit === 'credit') {
//            	  vm.enableBank = "N";
//            	  ecomplishRequest();
            	  configIframe();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            	  
              }
              //VAN-1395-eComplish iFrame implementation - Ends
            }
          }, function(response) {
            modalService.dismiss();
            console.log('[EPay.init] Find customer fail', response);

            if (response.status == 303) {
              vm.customerFind = response.data.errorData;
              vm.initialPayment = checkForInitialDecimal(vm.customerFind.VanEngagement.totalDueAmount);
              vm.formattedInitialPayment = addDecimalToInitialPayment(vm.initialPayment);
              SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
            }
          });

        $log.log('[EPay.init] customer', vm.customer);
        // getCustomerData();
}
    //VAN-877-Submit payment button is not showing on AWP - Ends
    
    function getCardTypeVal(val){
    	console.log(val);
    	var cardNumEntered = val.cc1+val.cc2+val.cc3+val.cc4;
    	console.log(cardNumEntered);
    	var cardType = SharedDataSvc.detectCardType(cardNumEntered);
    	console.log(cardType);	
    	
    	if(cardType){
    		var cardValues = {
    				mastercard:"1",
    				visa:"2",
    				amex:"3",
    				discover:"4",
    				diners:"5"	
    		};

    		console.log(cardValues.cardType);
    		
    		return cardValues[cardType];
    	}
    	else{
    		return false;
    	}
    }
    
    function findCardTypeInitialPayment(val){
    	vm.account.initialPayment.creditCard.cardType = getCardTypeVal(val)?getCardTypeVal(val):"";
    }
    
    function findCardTypeEpayAutoCredit(val){
    	vm.account.autoPayment.creditCard.cardType = getCardTypeVal(val)?getCardTypeVal(val):"";
    }

    function navigateTo(target) {
      modalService.dismiss();
      $location.path(target);
    };
    /* Start VAN-3023 */
    function resetAccountDetails()
    {
        vm.account.username = '';
        vm.account.password = '';
        vm.account.passwordVerify='';
        vm.securityQuestion='';
        vm.account.securityAnswer='';
        vm.resetAccountDetail = false;
        $scope.ePayFormSubmitted = false;
        vm.message = '';
        vm.message2 = '';
        vm.statusMessage = false;
        vm.securityQuestion = $rootScope.selectedLanguage == 'es' ? vm.account.securityQuestion[0].name.es : vm.account.securityQuestion[0].name.en;
    }
    /* End VAN-3023 */
    function validateUsername(userName) {
      if(userName) {
    	  vm.statusMessage = false;//VAN-858 - Quad Weekly Payment Frequency
        if(userName.match(vm.userNamePattern)) {
          vm.validUsername = true;
          $scope.ePayLogin.ePayUser.$setValidity('ePayUser', true);
        } else {
          vm.validUsername = false;
          $scope.ePayLogin.ePayUser.$setValidity('ePayUser', false);
        }
      }
    }
    
    /* Validation for credit/debit card expiry date. It should not be a past year or month */
    function isPastMonthYear(date){
	    var dt = new Date();
      	var crrMonth = dt.getMonth()+1;
      	var crrYear = dt.getFullYear().toString().substring(2);
      	var inputMonth = date.substring(0,2);
      	var inputYear = date.substring(2,4);
      	
      	if( ((inputMonth < crrMonth) && (inputYear <= crrYear)) || (inputYear < crrYear) ){
      		return false;
      	}else{
      		return true;
      	}	
    }

    function ccDateKeyDown(event, name) {
      //console.log('KEY DOWN', event);
      var date = vm.account.initialPayment.creditCard.expDate;
      var rep = vm.account.initialPayment.creditCard.expDate.slice(0, 2);
     
      if(name === 'autoPayment') {
        date = vm.account.autoPayment.creditCard.expDate;
        rep = vm.account.autoPayment.creditCard.expDate.slice(0, 2);
     
        if(date.length == 4){
        	var result = isPastMonthYear(date);
        	$scope.ePayAutoCredit.ccExperation.$setValidity('ccExperation', result);
        } 
      }else{
      	if(date.length == 4){
      		var result = isPastMonthYear(date);
      		$scope.ePayCreditCard.ccExperation.$setValidity('ccExperation', result);
      	}
      }

      // if(date.length === 2 && event.keyCode !== 8 || date.length === 2 && event.keyCode !== 46) {
      //   console.log('Add slash');
      //   if(date.slice(-1) !== '/') {
      //     if(name === 'autoPayment') {
      //       vm.account.autoPayment.creditCard.expDate = date + '/';
      //     } else {
      //       vm.account.initialPayment.creditCard.expDate = date + '/';
      //     }
      //   }
      // }

      if(event.keyCode === 8 || event.keyCode === 46) {
        vm.account.initialPayment.creditCard.expDate = date.replace('/', '');
        vm.account.autoPayment.creditCard.expDate = date.replace('/', '');
        if(date.indexOf('/') < 0 && date.length === 3) {
          if(date.slice(-1) !== '/') {
            if(name === 'autoPayment') {
              // vm.account.autoPayment.creditCard.expDate = date + '/';
              vm.account.autoPayment.creditCard.expDate = vm.account.autoPayment.creditCard.expDate.replace(rep, rep+'/');
            } else {
              // vm.account.initialPayment.creditCard.expDate = date + '/';
              vm.account.initialPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate.replace(rep, rep+'/');
            }
          }
        }
      } else {
        if(date.indexOf('/') < 0 && date.length >= 2) {
          if(date.slice(-1) !== '/') {
            if(name === 'autoPayment') {
              // vm.account.autoPayment.creditCard.expDate = date + '/';
              vm.account.autoPayment.creditCard.expDate = vm.account.autoPayment.creditCard.expDate.replace(rep, rep+'/');
            } else {
              // vm.account.initialPayment.creditCard.expDate = date + '/';
              vm.account.initialPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate.replace(rep, rep+'/');
            }
          }
        }
      }
    }

    function ccDateAddSlash(val, name) {
      // var date = vm.account.initialPayment.creditCard.expDate;

      // if(name === 'autoPayment') {
      //   date = vm.account.autoPayment.creditCard.expDate;
      // }

      // console.log('CC DATE', date);
      // if(date.indexOf('/') < 0) {
      //   console.log('CC DATE len', date.length);
      //   if(date.length === 2) {
      //     console.log('Add slash');
      //     vm.account.initialPayment.creditCard.expDate = date + '/';
      //   }
      // }
    }

    function validateCCDate(date) {
      if(date.indexOf('/') < 0) {
        console.log('no slash, needs formatting');
        if(date.length === 4) {
          console.log('insert slash');
        } else {
          console.log('failed validation');
        }
      } else {
        if(date.length === 5) {
          console.log('passes validation');
        } else {
          console.log('failed validation');
        }
      }

    }

    function validatePassword(password) {
      if(password) {
        /* Check for length, one uppercase char and one lowercase char to pass /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7}$/
           */


        if(password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/)) {
        	if( password.match(/^[a-z0-9]+$/i) ){
        		vm.validPass = true;
        		$scope.ePayLogin.ePayPass.$setValidity('ePayPass', true);
        	}else{
        		vm.validPass = false;
        		$scope.ePayLogin.ePayPass.$setValidity('ePayPass', false);
        	}


        } else {
          vm.validPass = false;
          $scope.ePayLogin.ePayPass.$setValidity('ePayPass', false);
        }
      }
    }

    function verifyPassword(password) {
      vm.verifyPass = password === vm.account.password ? true : false;
      console.log('verify password', vm.verifyPass);
      if(vm.verifyPass) {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', true);
      } else {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', false);
      }
    }

    function getCustomerData() {
      var areaCode = vm.customer.customerPhoneNum.substring(0, 3);
      var phone = vm.customer.customerPhoneNum.substring(3, 10);
      var data = { 'firstName' : vm.customer.customerFirstName,
                   'lastName' : vm.customer.customerLastName,
                   'phoneNumber': phone,
                   'areaCode': areaCode
                 };
      console.log('customer get data', data);
      getSpecificApplication.findByNameAndPhone(data)
        .then(function(response) {
          $log.log('Successfully returned customer data', response);
        }, function(response){
          $log.log('Error getting customer data', response);
        });
    }

    function showModal(name, windowClass, size) {
      windowClass = windowClass || '';
      size =  size || '';
      vm.modal = modalService.open({
        templateUrl: '../../../partials/modal_' + name + '.html',
        backdrop: 'static',
        windowClass: windowClass,
        size: size,
        scope: $scope
      });
    }

    function setEpayAccount() {
      vm.epayAccount.customerPassword = vm.account.password;
      vm.epayAccount.customerUsername = vm.account.username;
      vm.epayAccount.customerFirstName = vm.customer.customerFirstName;
      vm.epayAccount.customerLastName = vm.customer.customerLastName;
      vm.epayAccount.customerPassCreatQuestion = vm.securityQuestion;
      vm.epayAccount.customerPassCreatQuestionAnswer = vm.account.securityAnswer;
      vm.epayAccount.customerEmailAddress = vm.customer.customerEmailAddress;
    }

    function setEpayLogin() {
      vm.login.customerUsername = vm.account.username;
      vm.login.customerPassword = vm.account.password;
      vm.login.engagementId = vm.engagementId;
    }

    function getOrder() {
      var order = vm.engagement.orderContent ? vm.engagement.orderContent : null;
      return JSON.parse(SharedDataSvc.base64.decode(order));
    }

    function validateCredit() {
      var ccn = vm.account.initialPayment.creditCard.cc1 + vm.account.initialPayment.creditCard.cc2 + vm.account.initialPayment.creditCard.cc3 + vm.account.initialPayment.creditCard.cc4;
      if(!ccn || ccn.length < 16) {
        $scope.ePayCreditCard.cc1.$setValidity('cc1', false);
        $scope.ePayCreditCard.cc2.$setValidity('cc2', false);
        $scope.ePayCreditCard.cc3.$setValidity('cc3', false);
        $scope.ePayCreditCard.cc4.$setValidity('cc4', false);
        return false;
      } else {
        return true;
      }
    }

    function validateAutoCreditDtl() {
      var ccn = vm.account.autoPayment.creditCard.cc1 + vm.account.autoPayment.creditCard.cc2 + vm.account.autoPayment.creditCard.cc3 + vm.account.autoPayment.creditCard.cc4;
      if(!ccn || ccn.length < 16) {
        $scope.ePayAutoCredit.cc1.$setValidity('cc1', false);
        $scope.ePayAutoCredit.cc2.$setValidity('cc2', false);
        $scope.ePayAutoCredit.cc3.$setValidity('cc3', false);
        $scope.ePayAutoCredit.cc4.$setValidity('cc4', false);
        return false;
      } else if(ccn!= '' && ccn.length == 16 && ($scope.ePayAutoCredit.cc1.$invalid || 
        $scope.ePayAutoCredit.cc2.$invalid || $scope.ePayAutoCredit.cc3.$invalid || 
        $scope.ePayAutoCredit.cc4.$invalid)){
          vm.invalidAutoCredit = false;
          $scope.ePayAutoCredit.cc1.$setValidity('cc1', true);
          $scope.ePayAutoCredit.cc2.$setValidity('cc2', true);
          $scope.ePayAutoCredit.cc3.$setValidity('cc3', true);
          $scope.ePayAutoCredit.cc4.$setValidity('cc4', true);
        return false;
      } else {
        return true;
      }
    }

    function validateBillingInfo() {
      console.log('validate billing');
      $scope.submittedBillingInfo = true;

      if($scope.billingInfo.$valid) {
        console.log('billing info valid');
      } else {
        console.log('billing info not valid');
      }
    }

    function validateEpayForm() {
    /* Start VAN-3023 */
      vm.resetAccountDetail = true;
    /* End VAN-3023 */
        $scope.ePayFormSubmitted = true;

      if(vm.account.password !== vm.account.passwordVerify) {
        // console.log('passwords do not match');
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', false);
      }

      if(vm.account.newOrExistingAcct) {
        if($scope.ePayLogin.$valid) {
          vm.createEpayAccount(vm.epayAccount);
        }
      } else {
        $scope.ePayLogin.passwordVerify.$setValidity('passwordVerify', true);
        $scope.ePayLogin.securityAnswer.$setValidity('securityAnswer', true);
      }

      if(!vm.account.newOrExistingAcct && vm.account.username.$valid && vm.account.password.$valid) {
        epayLogin(vm.login);
      }
    }

    function validateEpayCredit() {
      $scope.ePayCreditCard.cc1.$setValidity('cc1', true);
      $scope.ePayCreditCard.cc2.$setValidity('cc2', true);
      $scope.ePayCreditCard.cc3.$setValidity('cc3', true);
      $scope.ePayCreditCard.cc4.$setValidity('cc4', true);

      if($scope.ePayCreditCard.$valid) {
        submitPayment();
      } else {
        if(!validateCredit()) {
          vm.invalidEpayCredit = true;
        }
      }
    }

    function validateEpayBankingAccount () {
      if($scope.ePayCreditCard.$valid) {
    	  //submitPayment();//Commented as part of VAN-1395
//    	  postToPayPage();//VAN-1395-eComplish iFrame implementation
    	//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
			if(vm.account.paymentCredit === 'credit'){
				invokeVantivIframe.getPaypageRegId();
			}else if(vm.account.paymentCredit === 'bank'){
				vm.isPayRegIdGenerated = false;
				vm.paymentId =  "";
				SharedDataSvc.autoPaymentData.paymentTokenId = "";
				submitPayment();
			}
		}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      }
    
    /* Commented these changes as part of VAN-670 - Consent Verbiage*/
    /*  VAN-616 - VAN - Low Down Initial Payment - Starts
    function paymentInitial(){
    	showModal('paymentInitial');
    }
    VAN-616 - VAN - Low Down Initial Payment - Ends*/
    
    function validateInitialPayment() {
      vm.invalidEpayCredit = false;
      $scope.initialPaymentOCHSubmitted = true;
      $scope.epayCreditSubmitted = true;
      //Commented as part of VAN-1395
      /*if(vm.account.paymentCredit === 'credit') {
        validateEpayCredit();
      } else if(vm.account.paymentCredit === 'bank') {
        validateEpayBankingAccount();
      }*/ 
      //Commented as part of VAN-1395
      //VAN-1395-eComplish iFrame implementation - Starts
      if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
    	  validateEpayBankingAccount();
      }//VAN-1395-eComplish iFrame implementation - Ends
      else {
    	  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
    	  /*VAN 762 - AWP - Submit payment is not working when 49 - cash and ANOW promo is selected*/
    	  if(vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.promoPaymentCheckboxValue && !vm.valAutoPay){
    		  vm.validateAutoPay();
    	  }else if(vm.cashPaymentAmount) {
    		  if(!vm.noCashPaymentAmount && !vm.cashPaymentToLow) {
    			  submitPayment();
    		  }
    	  } else {
    		  vm.noCashPaymentAmount = true;
    	  }
      }
      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
    }

    function resetInitialPayValidation() {
      $scope.ePayCreditCard.$setPristine();
      vm.invalidEpayCredit = false;
      $scope.initialPaymentOCHSubmitted = false;
      $scope.epayCreditSubmitted = false;
      vm.cashPaymentToLow = false;
      vm.noCashPaymentAmount = false;
      vm.account.initialPayment.accountType = undefined;
      vm.showIframe = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    }

    function resetAutopayValidation() {
      $scope.ePayAutoCredit.$setPristine();
      $scope.bankAccount.$setPristine();
      $scope.billingInfo.$setPristine();
      $scope.bankAccountSubmitted = false;
      $scope.autoCreditSubmitted = false;
      $scope.submittedBillingInfo = false;
      vm.invalidAutoCredit = false;
      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
      vm.autoPayStatus = false;
      vm.account.autoPayment.routingNumber = "";
      vm.account.autoPayment.accountNumber = "";
      vm.account.autoPayment.accountType = "";
      if(iFrame.parentNode != null){
    	  var iframeVal = iFrame;
    	  iframeVal.parentNode.removeChild(iframeVal);
      }
      if(vm.account.paymentInitialValSelected != 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.initialPaymentPaid == true){
    	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
      }
      if(vm.account.autoPayment.existingBankOrCredit){
//  	  vm.enableBank = "N";
//  	  ecomplishRequest();
    	  vm.showIframe = true;
    	  configIframe();
      }else{
//  	  vm.enableBank = "Y";
//  	  ecomplishRequest();
    	  vm.isPayRegIdGenerated = false;
    	  vm.paymentId =  "";
    	  SharedDataSvc.autoPaymentData.paymentTokenId = "";
    	  vm.showIframe = false;
      }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      //VAN-1395-eComplish iFrame implementation - Ends  
    }

    function validateAutoBank() {
      if($scope.ePayAutoCredit.$valid) {
        console.log('submit auto payment');
      }
    }

    function validateAutoCredit() {
      $scope.autoCreditSubmitted = true;
      validateBillingInfo();
      //Commented as part of VAN-1395
      /*if($scope.ePayAutoCredit.$valid && $scope.billingInfo.$valid) {
        console.log('Auto credit passes');
        submitAutoPay(); 
      }*///Commented as part of VAN-1395
      //VAN-1395-eComplish iFrame implementation - Starts
      if($scope.billingInfo.$valid) {
//    	  postToPayPage();
    	  invokeVantivIframe.getPaypageRegId();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }//VAN-1395-eComplish iFrame implementation - Ends
      else{
    	  if(!validateAutoCreditDtl()) {
          vm.invalidAutoCredit = true;
        }
        console.log('Auto credit initial not valid');
      }
    }

    function validateBankAccount() {
      $scope.bankAccountSubmitted = true;
      validateBillingInfo();
      console.log('account type', vm.account.autoPayment.accountType);
      if(vm.account.autoPayment.accountType !== undefined) {
        vm.noAccountType = true;
      }else{
        vm.noAccountType = false;
      }
      //Commented as part of VAN-1395 - Starts
      /*if($scope.bankAccount.$valid && vm.noAccountType && $scope.billingInfo.$valid) {
       submitAutoPay();
      }*///Commented as part of VAN-1395 - Ends
      //VAN-1395-eComplish iFrame implementation - Starts
      if( $scope.billingInfo.$valid && $scope.bankAccount.$valid && vm.noAccountType) {//VAN-3482-AWP - Application throwing 700 error if we do not select any Account Type option in Bank Auto Pay section
//    	  postToPayPage();
    	  submitAutoPay();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }//VAN-1395-eComplish iFrame implementation - Ends
    }

    function validateAutoPay(val) {
    	if(val) {
        vm.changeAutoPaymentMode = true;
      }
      $scope.autoCreditSubmitted = true;
      //VAN-1395-eComplish iFrame implementation - Starts
      if(vm.copyPayPageInfo){
    	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'Y';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  submitAutoPay();
      }
      else{//VAN-1395-eComplish iFrame implementation - Ends
    	  if(vm.account.autoPayment.existingBankOrCredit) {
    		  vm.enableBank = "N";//VAN-1395-eComplish iFrame implementation
    		  vm.validateAutoCredit();
    	  } else {
    		  vm.enableBank = "Y";//VAN-1395-eComplish iFrame implementation
    		  vm.validateBankAccount();
    	  }
      } 
    }

    function validateAssociatePasswordCash(pass) {
      var auth = SharedDataSvc.base64.decode($cookieStore.get('store_nick_name'));
      if(auth === pass) {
        vm.employeePinError = false;
        vm.employeeId = '';
        showModal('cashPaymentEmployeePin');
      } else {
        vm.invalidAssociatePass = true;
      }
    }

    function createEpayAccount(epayData) {
      setEpayAccount();
      // console.log('LOGIN FORM', $scope.ePayLogin);
      if($scope.ePayLogin.$valid) {
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.createEpayAccount(epayData)
          .then(function(response){
            console.log('[EPay.createEpayAccount] RESPONSE', response);
            // Handle 400 error response
            if(response.status === 400) {
              $scope.viewLoading = false;
              vm.modal.close();
              vm.statusMessage = true;
              vm.alertClass = 'alert-danger';
              vm.message = response.data.status_details;
              vm.message2 = '';
            } else {
              $scope.viewLoading = false;
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-success';
              vm.message = 'epay_label_epaySuccess';
              vm.message2 = '';
              vm.account.newOrExistingAcct = false;
              vm.account.loggedIntoEpay = true;
              $log.log('[EPay.createEpayAccount] EPAY CREATED', response);
            }
          }, function(response){
            $scope.viewLoading = false;
            modalService.dismiss();
            // vm.message = response.data;
            // vm.showThis = response.data;
            vm.alertClass = 'alert-danger';
            vm.statusMessage = true;
            $log.log('[EPay.createEpayAccount] UNABLE TO CREATE EPAY ACCOUNT', response);
            if(response.data.errorData.status_code.indexOf('ENG-E2002-400') > -1) {
              vm.message = "epay_label_paymentDesc8";
              vm.message2 =  "epay_label_paymentDesc9";
              // vm.account.username + $translate.instant("");
            } else {message2
              vm.message = "epay_label_error_epayCreate";
              vm.message2 = '';
            }

          });
      }
    }

    function checkCashPaymentAmount() {
      if(vm.change || vm.change === 0) {
        if(vm.change < 0) {
          vm.cashPaymentToLow = true;
        } else {
          vm.cashPaymentToLow = false;
        }
      }
    }

    function calculateChange(paymentAmount) {
      vm.noCashPaymentAmount = false;
      if(paymentAmount.indexOf('.') < 0) {
        paymentAmount = paymentAmount + '.00';
      }
      var change = (Number(paymentAmount) - Number(vm.formattedInitialPayment));
      vm.change = change;
      vm.changeDue = change > 0 ? change.toFixed(2) : 0;
    }

    function setCashInitialPayment() {
      SharedDataSvc.cashInitialPayment.id = vm.engagement.customerHtStoreId;
      SharedDataSvc.cashInitialPayment.paymentToDate = vm.customerFind.VanEngagement.payToDate;
      SharedDataSvc.cashInitialPayment.agreementNumber = vm.engagement.agreementHtId;
      SharedDataSvc.cashInitialPayment.amtOwed = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.cashInitialPayment.amtTendered = formatCash(vm.cashPaymentAmount);
      SharedDataSvc.cashInitialPayment.changeAmt = formatCash(vm.changeDue);
      SharedDataSvc.cashInitialPayment.customerEngagementId = vm.engagementId;
      //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
      //Start: VAN-616 - Low Down Initial Payment
      /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.cashInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }  
      }else {
      	if(SharedDataSvc.cashInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.cashInitialPayment.promotionDetails;
      }

      //VAN-2012-VAN-AutoPay Issue - Starts
      SharedDataSvc.cashInitialPayment.customerLogin = vm.account.username;
      SharedDataSvc.cashInitialPayment.lastFourDigit =  vm.lastFourDigit;
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].agreementType = "Agreement";
      SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[0].monthlyAmount = getAgreementsMonthly();

      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      if(clubStatus === 1) {
    	  if(!SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1]) {
    		  var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
    		  benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
    		  benefits.agreementType = "Benefits";
    		  benefits.monthlyAmount = "0.0";
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.push(benefits);
    	  }else {
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].agreementType = "Benefits";
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo[1].monthlyAmount = "0.0";
    	  }
      }

      if(clubStatus !== 1) {
    	  if(SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.length > 1) {
    		  SharedDataSvc.cashInitialPayment.autoPayAgreementInfo.pop();
    	  }
      }
      //VAN-2012-VAN-AutoPay Issue - Ends
      
      console.log("Cash Payment Initial", SharedDataSvc.cashInitialPayment);
    }

    function setDirectAutoPayCreditCard(){
      console.log("I am called");
      vm.unmannedAutoPayDo = "true";
      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      SharedDataSvc.autoPaymentData.engagementId = typeof vm.engagementId !== 'number' ? Number(vm.engagementId) : vm.engagementId;
      SharedDataSvc.autoPaymentData.customerPassword = vm.account.password;
      SharedDataSvc.autoPaymentData.customerLastName = vm.customerFind.VanEngagement.customer.lName;
      SharedDataSvc.autoPaymentData.customerFirstName = vm.customerFind.VanEngagement.customer.fName;
      SharedDataSvc.autoPaymentData.customerLogin = vm.account.username;
      SharedDataSvc.autoPaymentData.emailAddress = vm.customerFind.VanEngagement.customer.email;

      SharedDataSvc.autoPaymentData.customerPhone = vm.customerFind.VanEngagement.customer.phNum;
      SharedDataSvc.autoPaymentData.customerAddress = vm.customerFind.VanEngagement.customer.addressLine1;
      SharedDataSvc.autoPaymentData.customerState =  vm.customerFind.VanEngagement.customer.state;
      SharedDataSvc.autoPaymentData.customerZip =  vm.customerFind.VanEngagement.customer.addressZipcode;
      SharedDataSvc.autoPaymentData.customerCity =  vm.customerFind.VanEngagement.customer.addressCity;
      SharedDataSvc.autoPaymentData.paymentType = getAutoPayType();

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      if(getAutoPayType() === 'Credit') {
        SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = SharedDataSvc.creditInitialPayment.payment.creditCardNumber;
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = SharedDataSvc.creditInitialPayment.payment.cardType; 
//        if(SharedDataSvc.creditInitialPayment.payment.expirationDate != ""){//VAN-1395-eComplish iFrame implementation
        if(vm.account.initialPayment.creditCard.expDate!=""){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.creditInitialPayment.payment.expirationDate = formatCreditCardDate();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      	SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).month;
      	SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).year;}
        SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = SharedDataSvc.creditInitialPayment.payment.cvv;
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = SharedDataSvc.creditInitialPayment.payment.cardType;//VAN-2012-VAN-AutoPay Issue
      }

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      console.log('CLUB STATUS', clubStatus);
        if(clubStatus === 1) {
            if(!SharedDataSvc.autoPaymentData.agreementList[1]) {
                var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
                benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
                benefits.agreementType = "Benefits";
                benefits.monthlyAmount = "0.0";
                SharedDataSvc.autoPaymentData.agreementList.push(benefits);
            }else {
                SharedDataSvc.autoPaymentData.agreementList[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
                SharedDataSvc.autoPaymentData.agreementList[1].agreementType = "Benefits";
                SharedDataSvc.autoPaymentData.agreementList[1].monthlyAmount = "0.0";
            }
        }

      if(clubStatus !== 1) {
        if(SharedDataSvc.autoPaymentData.agreementList.length > 1) {
          SharedDataSvc.autoPaymentData.agreementList.pop();
        }
      }
		
      if(getAutoPayType() === 'ACH') {
        SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType;
        SharedDataSvc.autoPaymentData.lastFourDigit = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		vm.lastFourDigit = SharedDataSvc.autoPaymentData.lastFourDigit;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.autoPaymentData.creditCardInfo = {};
      }

      //VAN-1395-eComplish iFrame implementation - Starts
//      if(vm.tokenizedPAN != ""){
//    	  SharedDataSvc.autoPaymentData.tokenizedPAN = vm.tokenizedPAN;
      if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  SharedDataSvc.autoPaymentData.paymentTokenId = vm.paymentId;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
    	  SharedDataSvc.autoPaymentData.lastFourDigit = vm.lastFourDigit;
    	  if(getAutoPayType() === 'ACH'){
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = '';
    	  }
    	  else{
    		  SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';
    		  //Commented as part of VAN-3330
    		 /* SharedDataSvc.autoPaymentData.creditCardInfo.cardType = ''; 
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = '';*/
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = '';
    	  }
      }
      //VAN-1395-eComplish iFrame implementation - Ends
      
      //VAN-2012-VAN-AutoPay Issue - Starts
      if(vm.promoPaymentOfferSelected) {
    	SharedDataSvc.autoPaymentData.payRequest.promoCode = vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,
    	SharedDataSvc.autoPaymentData.payRequest.promoDesc = vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,
    	SharedDataSvc.autoPaymentData.payRequest.promoAmount = vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),
    	SharedDataSvc.autoPaymentData.payRequest.promoAmtUsed = formatCash(vm.formattedInitialPayment),
    	SharedDataSvc.autoPaymentData.payRequest.totalDue = SharedDataSvc.initialPaymentValue, 
    	SharedDataSvc.autoPaymentData.payRequest.passPhrase = SharedDataSvc.paymentPassphraseUsed
      }
      
      if(getAutoPayType() === 'ACH') {
      SharedDataSvc.autoPaymentData.payRequest.hoCustName = SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.hoCustName;
      SharedDataSvc.autoPaymentData.payRequest.billingAddressLine = SharedDataSvc.bankPaymentInitialPayment.payment.billingAddressLine;
      SharedDataSvc.autoPaymentData.payRequest.billingState = SharedDataSvc.bankPaymentInitialPayment.payment.billingState;
      SharedDataSvc.autoPaymentData.payRequest.billingCity = SharedDataSvc.bankPaymentInitialPayment.payment.billingCity;
      SharedDataSvc.autoPaymentData.payRequest.billingZipCode = SharedDataSvc.bankPaymentInitialPayment.payment.billingZipCode;
      SharedDataSvc.autoPaymentData.payRequest.paymentAmountInCents = SharedDataSvc.bankPaymentInitialPayment.payment.paymentAmountInCents;
      }else{
    	  SharedDataSvc.autoPaymentData.payRequest.hoCustName = SharedDataSvc.creditInitialPayment.agreement.engagement.hoCustName;
          SharedDataSvc.autoPaymentData.payRequest.billingAddressLine = SharedDataSvc.creditInitialPayment.payment.billingAddressLine;
          SharedDataSvc.autoPaymentData.payRequest.billingState = SharedDataSvc.creditInitialPayment.payment.billingState;
          SharedDataSvc.autoPaymentData.payRequest.billingCity = SharedDataSvc.creditInitialPayment.payment.billingCity;
          SharedDataSvc.autoPaymentData.payRequest.billingZipCode = SharedDataSvc.creditInitialPayment.payment.billingZipCode;
          SharedDataSvc.autoPaymentData.payRequest.paymentAmountInCents =  SharedDataSvc.creditInitialPayment.payment.paymentAmountInCents;
      }
      //VAN-2012-VAN-AutoPay Issue - Ends 
      
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
      if(!$scope.newProcess/*vm.storeInfo.lowInitialPaymentEnabled*/){
    	  vm.submitAutoPay();
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

    }

    function setCreditInitialPayment() {
      console.log("[EPay.setCreditInitialPayment] vm.engagement: ", vm.engagement);
      console.log("[EPay.setCreditInitialPayment] vm.customer: ", vm.customer);
      var creditCardNumber = vm.account.initialPayment.creditCard.cc1 + vm.account.initialPayment.creditCard.cc2 + vm.account.initialPayment.creditCard.cc3 + vm.account.initialPayment.creditCard.cc4;

      SharedDataSvc.creditInitialPayment.agreement.id = vm.engagement.agreementHtId;
      SharedDataSvc.creditInitialPayment.agreement.customer.lastName = vm.customer.customerLastName;
      SharedDataSvc.creditInitialPayment.agreement.customer.firstName = vm.customer.customerFirstName;
      SharedDataSvc.creditInitialPayment.agreement.customer.login = vm.account.username;
      SharedDataSvc.creditInitialPayment.agreement.customer.email = vm.customer.customerEmailAddress;
      SharedDataSvc.creditInitialPayment.agreement.customer.phoneNumber = vm.customer.phNum;

      SharedDataSvc.creditInitialPayment.agreement.engagement.id = vm.engagement.engagementId;
      SharedDataSvc.creditInitialPayment.agreement.engagement.hoCustName = vm.customer.customerFirstName + ' ' + vm.customer.customerLastName;

      SharedDataSvc.creditInitialPayment.payment.billingCity = vm.account.initialPayment.creditCard.billingCity;
      SharedDataSvc.creditInitialPayment.payment.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
      SharedDataSvc.creditInitialPayment.payment.cvv = vm.account.initialPayment.creditCard.securityCode;
      SharedDataSvc.creditInitialPayment.payment.creditCardNumber = creditCardNumber;
      SharedDataSvc.creditInitialPayment.payment.cardType = /*vm.account.initialPayment.creditCard.cardType;*/getCardType(vm.cardType);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.creditInitialPayment.payment.billingLastName = vm.customer.customerLastName;
      SharedDataSvc.creditInitialPayment.payment.expirationDate = /*formatCreditCardDate(vm.account.expDate);*/vm.expDate; //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.creditInitialPayment.payment.billingState = vm.account.initialPayment.creditCard.billingState;
      SharedDataSvc.creditInitialPayment.payment.billingAddressLine = vm.account.initialPayment.creditCard.billingAddress1 + ' ' + vm.account.initialPayment.creditCard.billingAddress2;
      SharedDataSvc.creditInitialPayment.payment.paymentAmountInCents = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.creditInitialPayment.payment.billingFirstName = vm.customer.customerFirstName;
      SharedDataSvc.creditInitialPayment.paymentType = "Credit"
    //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT     
	  //Start: VAN-616 - Low Down Initial Payment
	  /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
            
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.creditInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }
      }else {
      	if(SharedDataSvc.creditInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.creditInitialPayment.promotionDetails;
      }

      //VAN-1395-eComplish iFrame implementation - Starts
      /*if(vm.tokenizedPAN != ""){
		SharedDataSvc.creditInitialPayment.tokenizedPAN = vm.tokenizedPAN;*/
      if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    	  SharedDataSvc.creditInitialPayment.paymentId = vm.paymentId;
    	  SharedDataSvc.creditInitialPayment.lastFour = vm.lastFourDigit;
    	  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    	  SharedDataSvc.creditInitialPayment.payment.creditCardNumber = '';
//  	  SharedDataSvc.creditInitialPayment.payment.expirationDate = '';//Commented as part of VAN-3330
    	  SharedDataSvc.creditInitialPayment.payment.cvv = '';
      }
      //VAN-1395-eComplish iFrame implementation - Ends

      console.log('CREDIT CARD INITIAL', SharedDataSvc.creditInitialPayment);
    }



    function setBankInitialPayment() {
      console.log("[EPay.setBankInitialPayment] vm.engagement: ", vm.engagement);
      console.log("[EPay.setBankInitialPayment] vm.customer: ", vm.customer);

      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber = vm.account.initialPayment.routingNumber;
      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber = vm.account.initialPayment.accountNumber;
      SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType = vm.account.initialPayment.accountType;
      SharedDataSvc.bankPaymentInitialPayment.lastFour = SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      vm.lastFourDigit = SharedDataSvc.bankPaymentInitialPayment.lastFour;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      SharedDataSvc.bankPaymentInitialPayment.paymentType = "ACH";

      SharedDataSvc.bankPaymentInitialPayment.agreement.id = vm.engagement.agreementHtId;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.lastName = vm.customer.customerLastName;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.firstName = vm.customer.customerFirstName;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.login = vm.account.username;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.email = vm.customer.customerEmailAddress;
      SharedDataSvc.bankPaymentInitialPayment.agreement.customer.phoneNumber = vm.customer.phNum;

      SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.id = vm.engagement.engagementId;
      SharedDataSvc.bankPaymentInitialPayment.agreement.engagement.hoCustName = vm.customer.customerFirstName + ' ' + vm.customer.customerLastName;

      SharedDataSvc.bankPaymentInitialPayment.payment.billingCity = vm.account.initialPayment.creditCard.billingCity;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
      SharedDataSvc.bankPaymentInitialPayment.payment.cvv = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.creditCardNumber = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.expirationDate = null;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingLastName = vm.customer.customerLastName;
      
      SharedDataSvc.bankPaymentInitialPayment.payment.billingState = vm.account.initialPayment.creditCard.billingState;
      SharedDataSvc.bankPaymentInitialPayment.payment.billingAddressLine = vm.account.initialPayment.creditCard.billingAddress1 + ' ' + vm.account.initialPayment.creditCard.billingAddress2;
      SharedDataSvc.bankPaymentInitialPayment.payment.paymentAmountInCents = formatCash(vm.formattedInitialPayment);
      SharedDataSvc.bankPaymentInitialPayment.payment.billingFirstName = vm.customer.customerFirstName;
      //Commented as part of VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
      //Start: VAN-616 - Low Down Initial Payment
      /*if(vm.storeInfo.lowInitialPaymentEnabled && vm.storeInfo.noCalcPromos){
    	  if(vm.account.paymentCredit === 'credit' || vm.account.paymentCredit === 'bank'){
	    	  vm.promoCode = vm.storeInfo.noCalcPromos[0].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[0].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[0].payment;
    	  }else{
    		  vm.promoCode = vm.storeInfo.noCalcPromos[1].code;
	    	  vm.promoDesc = vm.storeInfo.noCalcPromos[1].desc;
	    	  vm.promoAmount = vm.storeInfo.noCalcPromos[1].payment;
    	  }
      } */
      //End: VAN-616 - Low Down Initial Payment
            
      if(vm.promoPaymentOfferSelected) {
        SharedDataSvc.bankPaymentInitialPayment.promotionDetails = {
          "agreemnentNumber": vm.engagement.agreementHtId,
          "customerId": vm.engagement.customerId.toString(),
          "engagementId": vm.engagementId,
          "promoCode": vm.promoSelected?vm.promoSelected.code:vm.selectedPromo.code,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoDesc": vm.promoSelected?vm.promoSelected.desc:vm.selectedPromo.desc,//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "promoAmount": vm.promoSelected?formatCash(vm.promoSelected.promoAmount):formatCash(vm.selectedPromo.payment),//VAN-873-Incorrect Promotion details saved in VAN DB and passed to HT
          "totalDue": SharedDataSvc.initialPaymentValue, 
          "passPhrase": SharedDataSvc.paymentPassphraseUsed,
          "promoAmtUsed": formatCash(vm.formattedInitialPayment)
        }
      }else {
      	if(SharedDataSvc.bankPaymentInitialPayment.promotionDetails !== undefined)
      		delete  SharedDataSvc.bankPaymentInitialPayment.promotionDetails;
      }
      ////Commented as part of VAN-3330
      //VAN-1395-eComplish iFrame implementation - Starts
      /*if(vm.tokenizedPAN != ""){
    	  SharedDataSvc.bankPaymentInitialPayment.tokenizedPAN = vm.tokenizedPAN;
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankRoutingNumber = '';
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountNumber = '';
    	  SharedDataSvc.bankPaymentInitialPayment.bankingInfo.bankAccountType = '';
      }*/
      //VAN-1395-eComplish iFrame implementation - Ends
      
      console.log('CREDIT CARD INITIAL', SharedDataSvc.bankPaymentInitialPayment);
    }

    function submitPayment() {
    	if(vm.account.paymentCredit === "credit") {
    		$log.log('credit payment');
    		setCreditInitialPayment();
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    		vm.account.autoPayment.existingBankOrCredit = true;
    		if(!vm.storeInfo.isManned || vm.storeInfo.operationMode ==='HYBRID' || (vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired') && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1424-AWP- Skip autopay section getting skipped while applying promo- $49 payment
    			$scope.newProcess = true;
    			setDirectAutoPayCreditCard();
//    			processAutopay(SharedDataSvc.autoPaymentData);
    			processInitialAndAutoPay(SharedDataSvc.autoPaymentData);//VAN-2012-VAN-AutoPay Issue
    		}
    		else{
    			processCreditPayment(SharedDataSvc.creditInitialPayment);
    		}
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
    	} else if(vm.account.paymentCredit === "bank") {
    		$log.log('OCH payment');
    		setBankInitialPayment();
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
    		vm.account.autoPayment.existingBankOrCredit = false;
    		if(!vm.storeInfo.isManned ||  vm.storeInfo.operationMode ==='HYBRID' || (vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired') && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1424-AWP- Skip autopay section getting skipped while applying promo- $49 payment
    			$scope.newProcess = true;
    			setDirectAutoPayCreditCard();
//    			processAutopay();
    			processInitialAndAutoPay(SharedDataSvc.autoPaymentData);//VAN-2012-VAN-AutoPay Issue
    		}
    		else{
    			processBankAccountPayment(SharedDataSvc.bankPaymentInitialPayment);
    		}
    		//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

    	} else {
    		$log.log('cash payment');
    		setCashInitialPayment();
    		showModal('cashPaymentEmployeePin');
    	}
    }

    function formatCreditCardDate() {
      var date = vm.account.initialPayment.creditCard.expDate;
      if(date.indexOf('/') > -1) {
        date = date.split('/');
        return date[0] + '20' + date[1];
      } else {
        if(date.length === 4) {
          return date;
        }
      }
    }

    function formatCash(amount) {
      var cashString = amount.toString();
      if(cashString.indexOf('.') < 0) {
        cashString = cashString + '.00';
      }
      cashString = cashString.replace('.', '');
      return cashString;
    }

    function setEmployeeId(employeeId) {
      SharedDataSvc.cashInitialPayment.empin = employeeId;
    }

    function processCashPayment() {
      $log.log('process cash payment', SharedDataSvc.cashInitialPayment);
      if(!vm.cashPaymentToLow) {
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.processCashPayment(SharedDataSvc.cashInitialPayment)
          .then(function(response){
            $log.log('Successfully processed cash payment', response);
            vm.modal.dismiss();
            if(response.status === 200) {
              vm.modal.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-success';
              vm.initialPaymentMessage = vm.paymentMessageSuccess;
              /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
              vm.cashPaymentSuccess = true;
              /*VAN 762 - AWP - Submit payment is not working when 49 - cash and ANOW promo is selected*/
              if(vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.promoPaymentCheckboxValue){
            	  vm.initialPaymentPaid = false;
                 // processCashAutoPayment();
            	  saveAutopay();//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
              }else{
            	  vm.initialPaymentPaid = true;
              }
                
             
        	/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
              //VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
              if(vm.zeroPromo){
            	  vm.autopayIframe = true;
//            	  vm.enableBank = "N";
//            	  ecomplishRequest();
            	  configIframe();//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
              }
              //VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
              else{//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
            	  vm.autopayIframe = true;
            	  vm.showIframe = true;
            	  configIframe();
              }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
            }
            if(response.status === 400) {
              vm.modal.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.paymentMessageFailure;
              vm.initialPaymentPaid = false;
              vm.employeeId = '';
            }
          }, function(response){
            $log.log('Error processing cash payment', response);
            vm.modal.dismiss();
            vm.employeeId = '';
            if(response.data.errorData.status_code === "ENG-114-400") {
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.promoNotAvailable;
              vm.initialPaymentPaid = false;
            }else if(response.data.errorData.status_code === "ENG-T1600-400"){
              //PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
	          vm.initialPaymentStatus = true;
	          vm.initialPaymentErrorClass = 'alert-danger';
	          vm.initialPaymentMessage = vm.internalRecordLocked;
	          vm.initialPaymentPaid = false;
            }else {
              var status_message = JSON.parse(response.data.errorData.status_message);
              console.log('CASH PAYMENT RESPONSE', response);
              console.log('STATUS', status_message);
              if(status_message.processingErrors[0].errorCode.indexOf('T2202') > -1) {
                vm.pinAttempts = vm.pinAttempts + 1;
                vm.initialPaymentStatus = false;
                vm.initialPaymentErrorClass = '';
                vm.employeeId = '';
                console.log('ATTEMPTS', vm.pinAttempts);
                if(vm.pinAttempts === 3) {
                  vm.pinAttempts = 0;
                  showModal('tooManyPinAttempts');
                } else {
                  vm.employeePinError = true;
                  showModal('cashPaymentEmployeePin');
                }
              } else {
                vm.initialPaymentStatus = true;
                vm.initialPaymentErrorClass = 'alert-danger';
                vm.initialPaymentMessage = vm.paymentMessageFailure;
              }
            }
          });
      }
    }

    function addDecimalToInitialPayment(amount) {
      var len = amount.length,
          decIndex = len - 2,
          array = amount.split('');

	  //Start - low down feature
      if(decIndex>0){
    	  array[decIndex] = '.' + array[decIndex];
    	  amount = array.join('');
      }else{
//    	  amount = Number(amount).toFixed(2);
    	  amount = '0.'+ amount;//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
      }
      //End - low down feature
      return amount;
    }

    function checkForInitialDecimal(payment) {
      if(payment.indexOf('.') > -1) {
        payment = payment.replace('.', '');
      }
      return payment;
    }

    function getDirectAutoPayCreditCardDate(date){
    	var date = date;
    	if(date && date.indexOf('20') > -1) {
        	var month = date.substring(0, 2);
        	var year = date.substring(2);
        	return { 'month' : month, 'year' : year };
      	}
    }

    function getCreditCardDate() {
      var date = vm.account.autoPayment.creditCard.expDate;

      $log.log('CC DATE', date);

      if(date && date.indexOf('/') > -1) {
        date = date.split('/');
        return { 'month' : date[0], 'year' : '20' + date[1] };
      }
    }

    function getAutoPayType() {
      if(vm.account.autoPayment.existingBankOrCredit === 'skip') {
        return 'skip';
      } else if(!vm.account.autoPayment.existingBankOrCredit) {
        return 'ACH';
      } else {
        return 'Credit';
      }
    }

    function sameCardAsInitial() {
      if(vm.copyPaymentInfo) {
        if(vm.account.paymentCredit === 'credit') {
          vm.account.autoPayment.creditCard.cc1 = vm.account.initialPayment.creditCard.cc1;
          vm.account.autoPayment.creditCard.cc2 = vm.account.initialPayment.creditCard.cc2;
          vm.account.autoPayment.creditCard.cc3 = vm.account.initialPayment.creditCard.cc3;
          vm.account.autoPayment.creditCard.cc4 = vm.account.initialPayment.creditCard.cc4;
          vm.account.autoPayment.creditCard.cardType = vm.account.initialPayment.creditCard.cardType;
          vm.account.autoPayment.creditCard.nameOnCard = vm.account.initialPayment.creditCard.nameOnCard;
          vm.account.autoPayment.creditCard.nameOnACard = vm.account.initialPayment.creditCard.nameOnCard;
          vm.account.autoPayment.creditCard.expDate = vm.account.initialPayment.creditCard.expDate;
          vm.account.autoPayment.securityCode = vm.account.initialPayment.creditCard.securityCode; 

          vm.invalidAutoCredit = false;
          /*Defect - 28717 -Starts*/
          /*$scope.ePayAutoCredit.cc1.$setViewValue(vm.account.initialPayment.creditCard.cc1);
          $scope.ePayAutoCredit.cc2.$setViewValue(vm.account.initialPayment.creditCard.cc2);
          $scope.ePayAutoCredit.cc3.$setViewValue(vm.account.initialPayment.creditCard.cc3);
          $scope.ePayAutoCredit.cc4.$setViewValue(vm.account.initialPayment.creditCard.cc4);*/
          /*Defect - 28717 -Ends*/
                   
          $scope.ePayAutoCredit.cc1.$setValidity('cc1', true);
          $scope.ePayAutoCredit.cc2.$setValidity('cc2', true);
          $scope.ePayAutoCredit.cc3.$setValidity('cc3', true);
          $scope.ePayAutoCredit.cc4.$setValidity('cc4', true);
       }

        if(vm.account.paymentCredit === 'bank') {
          vm.account.autoPayment.routingNumber = vm.account.initialPayment.routingNumber;
          vm.account.autoPayment.accountNumber = vm.account.initialPayment.accountNumber;
          vm.account.autoPayment.accountType = vm.account.initialPayment.accountType;
        }

        vm.account.autoPayment.creditCard.billingAddress1 = vm.account.initialPayment.creditCard.billingAddress1;
        vm.account.autoPayment.creditCard.billingAddress2 = vm.account.initialPayment.creditCard.billingAddress2;
        vm.account.autoPayment.creditCard.billingCity = vm.account.initialPayment.creditCard.billingCity;
        vm.account.autoPayment.creditCard.billingState = vm.account.initialPayment.creditCard.billingState;
        vm.account.autoPayment.creditCard.billingZipCode = vm.account.initialPayment.creditCard.billingZipCode;
        vm.account.autoPayment.creditCard.billingEmail = vm.account.initialPayment.creditCard.billingEmail;
        vm.account.autoPayment.creditCard.billingPhone = vm.account.initialPayment.creditCard.billingPhone;
      } else {
        vm.account.autoPayment.creditCard.cc1 = '';
        vm.account.autoPayment.creditCard.cc2 = '';
        vm.account.autoPayment.creditCard.cc3 = '';
        vm.account.autoPayment.creditCard.cc4 = '';
        vm.account.autoPayment.creditCard.cardType = '';
        vm.account.autoPayment.creditCard.nameOnCard = '';
        vm.account.autoPayment.creditCard.nameOnACard = '';
        vm.account.autoPayment.creditCard.expDate = '';
        vm.account.autoPayment.securityCode = '';
        vm.account.autoPayment.creditCard.billingAddress1 = '';
        vm.account.autoPayment.creditCard.billingAddress2 = '';
        vm.account.autoPayment.creditCard.billingCity = '';
        vm.account.autoPayment.creditCard.billingState = '';
        vm.account.autoPayment.creditCard.billingZipCode = '';
        vm.account.autoPayment.creditCard.billingEmail = '';
        vm.account.autoPayment.creditCard.billingPhone = '';
        vm.account.autoPayment.routingNumber = '';
        vm.account.autoPayment.accountNumber = '';
        vm.account.autoPayment.accountType = undefined;
      }
    }

    function getAgreementsMonthly() {
      return addDecimalToInitialPayment(String(vm.customerFind.agreement.monthlyRate));
    }

    function processBankAccountPayment() {
      $log.log('process bank account payment', SharedDataSvc.bankPaymentInitialPayment);
      $scope.viewLoading = true;
      showModal('Processing', 'processing-modal');
      paymentFactory.processCreditPayment(SharedDataSvc.bankPaymentInitialPayment)
        .then(function(response) {
          $log.log('Successfully processed bank account payment', response);
          vm.bankAccountNoLastFourDigit = vm.account.initialPayment.accountNumber.substr(-4);

          if(response.status === 200) {
        	$scope.viewLoading = false;//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
            modalService.dismiss();
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-success';
            vm.initialPaymentMessage = vm.paymentMessageSuccess;
            vm.initialPaymentPaid = true;
            vm.copyPayPageInfo = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
            if( vm.autopayWithEcomplish && vm.initialPaymentPaid){
            	saveAutopay();
            }
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

            //Commented as part of VAN-815
            //low down payment feature
            /*
            	if(!vm.storeInfo.isManned || $rootScope.isHybrid || (vm.storeInfo.isManned && vm.storeInfo.lowInitialPaymentEnabled &&
            			vm.initialPaymentSetUp && vm.account.paymentInitialValSelected === 'AutoPayRequired')){
            		vm.account.autoPayment.existingBankOrCredit = false;
            		vm.setDirectAutoPayCreditCard();
            	} *///Commented as part of VAN-3330
            //VAN-1395-eComplish iFrame implementation - Starts
            /*vm.copyPayPageInfo = true;
            var iframeVal = iFrame;
            iframeVal.parentNode.removeChild(iframeVal);*/
            //VAN-1395-eComplish iFrame implementation - Ends
          
            if(response.status === 400) {
              modalService.dismiss();
              vm.paymentErrorCode = '';
              vm.initialPaymentStatus = true;
              vm.initialPaymentErrorClass = 'alert-danger';
              vm.initialPaymentMessage = vm.paymentMessageFailure;
              vm.initialPaymentPaid = false;
            }

          }//Commented as part of VAN-815
          //modalService.dismiss();
        }, function(response) {
          $log.log('Error processing bank account payment', response);
          modalService.dismiss();
          vm.initialPaymentStatus = true;
          vm.initialPaymentPaid = false;
          vm.initialPaymentErrorClass = 'alert-danger';
          
          if(response.data.errorData.status_code == "ENG-A1012-400") {
          	vm.invalidBankDetailCount ++;
          	if(vm.invalidBankDetailCount == 2){
          		vm.initialPaymentMessage = vm.invalidBankDetailsType;
          		vm.storeInfo.allowAch = false;
          		vm.initialBankAccount("credit");
          		vm.account.paymentCredit = "credit";
          	}else{
          		vm.initialPaymentMessage = vm.invalidBankDetail;
          	}
    
          } else if(response.data.errorData.status_code == "ENG-114-400") {
          	vm.initialPaymentMessage = vm.promoNotAvailable;
          } else if(response.data.errorData.status_code == "ENG-T1600-400"){
        	//PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
        	vm.initialPaymentMessage = vm.internalRecordLocked;
          } else{
          	vm.initialPaymentMessage = vm.noTransactionProcess;
          }
        });

    }

    function processCreditPayment() {
      $log.log('process credit payment', SharedDataSvc.creditInitialPayment);
      $scope.viewLoading = true;
      showModal('Processing', 'processing-modal');
      paymentFactory.processCreditPayment(SharedDataSvc.creditInitialPayment)
        .then(function(response) {
          $log.log('Successfully processed credit payment', response);
          
          //vm.copyPaymentInfo = true;
          //sameCardAsInitial();

          if(response.status === 200) {
            modalService.dismiss();
            $scope.viewLoading = false;//VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-success';
            vm.initialPaymentMessage = vm.paymentMessageSuccess;
            vm.initialPaymentPaid = true;
            vm.copyPayPageInfo = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
            if( vm.autopayWithEcomplish && vm.initialPaymentPaid){
            	saveAutopay();
            }
            //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

            //Commented as part of VAN-815
            //low down payment feature
            /*if(!vm.storeInfo.isManned || $rootScope.isHybrid || (vm.storeInfo.isManned && vm.storeInfo.lowInitialPaymentEnabled &&
            		vm.initialPaymentSetUp && vm.account.paymentInitialValSelected === 'AutoPayRequired') ){
            	vm.setDirectAutoPayCreditCard();
            }*/
            //VAN-1395-eComplish iFrame implementation - Starts
            vm.copyPayPageInfo = true;
            var iframeVal = iFrame;
            iframeVal.parentNode.removeChild(iframeVal);
            //VAN-1395-eComplish iFrame implementation - Ends
          }

          if(response.status === 400) {
            modalService.dismiss();
            vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.paymentMessageFailure;
            vm.initialPaymentPaid = false;
          }
          //Commented as part of VAN-815
          //modalService.dismiss();
        }, function(response) {
          $log.log('Error processing credit payment', response);
          modalService.dismiss();
          console.log('error code', response.data.errorData.status_code);
          if(response.data.errorData.status_code === 'ENG-E3004-500') {
            console.log('87 error');
            vm.paymentErrorCode = '87';
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentStatus = true;
            if(vm.storeInfo.isManned) {
              vm.initialPaymentMessage = vm.transactionDesc1 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc2;//AC-28614 Changes
            } else {
              vm.initialPaymentMessage = vm.transactionDesc3 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc2;//AC-28614 Changes


            }
          } else if(response.data.errorData.status_code === 'ENG-S5001-500' || response.data.errorData.status_code === 'ENG-S4001-500') {
            vm.paymentErrorCode = '88';
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentStatus = true;
            if(vm.storeInfo.isManned) {
              vm.initialPaymentMessage = vm.transactionDesc4 ;
            } else {
              vm.initialPaymentMessage = vm.transactionDesc5 + SharedDataSvc.globalVars.storeSetup.vipPhoneNumber + vm.transactionDesc6;//AC-28614 Changes
            }
          } else if(response.data.errorData.status_code === "ENG-A1010-500") {
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.transactionDesc7;
          } else if(response.data.errorData.status_code === "ENG-114-400") {
          	vm.paymentErrorCode = '';
          	vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.promoNotAvailable;
          } else if(response.data.errorData.status_code === "ENG-T1600-400") {
        	//PRB0042786 - VAN payment error - This promo not available. Please select another - customer record locked out return error code 14 during initial payment
        	vm.paymentErrorCode = '';
            vm.initialPaymentStatus = true;
            vm.initialPaymentErrorClass = 'alert-danger';
            vm.initialPaymentMessage = vm.internalRecordLocked;
          } else {
             // vm.showModal('creditCardDeclined');
             vm.initialPaymentErrorClass = 'alert-danger';
             vm.initialPaymentStatus = true;
             vm.paymentErrorCode = '757';
             vm.initialPaymentMessage = vm.transactionDesc8;
          }

          // vm.statusMessage = true;
          // vm.alertClass = 'alert-danger';
          // vm.message = 'Payment was not successful';
          // $scope.viewLoading = false;
        });



    }

    function epayLogin(epayData) {
      setEpayLogin();
      console.log('ePay data', epayData);
      if(vm.account.username && vm.account.password) {
        console.log('no epay login errors');
        $scope.viewLoading = true;
        showModal('Processing', 'processing-modal');
        paymentFactory.epayLogin(epayData)
          .then(function(response) {
            // Handle 400 error response
            if(response.status !== 200) {
              $scope.viewLoading = false;
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-danger';
              vm.message = "epay_label_transactionDesc9";
              vm.message2 = '';
            }
            if(response.status === 200) {
              $log.log('SUCCESSFUL EPAY LOGIN', response);
              modalService.dismiss();
              vm.statusMessage = true;
              vm.alertClass = 'alert-success';
              vm.message = "epay_label_transactionDesc10"; /* Successfully logged in to ePay account  */
              vm.message2 = '';
              vm.account.newOrExistingAcct = false;
              vm.account.loggedIntoEpay = true;
            }
          }, function(response) {
            $log.log('UNABLE TO EPAY LOGIN', response);
            vm.statusMessage = true;
            vm.alertClass = 'alert-danger';
            vm.message = "epay_label_transactionDesc9";
            vm.message2 = '';
            modalService.dismiss();
          });
      }
    }

    function setAutoPayData() {
      var clubStatus = typeof vm.customerFind.VanEngagement.clubStatus === 'number' ? vm.customerFind.VanEngagement.clubStatus : Number(vm.customerFind.VanEngagement.clubStatus);
      SharedDataSvc.autoPaymentData.engagementId = typeof vm.engagementId !== 'number' ? Number(vm.engagementId) : vm.engagementId;
      SharedDataSvc.autoPaymentData.customerPassword = vm.account.password;
      SharedDataSvc.autoPaymentData.customerLastName = vm.customerFind.VanEngagement.customer.lName;
      SharedDataSvc.autoPaymentData.customerFirstName = vm.customerFind.VanEngagement.customer.fName;
      SharedDataSvc.autoPaymentData.customerLogin = vm.account.username;
      SharedDataSvc.autoPaymentData.emailAddress = vm.customerFind.VanEngagement.customer.email;

      SharedDataSvc.autoPaymentData.customerPhone = vm.customerFind.VanEngagement.customer.phNum;
      SharedDataSvc.autoPaymentData.customerAddress = vm.customerFind.VanEngagement.customer.addressLine1;
      SharedDataSvc.autoPaymentData.customerState = vm.customerFind.VanEngagement.customer.state;
      SharedDataSvc.autoPaymentData.customerZip = vm.customerFind.VanEngagement.customer.addressZipcode;
      SharedDataSvc.autoPaymentData.customerCity = vm.customerFind.VanEngagement.customer.addressCity;
      SharedDataSvc.autoPaymentData.paymentType = getAutoPayType();

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      if(getAutoPayType() === 'Credit') {
        SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';/*vm.account.autoPayment.creditCard.cc1 + vm.account.autoPayment.creditCard.cc2 + vm.account.autoPayment.creditCard.cc3 + vm.account.autoPayment.creditCard.cc4;*///VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        /*if(vm.account.autoPayment.creditCard.expDate != ""){//VAN-1395-eComplish iFrame implementation
        SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getCreditCardDate().month;
        SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getCreditCardDate().year;}*/
        SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = /*vm.account.autoPayment.securityCode;*/'';
        //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
        SharedDataSvc.autoPaymentData.creditCardInfo.cardType = getCardType(vm.cardType);
        if(vm.account.initialPayment.creditCard.expDate!=""){
        	SharedDataSvc.creditInitialPayment.payment.expirationDate = formatCreditCardDate();
        	SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).month;
        	SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = vm.getDirectAutoPayCreditCardDate(SharedDataSvc.creditInitialPayment.payment.expirationDate).year;
        }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
      }

      SharedDataSvc.autoPaymentData.agreementList[0].agreementNumber = vm.customerFind.agreement.raNumber;
      SharedDataSvc.autoPaymentData.agreementList[0].agreementType = "Agreement";
      SharedDataSvc.autoPaymentData.agreementList[0].monthlyAmount = getAgreementsMonthly();

      console.log('CLUB STATUS', clubStatus);
      if(clubStatus === 1) {
          if(!SharedDataSvc.autoPaymentData.agreementList[1]) {
              var benefits = {'agreementNumber': undefined, 'agreementType': 'Benefits', 'monthlyAmount': undefined};
              benefits.agreementNumber = vm.customerFind.agreement.ClubMemberId;
              benefits.agreementType = "Benefits";
              benefits.monthlyAmount = "0.0";

              SharedDataSvc.autoPaymentData.agreementList.push(benefits);
          }else {
              SharedDataSvc.autoPaymentData.agreementList[1].agreementNumber = vm.customerFind.agreement.ClubMemberId;
              SharedDataSvc.autoPaymentData.agreementList[1].agreementType = "Benefits";
              SharedDataSvc.autoPaymentData.agreementList[1].monthlyAmount = "0.0";
          }
      }

      if(clubStatus !== 1) {
        if(SharedDataSvc.autoPaymentData.agreementList.length > 1) {
          SharedDataSvc.autoPaymentData.agreementList.pop();
        }
      }

      if(getAutoPayType() === 'ACH') {
        SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = vm.account.autoPayment.routingNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = vm.account.autoPayment.accountNumber;
        SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = vm.account.autoPayment.accountType;
        SharedDataSvc.autoPaymentData.lastFourDigit = vm.account.autoPayment.accountNumber.substr(-4);//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
//		vm.lastFourDigit = SharedDataSvc.autoPaymentData.lastFourDigit;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        SharedDataSvc.autoPaymentData.creditCardInfo = {};
      }
      
      //VAN-1395-eComplish iFrame implementation - Starts
//      if(vm.tokenizedPAN != ""){
//		SharedDataSvc.autoPaymentData.tokenizedPAN = vm.tokenizedPAN;
		if(vm.isPayRegIdGenerated){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		SharedDataSvc.autoPaymentData.paymentTokenId = vm.paymentId;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		SharedDataSvc.autoPaymentData.lastFourDigit = vm.lastFourDigit;
    	  /*if(getAutoPayType() === 'ACH'){
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankRoutingNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountNumber = '';
    		  SharedDataSvc.autoPaymentData.bankingInfo.bankAccountType = '';
    	  }
    	  else{
    		  SharedDataSvc.autoPaymentData.creditCardInfo.creditCardNumber = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cardType = ''; 
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationMonth = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.expirationYear = '';
    		  SharedDataSvc.autoPaymentData.creditCardInfo.cvvCode = '';
    	  }*/
      }
      //VAN-1395-eComplish iFrame implementation - Ends
    }

    function deliveryModalOptions(engagement, deliveryPart){
        deliveryPart = deliveryPart || '';
        return {
            templateUrl: 'partials/modal_invoiceAndDelivery' + deliveryPart + '.html',
            backdrop: 'static',
            controller: 'delivery',
            controllerAs: 'delivery',
            scope: $scope
        }
    }

    //address confirm modal for schedule delivery
    function showDeliveryConfirmation(engagement){
        var deliveryConfirmModal = modalService.open(deliveryModalOptions(engagement, 'Confirm'));
        deliveryConfirmModal.result.then(function(result){
            if(result && result.status === 'confirm'){
                showModalDeliveryPass('scheduleDeliveryPass');
                console.log('[Schedule Delivery Modal Pass Check] Success:', result.status);
            }
        },
            //dismiss
            function(result){
                if(result && result.status === 'declined'){
                    modalService.open({
                       templateUrl: 'partials/modal_invoiceAndDeliveryFailed.html',
                        scope: $scope
                    });
                }
            }
        )
    }

      //show schedule delivery associate pass check modal
      function showModalDeliveryPass(name, windowClass){
          windowClass = windowClass || '';
          SharedDataSvc.globalVars.navigateToDashboard = true;
          vm.myDeliveryConfirmPassModal = modalService.open({
              templateUrl: '/partials/modal_' + name + '.html',
              backdrop: 'static',
              windowClass: windowClass,
              controller: 'delivery',
              controllerAs: 'delivery',
              scope: $scope
          });
          vm.myDeliveryConfirmPassModal
              .result.then(function (result) {
              navigateTo('dashboard');
          });
      }
      
      //skip autopay click event function
      function submitSkipAutoPay(){
    	  if(vm.storeInfo.immediateInvoicing || (vm.storeInfo.immediateInvoicing === undefined)) {
            if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
              var showVerifyInfoAlertModal = modalService.open({
                templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
                backdrop: 'static',
                show:true,
                scope: $scope
              });
              showVerifyInfoAlertModal.result.then(function(){
                navigateTo('dashboard');
              });
            } else {
                vm.myDeliveryModal = modalService.open(deliveryModalOptions((vm.engagement)));
                vm.myDeliveryModal.result.then(function(result){
                    if(result && result.status === 'confirm'){
                        showDeliveryConfirmation(result.data);
                        console.log('[Schedule Delivery Address Check Modal] Success');
                    } else if(result && result.status === 'pickup'){
                        showModalDeliveryPass('scheduleDeliveryPass');
                        console.log('[Schedule Pickup Modal Pass Check] Success:', result.status);
                    }
                }, function(result){
                    console.log('[Delivery Modal dismiss]');
                    updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
                });
              }
          } else {
            $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
            $rootScope.initialAmountPaid = vm.formattedInitialPayment;
            $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
            var vccDetails = modalService.open({
              templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
              backdrop: 'static',
              controller: 'delivery',
              controllerAs: 'delivery',
              show:true
            });
            vccDetails.result.then(function(){
              updateEngagement(SharedDataSvc.currentEngagementData);
            });
          }
      }

      function updateEngagement(engagement, status){
        showModal('Processing', 'processing-modal');
        engagement.salesStatus = "Complete";
        if(status === "updateDeliveryStatus") {
          engagement.deliveryStatus = "Complete";
        }
        updateEngagementItemFactory.putData(engagement)
        .then(function(response) {
          navigateTo('dashboard');
          console.log('[epay.updateEngagement] successfully updated engagement', response);
         }, function(error){
          console.log('[epay.updateEngagement] failed updating engagement delivery info', error);
        });
      }

      function submitAutoPay() {
           $log.log('submit auto payment');
           if( vm.unmannedAutoPayDo !== 'true') {
               console.log("Direct Autopay Set data called");
               setAutoPayData();
           }
          showModal('Processing', 'processing-modal');
          //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
          if(vm.account.paymentCredit === 'cash' && vm.account.paymentInitialValSelected === 'AutoPayRequired' && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1438 AWP- AUTOPAYSTATUS set as null for customers where $0 promo is applied and CASH selected as payment method.($49 option)
        	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
        	  SharedDataSvc.autoPaymentData.noHTEnrollAutoPay = 'Y';//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
//        	  autopayForCash(); 
          }/*else{//Commented as part of VAN-3330
        	  VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts
        	  processCashAutoPayment();
        	  VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends
          }*///VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
          else if(vm.account.paymentCredit === 'cash' && vm.account.paymentInitialValSelected != 'AutoPayRequired'){
        	  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
          }//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP  - End
          autopayForCash(); //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends

      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
      function processCashAutoPayment(){
    	  if(vm.storeInfo.lowInitialPaymentEnabled && 
        		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess  && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1440 AWP- Getting Error 789 while setting up Autopay for Cash payment
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay = "LDP_Ecomplish";
          }else if(vm.storeInfo.lowInitialPaymentEnabled && 
        		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess && (vm.promoSelected === undefined || vm.promoSelected === "")){//VAN-1440 AWP- Getting Error 789 while setting up Autopay for Cash payment
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay = "LDP_Autopay";
          }else{
        	  SharedDataSvc.autoPaymentData.enableLDPAutopay="Autopay";
          }
    	  
    	  $log.log('data for autopay', SharedDataSvc.autoPaymentData);
    	  paymentFactory.processAutoPayment(SharedDataSvc.autoPaymentData)
              .then(function(response) {
                  console.log('autopay success!', response);
                  vm.autoPayStatus = false;//VAN-769 - AWP - The error message for invalid bank/CC details appears on the screen even after successful Autopay setup and initial payment
                  vm.autoPayClass = "";//VAN-769 - AWP - The error message for invalid bank/CC details appears on the screen even after successful Autopay setup and initial payment
                  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
                  if(vm.storeInfo.lowInitialPaymentEnabled && 
                		  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash'){
                  vm.valAutoPay = true;
                  }
                  //VAN-616 - VAN - Low Down Initial Payment
                  if((vm.storeInfo.isManned && !$rootScope.isHybrid && 
                		  (!vm.storeInfo.lowInitialPaymentEnabled ||  (vm.storeInfo.lowInitialPaymentEnabled && (vm.promoSelected || vm.account.paymentInitialValSelected === 'WithoutAutoPay' 
                			  || (vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess))) ))
                			|| (vm.storeInfo.isManned && $rootScope.isHybrid && vm.changeAutoPaymentMode)
                        			|| (!vm.storeInfo.isManned && vm.unmannedAutoPayDo != 'true')){
                  /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
                      if(vm.storeInfo.immediateInvoicing  || (vm.storeInfo.immediateInvoicing === undefined)) {
                        if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
                          var showVerifyInfoAlertModal = modalService.open({
                            templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
                            backdrop: 'static',
                            show:true,
                            scope: $scope
                          });
                          showVerifyInfoAlertModal.result.then(function(){
                            navigateTo('dashboard');
                          });
                        } else {
                        SharedDataSvc.deliveryTempId = vm.engagement.engagementId;
                        SharedDataSvc.invoiceDeliveryData.engagement = vm.engagement;
                        //showModal('invoiceAndDelivery');
                        vm.myDeliveryModal = modalService.open(deliveryModalOptions(vm.engagement));
                        vm.myDeliveryModal
                            .result.then(function (result) {
                            //console.log('[Delivery Modal complete]', result);
                              if (result && result.status === 'confirm') {
                                  showDeliveryConfirmation(result.data);
                                  console.log('[Schedule Delivery Address Check Modal] success');
                              } else if(result && result.status === 'pickup') {
                                  showModalDeliveryPass('scheduleDeliveryPass');
                                  console.log('[Schedule Pickup Modal Pass Check] success:', result.status);
                              }
                        },
                            function (result) {
                                console.log('[Delivery Modal dismiss]');
                                updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
                                //navigateTo('dashboard');
                            })
                          }
                      } else {
                          $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
                          $rootScope.initialAmountPaid = vm.formattedInitialPayment;
                          $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
                          var vccDetails = modalService.open({
                            templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
                            backdrop: 'static',
                            controller: 'delivery',
                            controllerAs: 'delivery',
                            show:true
                          });
                          vccDetails.result.then(function(){
                            updateEngagement(SharedDataSvc.currentEngagementData);
                          });
                      }
                  }else {
                      if (vm.unmannedAutoPayDo == 'true') {
                          vm.autoPaySuccess = true;
                          vm.unmannedAutoPayDo = false;
                          vm.modal.dismiss();
                      }
                      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
                      else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' &&
                    		  vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess){
                    	  vm.autoPaySuccess = true;
                    	  vm.modal.dismiss();
                      } 
                      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
                      else {
                          vm.autoPaySuccess = true;
                          vm.modal.dismiss();
                          navigateTo('dashboard');
                      }
                  }
              }, function(response) {
                  console.log('autopay fail', response);
                  modalService.dismiss();
                  if(response.data.errorData.status_code === "ENG-A1010-500") {
                    vm.autoPayStatus = true;
                    vm.autoPayMessage = vm.transactionDesc7;
                    vm.autoPayClass = 'alert-danger';
                  } else {
                    vm.autoPayStatus = true;
                    vm.autoPayMessage = "There was a problem validating your payment method. Please review the information and try again or tap Exit to skip AutoPay setup. [758]";
                    vm.autoPayClass = 'alert-danger';
                  }
              });
      }
      /*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
      
      function initialBankAccount(paymentMode) {
			console.log(paymentMode);
			resetInitialPayValidation();
			resetPromoPayment();
			if(paymentMode === "credit") {
//				vm.enableBank = "N";//VAN-1395-eComplish iFrame implementation
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Starts*/	
				vm.account.initialPayment.routingNumber = '';
				vm.account.initialPayment.accountNumber = '';
				vm.account.initialPayment.accountType = '';
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Ends*/
				vm.account.paymentBankAccount = false;
				vm.showIframe = true;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			} else{
//				vm.enableBank = "Y";//VAN-1395-eComplish iFrame implementation
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Starts*/
				vm.account.initialPayment.creditCard.cc1 = '';
				vm.account.initialPayment.creditCard.cc2 = '';
				vm.account.initialPayment.creditCard.cc3 = '';
				vm.account.initialPayment.creditCard.cc4 = '';
				vm.account.initialPayment.creditCard.cardType = '';
				vm.account.initialPayment.creditCard.nameOnCard = '';
				vm.account.initialPayment.creditCard.expDate = '';
				vm.account.initialPayment.creditCard.securityCode = '';
				/*VAN-778-AWP-In payment screen, credit card details are retaining while coming back to same screen after switching to Bank account - Ends*/
				vm.account.paymentBankAccount = true; 
				vm.showIframe = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			}
			//VAN-1395-eComplish iFrame implementation - Starts      
			vm.initialPaymentStatus = false;
			vm.autopayIframe = false;
			vm.autoPaystatus = false;
			vm.account.autoPayment.existingBankOrCredit = true;
//			ecomplishRequest();
			//VAN-1395-eComplish iFrame implementation - Ends
			//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
			if(iFrame!=null && iFrame.parentNode != null){
				var iframeVal = iFrame;
				iframeVal.parentNode.removeChild(iframeVal);
			}
			if(paymentMode === "credit"){
				configIframe();
			}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP
		}
      
      function setUpInitialPaymentAmount(paymentOptionChosen){
    	  console.log(paymentOptionChosen);
    	  vm.initialPaymentSetUp = true;
          resetInitialPayValidation();
          resetPromoPayment();
          vm.account.paymentCredit = 'credit';
          //VAN 633-AWP - Bank Account fields are showing when user selects Credit Card payment options
          vm.account.paymentBankAccount = false;
          //VAN-670 - Consent Verbiage - Starts
          if(paymentOptionChosen === 'AutoPayRequired'){
        	  showModal('paymentInitial');
          }
          //VAN-670 - Consent Verbiage - Ends
      }

      function resetPromoPayment() {
			$scope.epayCreditSubmitted = false;
			vm.zeroDollarPromoInitialPayment = false;
			vm.promoPaymentCheckboxValue = false;
			vm.promoPaymentOfferSelected = false;
			vm.invalidPromoPayment = false;
			vm.showPromoOptions = false;
			vm.selectedPromoOption = undefined;
			vm.promoSelected = "";
			//Start - VAN-616 - Low Down Initial Payment
			/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
			if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.storeInfo.noCalcPromos){
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
//				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[0].payment);
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				vm.promoPaymentOfferSelected = true;
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Starts*/
			}else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'WithoutAutoPay' && vm.storeInfo.noCalcPromos){
				/*VAN-621 - Cash for initial payment in HT and autopay requirement with credit card - ($49 with cash) Ends*/
//				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.storeInfo.noCalcPromos[1].payment);
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days
				vm.promoPaymentOfferSelected = true;
			}else if(vm.account.paymentInitialValSelected === 'PIFPromo'){//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Starts
				vm.formattedInitialPayment = addDecimalToInitialPayment(vm.customerFind.VanEngagement.totalDueAmount);
				vm.promoPaymentOfferSelected = true;
			}else{//VAN-1401-Initial Payment, if Renewal date chosen is Beyond 15 days - Ends
				if(SharedDataSvc.initialPaymentValue != undefined){//VAN-670 - Consent Verbiage
					vm.formattedInitialPayment = addDecimalToInitialPayment(SharedDataSvc.initialPaymentValue)}
				/*ANOW Promo amount is retained when swiching between credit/Cash/bank  - Starts*/
				else{
					vm.formattedInitialPayment='';
				}
				/*ANOW Promo amount is retained when swiching between credit/Cash/bank  - Ends*/
			}
			//End - VAN-616 - Low Down Initial Payment

			SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
			vm.cashPaymentAmount = "";
			vm.changeDue = 0;
			vm.empPassPhrase = '';
			vm.errorMessage = '';
			//VAN-1395-eComplish iFrame implementation - Starts
			if(vm.account.paymentCredit === 'cash'){
				vm.autopayIframe = true;
//				vm.enableBank = "N";
				if(iFrame!=null && iFrame.parentNode != null){//VAN-3330- replace eComplish iframe with VANTIV iframe - AWP
				var iframeVal = iFrame;
				iframeVal.parentNode.removeChild(iframeVal);}
//				ecomplishRequest();
				//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
				if(vm.account.paymentInitialValSelected == 'AutoPayRequired'){
				vm.showIframe = true;
				configIframe();
				}//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
			}//VAN-1395-eComplish iFrame implementation - Ends
		}

      function updateInitialPayment(val, index) {
        console.log("Selected Promo Option", val);
        if(val !== undefined) {
          vm.promoPaymentOfferSelected = true;
          vm.zeroDollarPromoInitialPayment = false;
          vm.initialPaymentSetUp = false;
          vm.invalidPromoPayment = false;
          vm.cashPaymentAmount = "";
          vm.changeDue = 0;
          console.log("Selected Promo Option Offer Details", SharedDataSvc.eligiblePromos[val.id]);
          vm.promoSelected = SharedDataSvc.eligiblePromos[val.id];
          vm.formattedInitialPayment = (Number(SharedDataSvc.eligiblePromos[val.id].payment)).toFixed(2);
          SharedDataSvc.initialPaymentAmount = vm.formattedInitialPayment;
          if(Number(vm.promoSelected.payment) === 0) {
            vm.zeroDollarPromoInitialPayment = true;
            vm.account.paymentCredit = 'cash';
            vm.cashPaymentAmount = '0';
          }
        } else {
          vm.invalidPromoPayment = true;
        }

      }
      

      function promoPaymentSelectOperation(val) {
        console.log("Promo Checked Value", val);
        if(val === "true") {
          if(vm.storeInfo.isManned && !vm.storeInfo.hybridEnabled){
           showModal('confirmPasswordePay');
          }else{
            showModal('validatePassphrase');
          }
        } else{
          resetPromoPayment();
        }
      }

      function verifyEmployeePassWord(pass){
        $scope.noEmpPass = false;
        $scope.errEmpPass = false;
      if (pass) {
        var auth = vm.storeInfo.vanStoreId;
        if (auth === pass) {
            console.log('associate password passes');
            vm.modal.dismiss();
            vm.showPromoOptions = true;
        } else {
          $scope.errEmpPass = true;
          console.log('associate password failure');
        }
      } else {
        $scope.noEmpPass = true;
      }
      }

      function verifyEmployeePassPhrase(val) {
        if(val) {
          showModal('Processing', 'processing-modal');
          agreementFactory.validatePassPhrase(val, 'epay').then(function(response) {
              console.log("Passphrase validated success", response);
              vm.associatePassFail = false;
              vm.showPromoOptions = true;
              SharedDataSvc.paymentPassphraseUsed = vm.empPassPhrase;
              vm.empPassPhrase = "";
              vm.cashPaymentAmount = "";
              vm.changeDue = 0;
              vm.modal.dismiss();
            }, function (response) {
              console.log("Passphrase validated error", response);
              vm.empPassPhrase = "";
              SharedDataSvc.validatePassPhraseAttempts = SharedDataSvc.validatePassPhraseAttempts + 1;
              console.log('Validate Passphrase Attempts', SharedDataSvc.validatePassPhraseAttempts);
              if(SharedDataSvc.validatePassPhraseAttempts === 3) {
                SharedDataSvc.validatePassPhraseAttempts = 0;
                vm.modal.dismiss();
                vm.showPromoOptions = false;
                vm.invalidPassPhrase = false;
                vm.associatePassFail = false;
                vm.promoPaymentCheckboxValue = false;
                vm.empPassPhrase = "";
              } else {
                vm.errorMessage = response.data.errorData.status_message;
                vm.invalidPassPhrase = true;
                vm.associatePassFail = true;
                vm.showPromoOptions = false;
                showModal('validatePassphrase');
              }                    
          });
        }else {
          vm.associatePassFail = true;
          vm.invalidPassPhrase = false;
        }
      }

      function getCardTypeDtl() {
        paymentFactory.getCardTypeDtl()
        .then(function(response) {
          console.log("[ePay.getCardTypeDtl] success response: ", response);
          SharedDataSvc.regex = response.regex;
        }, function(error){
          console.log("[ePay.getCardTypeDtl] fail: ", error);
        });
      }

      function clearPinData() {
        vm.employeeId = '';
      }

      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Starts
      function processAutopay(){
    	  $log.log('Process Autopay With Ecomplish', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.enrollAutopayWithEcomplish(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  $log.log('Successfully processed credit payment', response);
    		  if(response.status === 200) {
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  vm.autopayWithEcomplish = true;
    			  if(vm.account.paymentCredit === "credit"){//credit payment
    				  $log.log('Payment mode - ', vm.account.paymentCredit)
    				  processCreditPayment();
    			  }
    			  if(vm.account.paymentCredit === "bank"){//banking payment
    				  $log.log('Payment mode - ', vm.account.paymentCredit)
    				  processBankAccountPayment();
    			  }

    		  }
    	  }, function(response) {
    		  $log.log('Error processing Autopay with Ecomplish', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  console.log('error code', response.data.errorData.status_code);
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    	  });
      }

      function saveAutopay() {
    	  $log.log('Post Autopay to HighTouch', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.postAutopayToHT(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  if(response.status == 200){
    			  $log.log('Successfully posted the Autopayment to HighTouch', response);
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  submitSkipAutoPay();
    		  }
    	  }, function(response) {
    		  $log.log('Error posting the Autopay to HighTouch', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  console.log('error code', response.data.errorData.status_code);
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    		  navigateTo('dashboard');
    	  });
      }
      
      //Enroll Autopay With Ecomplish for $49 promo cash payment
      function autopayForCash(){
    	  $log.log('Enroll Autopay With Ecomplish for $49 promo cash payment', SharedDataSvc.autoPaymentData);
    	  paymentFactory.enrollAutopayWithEcomplish(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  console.log('autopay success!', response);
    		  vm.autoPayStatus = false;
    		  vm.autoPayClass = "";
    		  if(vm.storeInfo.lowInitialPaymentEnabled && 
    				  vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash'){
    			  vm.valAutoPay = true;
    		  }
    		  if((vm.storeInfo.isManned && !$rootScope.isHybrid && 
    				  (!vm.storeInfo.lowInitialPaymentEnabled ||  (vm.storeInfo.lowInitialPaymentEnabled && (vm.promoSelected || vm.account.paymentInitialValSelected === 'WithoutAutoPay' 
    					  || (vm.account.paymentInitialValSelected === 'AutoPayRequired' && vm.account.paymentCredit === 'cash' && vm.cashPaymentSuccess))) ))
    					  || (vm.storeInfo.isManned && $rootScope.isHybrid && vm.changeAutoPaymentMode)
    					  || (!vm.storeInfo.isManned && vm.unmannedAutoPayDo != 'true')){
    			  if(vm.storeInfo.immediateInvoicing  || (vm.storeInfo.immediateInvoicing === undefined)) {
    				  if($scope.isSpanishStore && (vm.engagement.verifyInfoAssociateName === undefined || vm.engagement.verifyInfoAssociateName.length <= 0)) {
    					  var showVerifyInfoAlertModal = modalService.open({
    						  templateUrl: '/partials/alert_showVerifyInfoAlert.tpl.html',
    						  backdrop: 'static',
    						  show:true,
    						  scope: $scope
    					  });
    					  showVerifyInfoAlertModal.result.then(function(){
    						  navigateTo('dashboard');
    					  });
    				  } else {
    					  SharedDataSvc.deliveryTempId = vm.engagement.engagementId;
    					  SharedDataSvc.invoiceDeliveryData.engagement = vm.engagement;
    					  //showModal('invoiceAndDelivery');
    					  vm.myDeliveryModal = modalService.open(deliveryModalOptions(vm.engagement));
    					  vm.myDeliveryModal
    					  .result.then(function (result) {
    						  //console.log('[Delivery Modal complete]', result);
    						  if (result && result.status === 'confirm') {
    							  showDeliveryConfirmation(result.data);
    							  console.log('[Schedule Delivery Address Check Modal] success');
    						  } else if(result && result.status === 'pickup') {
    							  showModalDeliveryPass('scheduleDeliveryPass');
    							  console.log('[Schedule Pickup Modal Pass Check] success:', result.status);
    						  }
    					  },
    					  function (result) {
    						  console.log('[Delivery Modal dismiss]');
    						  updateEngagement(SharedDataSvc.currentEngagementData,"updateDeliveryStatus");
    						  //navigateTo('dashboard');
    					  })
    				  }
    			  } else {
    				  $rootScope.customerName = SharedDataSvc.globalVars.order.currentEngagement.customerFirstName;
    				  $rootScope.initialAmountPaid = vm.formattedInitialPayment;
    				  $rootScope.servicePhoneNumber = SharedDataSvc.globalVars.storeSetup.vipPhoneNumber;
    				  var vccDetails = modalService.open({
    					  templateUrl: '/partials/alert_scheduleDeliveryConfirmation.html',
    					  backdrop: 'static',
    					  controller: 'delivery',
    					  controllerAs: 'delivery',
    					  show:true
    				  });
    				  vccDetails.result.then(function(){
    					  updateEngagement(SharedDataSvc.currentEngagementData);
    				  });
    			  }
    		  }else {
    			  if (vm.unmannedAutoPayDo == 'true') {
    				  vm.autoPaySuccess = true;
    				  vm.unmannedAutoPayDo = false;
    				  vm.modal.dismiss();
    			  }
    			  else if(vm.storeInfo.lowInitialPaymentEnabled && vm.account.paymentInitialValSelected === 'AutoPayRequired' &&
    					  vm.account.paymentCredit === 'cash' && !vm.cashPaymentSuccess){
    				  vm.autoPaySuccess = true;
    				  vm.modal.dismiss();
    			  } 
    			  else {
    				  vm.autoPaySuccess = true;
    				  vm.modal.dismiss();
    				  navigateTo('dashboard');
    			  }
    		  }
    	  }, function(response) {
    		  console.log('autopay fail', response);
    		  modalService.dismiss();
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = "There was a problem validating your payment method. Please review the information and try again or tap Exit to skip AutoPay setup. [758]";
    			  vm.autoPayClass = 'alert-danger';
    		  }
    	  });
      }
      //VAN-815-Autopay service - service Decomposition into eComplish autopay and HT autopay setup - Ends
  
      //VAN-1395-eComplish iFrame implementation - Starts
      /*function  ecomplishRequest(){
    	  var date = new Date();
    	  var payPageID = date.getTime().toString();
    	  var data = {
    			  "payPageID": payPageID,
    			  "div": "cardInputFrame",
    			  "widthOfFrame": "430",
    			  "heightOfFrame": "130",
    			  "allowACH": vm.enableBank,
    			  "language": $rootScope.selectedLanguage
    	  };
    	  $log.log("Input Data: "+data);
    	  if(vm.autopayIframe){
    		  data.div = "autopayIframe";
    	  }
    	  invokeEcomIframe(data);
      }

      function invokeEcomIframe(data){
    	  $log.log("Loading Ecomplish PayPage.");
    	  var payPageID = data.payPageID;
    	  var divFrameToUse = data.div;
    	  var heightOfFrame = data.heightOfFrame;
    	  var widthOfFrame  = data.widthOfFrame;
    	  var allowACH = data.allowACH;
    	  var language = data.language;
    	  if(window.location.hostname !== undefined && ecomSrcURL === ""){
    	  if(window.location.hostname === 'awp.acceptancenow.com'){
    			  ecomSrcURL = "https://www.secured-server.biz"; 
    		  }
    		  else{
    			  ecomSrcURL = "https://dev.secured-server.biz";  
    		  }
    	  }
    	  if(vm.customerFind != undefined && vm.customerFind.VanEngagement != undefined && vm.customerFind.VanEngagement.vanStore != undefined){
    		  vm.htStoreID = vm.customerFind.VanEngagement.vanStore.storeInfoLawson.htStoreId;
    	  } else if(vm.storeInfo != undefined && vm.storeInfo.vanStoreId != undefined){
    		  vm.htStoreID =  vm.storeInfo.vanStoreId.length == 4 ? "0"+vm.storeInfo.vanStoreId : vm.storeInfo.vanStoreId;
    	  }
    	  jQuery("#" + divFrameToUse).html("<iframe id='ecomp-payframe' name='ecomp-payframe' frameborder='0' src='"+ecomSrcURL+"/RentACenter/TokenizerAPI/?pageID="+payPageID+"&allowACH="+allowACH+"&lang="+language+"&customerNumber="+vm.customerFind.VanEngagement.customerHTId+"&storeNumber="+vm.htStoreID+"' height='" + heightOfFrame + "' width='" + widthOfFrame +"'></iframe>");
    	  iFrame = document.getElementById('ecomp-payframe');
    	 
    	  function getPageRegID(reqValues) {
    		  $log.log("Posting message to Ecomplish PayPage.");
    		  vm.eventInitialize= true;
    		  if (window.addEventListener) {
    			  window.addEventListener("message", eventHandler, false)
    		  } else {
    			  if (window.attachEvent) {
    				  window.attachEvent("onmessage", eventHandler)
    			  }
    		  }
    		  reqValues.action = "getRegID";
    		  reqValues.pageID = payPageID;
//  		  reqValues.allowACH = allowACH;
    		  var j = document.getElementById("ecomp-payframe").contentWindow;
    		  j.postMessage(JSON.stringify(reqValues), "*");
    	  }
    	  invokeEcomIframe.getPageRegID = getPageRegID;
      }
      var eventHandler = function (event) {
    	  var response = null;
    	  try {
    		  response = JSON.parse(event.data)
    		  console.log("Response from Ecomplish PayPage: "+response);  
    		  resHandler(response);
    		  window.removeEventListener("message", eventHandler, false);//VAN 1998 - AWP : Payment Page Freezed and Unable to Proceed with Payment Submission
    	  } catch (c) {
    		  console.log("Exception while invoking PayPage: "+response.responseText);
    		  vm.paymentErrorCode = '';
    		  vm.initialPaymentStatus = true;
    		  vm.initialPaymentErrorClass = 'alert-danger';
    		  vm.initialPaymentMessage = "Invalid data sent back from Ecomplish service";
    		  vm.initialPaymentPaid = false;
    	  }
      }

      function postToPayPage(){
    	  var data = {
    			  "action": "",
    			  "pageID": "",
    			  "allowACH": vm.enableBank,
    			  "language": $rootScope.selectedLanguage,
    			  "customerNumber": vm.customerFind.VanEngagement.customerHTId,
    			  "storeNumber": vm.customerFind.VanEngagement.vanStore.storeInfoLawson.htStoreId
    	  };
    	  invokeEcomIframe.getPageRegID(data);
      }

      function resHandler(response){
    	  if(!vm.autopayIframe){
    		  resHandlerInitialPay(response);
    	  }else {
    		  resHandlerAutopay(response);
    	  }
      }

      function resHandlerInitialPay(response){
    	  if(response.responseCode == '1'){
    		  $log.log("Sucess response: "+response.responseText);
    		  if(response.tokenizedPAN != ""){
    			  vm.tokenizedPAN = response.tokenizedPAN;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.tokenGenerated = true;
    		  }
    		  submitPayment();
    	  } else{
    		  vm.paymentErrorCode = '';
    		  vm.initialPaymentStatus = true;
    		  vm.initialPaymentErrorClass = 'alert-danger';
    		  vm.initialPaymentMessage = response.responseText;
    		  vm.initialPaymentPaid = false;
    	  }
      }

      function resHandlerAutopay(response){
    	  if(response.responseCode == '1'){
    		  $log.log("Sucess response: "+response.responseText);
    		  if(response.tokenizedPAN != ""){
    			  vm.tokenizedPAN = response.tokenizedPAN;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.tokenGenerated = true;
    		  }
    		  submitAutoPay();
    	  } else{
    		  vm.autoPayStatus = true;
    		  vm.autoPayMessage = response.responseText;
    		  vm.autoPayClass = 'alert-danger';
    	  }
      }*/

      function autoPayToken(value){
    	  vm.autoPayStatus = false;//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    	  if(value === 'sameCard'){
    		  console.log("Customer choosed same account for AutoPay. Hence pass the same token generated in Initial Payment flow.");
    		  vm.autopayIframe = false;
    		  vm.copyPayPageInfo = true;
    		  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
    		  if(iFrame.parentNode != null){
    			  var iframeVal = iFrame;
    			  iframeVal.parentNode.removeChild(iframeVal);
    		  }
    		  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'Y';
    		//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    	  }
    	  else{
    		  if(value === 'newCard'){
    			  console.log("Customer choosed different account for AutoPay. Hence Generate new token value.");
    			  vm.autopayIframe = true;
    			  vm.copyPayPageInfo = false;
    			  vm.account.autoPayment.existingBankOrCredit = true;
    			//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
//  			  vm.enableBank = "N";
//  			  ecomplishRequest();
    			  vm.showIframe = true;
    			  SharedDataSvc.autoPaymentData.sameCardForAutopay = 'N';
    			  configIframe();
    			  //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
    		  }
    	  }
      }
      //VAN-1395-eComplish iFrame implementation - Ends  
      
      //VAN-2012-VAN-AutoPay Issue - Starts
      function processInitialAndAutoPay(){
    	  $log.log('Process Autopay With Ecomplish', SharedDataSvc.autoPaymentData);
    	  $scope.viewLoading = true;
    	  showModal('Processing', 'processing-modal');
    	  paymentFactory.initialPayAndAutoPay(SharedDataSvc.autoPaymentData)
    	  .then(function(response) {
    		  $log.log('Successfully processed credit payment', response);
    		  if(response.status === 200) {
    			  $scope.viewLoading = false;
    			  modalService.dismiss();
    			  vm.autopayWithEcomplish = true;
    			  vm.initialPaymentStatus = true;
    			  vm.initialPaymentPaid = true; 
    			  if(SharedDataSvc.autoPaymentData.paymentType === 'Credit'){//VAN-3330-replace eComplish iframe with VANTIV iframe - AWP 
    			  vm.copyPayPageInfo = true;
    			  var iframeVal = iFrame;
    			  iframeVal.parentNode.removeChild(iframeVal);
    			  }
    			  submitSkipAutoPay();
    		  }
    	  }, function(response) {
    		  $log.log('Error processing Autopay with Ecomplish', response);
    		  $scope.viewLoading = false;
    		  modalService.dismiss();
    		  console.log('error code', response.data.errorData.status_code);
    		  if(response.data.errorData.status_code === "ENG-A1010-500") {
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = vm.transactionDesc7;
    			  vm.autoPayClass = 'alert-danger';
    		  } else {
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  vm.initialPaymentStatus = true;
    			  vm.paymentErrorCode = '757';
    			  vm.initialPaymentMessage = vm.transactionDesc8;
    		  }
    	  });
      }
      
      function processCash(){
    	  if(vm.account.paymentInitialValSelected === 'AutoPayRequired'){
    		  processCashAndAutoPay();
    	  }else{
    		  processCashPayment();
    	  }
      }

      function processCashAndAutoPay(){
    	  $log.log('process cash payment and autopayment', SharedDataSvc.cashInitialPayment);
    	  if(!vm.cashPaymentToLow) {
    		  $scope.viewLoading = true;
    		  showModal('Processing', 'processing-modal');
    		  paymentFactory.postCashAndAutoPay(SharedDataSvc.cashInitialPayment)
    		  .then(function(response){
    			  $log.log('Successfully processed cash payment and autopayment', response);
    			  vm.modal.dismiss();
    			  if(response.status === 200) {
    				  vm.modal.dismiss();
    				  vm.paymentErrorCode = '';
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-success';
    				  vm.initialPaymentMessage = vm.paymentMessageSuccess;
    				  vm.cashPaymentSuccess = true;
    				  vm.initialPaymentPaid = false;
    				  submitSkipAutoPay();
    			  }
    			  if(response.status === 400) {
    				  vm.modal.dismiss();
    				  vm.paymentErrorCode = '';
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.paymentMessageFailure;
    				  vm.initialPaymentPaid = false;
    				  vm.employeeId = '';
    			  }
    		  }, function(response){
    			  $log.log('Error processing cash payment', response);
    			  vm.modal.dismiss();
    			  vm.employeeId = '';
    			  if(response.data.errorData.status_code === "ENG-114-400") {
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.promoNotAvailable;
    				  vm.initialPaymentPaid = false;
    			  }else if(response.data.errorData.status_code === "ENG-T1600-400"){
    				  vm.initialPaymentStatus = true;
    				  vm.initialPaymentErrorClass = 'alert-danger';
    				  vm.initialPaymentMessage = vm.internalRecordLocked;
    				  vm.initialPaymentPaid = false;
    			  }else {
    				  var status_message = JSON.parse(response.data.errorData.status_message);
    				  console.log('CASH PAYMENT RESPONSE', response);
    				  console.log('STATUS', status_message);
    				  if(status_message.processingErrors[0].errorCode.indexOf('T2202') > -1) {
    					  vm.pinAttempts = vm.pinAttempts + 1;
    					  vm.initialPaymentStatus = false;
    					  vm.initialPaymentErrorClass = '';
    					  vm.employeeId = '';
    					  console.log('ATTEMPTS', vm.pinAttempts);
    					  if(vm.pinAttempts === 3) {
    						  vm.pinAttempts = 0;
    						  showModal('tooManyPinAttempts');
    					  } else {
    						  vm.employeePinError = true;
    						  showModal('cashPaymentEmployeePin');
    					  }
    				  } else {
    					  vm.initialPaymentStatus = true;
    					  vm.initialPaymentErrorClass = 'alert-danger';
    					  vm.initialPaymentMessage = vm.paymentMessageFailure;
    				  }
    			  }
    		  });
    	  }
      }
      //VAN-2012-VAN-AutoPay Issue - Ends

      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - Start
      function configIframe(){
    	  if(payPageID == ""){
    		  if(window.location.hostname !== undefined && payPageID === "" && merchantID ===""){
    			  if(window.location.hostname === 'awp.acceptancenow.com'){
    				  payPageID = "ppexn7YVS475JywH"; 
    				  merchantID = "4445026664342";
    			  }
    			  else{
    				  payPageID = "DKa7HK5j5f4H4hxw";  
    				  merchantID = "1273451";
    			  }
    		  }
    	  }
    	  if(vm.props == ""){ 
    		  vm.props = {
    				  "paypageId" : payPageID,
    				  "style": $rootScope.selectedLanguage == 'en'?"iframeen":"iframeesv2",
    						  "height" : "200px",
    						  "width" : "200px",
    						  "reportGroup" : "ANOW-CP",
    						  "timeout" : "15000",
    						  "div" : !vm.autopayIframe?"payframe":"payframe_auto",
    								  "callback" : payframeClientCallback,
    								  "showCvv" : true,
    								  "months" : {
    									  "0": "Select",
    									  "1" : "January",
    									  "2" : "February",
    									  "3" : "March",
    									  "4" : "April",
    									  "5" : "May",
    									  "6" : "June",
    									  "7" : "July",
    									  "8" : "August",
    									  "9" : "September",
    									  "10": "October",
    									  "11" : "November",
    									  "12" : "December"
    								  },
    								  "numYears" : 50,"tabIndex" : {
    									  "cvv" : 1,
    									  "accountNumber" : 2,
    									  "expMonth" : 3,
    									  "expYear" : 4
    								  },
    								  "placeholderText" : {
    									  "cvv" : "CVV",
    									  "accountNumber" : "Number on Card",
    									  "expMonth" : "MM",
    									  "expYear" : "YYYY"  
    								  },
    								  "enhancedUxFeatures" : {
    									  "inlineFieldValidations": true,
    									  "numericInputsOnly": true
    								  }
    		  }
    	  }else{
    		  if(vm.props.div == "payframe" && vm.autopayIframe){
    			  vm.props.div ="payframe_auto";
    		  }else if(vm.props.div == "payframe_auto" && !vm.autopayIframe){
    			  vm.props.div ="payframe";
    		  }
    	  }
    	  invokeVantivIframe(vm.props);
      }
      function invokeVantivIframe(data){
    	  var startTime;

    	  if (typeof EprotectIframeClient === 'undefined') {
    		  console.log("Coudln't download payframe-client javascript");
    	  }
    	  payframeClient = new EprotectIframeClient(data);
    	  iFrame = document.getElementById("eProtect-iframe");
    	  function getPaypageRegId(){
    		  var message = {
    				  "id" : merchantID!=""?merchantID:"",
    				  "orderId" : vm.engagementId
    		  };
    		  startTime = new Date().getTime();
    		  payframeClient.getPaypageRegistrationId(message);
    		  return false;
    	  }
    	  invokeVantivIframe.getPaypageRegId = getPaypageRegId;
      }

      var payframeClientCallback = function (response) {
    	  if (response.response === '870') {
    		  console.log(response.message);
    		  if(response.paypageRegistrationId!=""){
    			  vm.isPayRegIdGenerated = true;
    			  vm.paymentId =  response.paypageRegistrationId;
    			  vm.lastFourDigit = response.lastFour;
    			  vm.cardType = response.type;
    			  vm.account.initialPayment.creditCard.expDate = response.expMonth+"/"+response.expYear;
    			  vm.expDate = response.expMonth+response.expYear;
    			  vm.configure = "";
    			  if(!vm.autopayIframe){
    				  submitPayment();
    			  }else{
    				  submitAutoPay();
    			  }
    		  }
    	  } else {
    		  if(!vm.autopayIframe){
    			  vm.paymentErrorCode = '';
    			  vm.initialPaymentStatus = true;
    			  vm.initialPaymentErrorClass = 'alert-danger';
    			  if(response.message!=undefined){
    				  vm.initialPaymentMessage = response.message;
    			  }else{
    				  vm.initialPaymentMessage = "The server encountered an internal error or misconfiguration and was unable to complete your request";
    			  }
    			  vm.initialPaymentPaid = false;
    		  }else{
    			  vm.autoPayStatus = true;
    			  vm.autoPayMessage = response.message;
    			  vm.autoPayClass = 'alert-danger';
    		  }
    		  vm.isPayRegIdGenerated = false;
    		  console.log(response.message);
    	  }	
      }

      function getCardType(type){
    	  if(type){
    		  var cardValues = {
    				  MC:"1",
    				  VI:"2",
    				  AX:"3",
    				  DI:"4"
    		  };
    		  return (cardValues[type]);	
    	  }
      }
      //VAN-3330-replace eComplish iframe with VANTIV iframe - AWP - End
  }

})();
